import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import { createForm } from "final-form";
import AnimateHeight from 'react-animate-height';
import api from "Api";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import handleError from 'Helpers/handleError';
import { objectArrayToObject } from "Utils/utils";
import useConfigSettings from 'Hooks/useConfigSettings';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { TENANT_FEATURE } from "Enums";
import PaymentMethodEnum from 'Enums/PaymentMethod';
import Text from "Components/shared/Text";
import { TextButton } from "Components/shared/buttons";
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import LoadingSpinner from "Components/shared/LoadingSpinner";
import { ToggleSymbol } from 'Components/shared/symbols';
import CollectionMethods from "./CollectionMethods/CollectionMethods";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import Footer from "./Footer";

const PurchaseConfig = () => {
  const [features, setFeatures] = useState({});
  const [savingSettings, setSavingSettings] = useState(false);
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  const [loadingCollectionMethods, setLoadingCollectionMethods] = useState(true);
  const [loadingAssets, setLoadingAssets] = useState(true);
  const [collectionMethods, setCollectionMethods] = useState([]);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentProviders, setPaymentProviders] = useState([]);
  const [assets, setAssets] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [notificationBarPosition, setNotificationBarPosition] = useState(null);

  const history = useHistory();
  // const formRef = useRef(createForm({
  //   initialValues: collectionMethods
  // }));

  const {
    data: defaultLanguage
  } = useConfigSettings.query({
    select: useCallback(
      ({ Languages }) => Languages.find(({ IsDefault }) => IsDefault),
      []
    ),
    onError: (error) => {
      handleError({ error, history });
    }
  });

  useEffect(() => {
    getFeatures();
    getCollectionMethods();
    getPaymentMethods();
    const tabBarElement = document.querySelector("#tabBar");
    setNotificationBarPosition(tabBarElement.clientHeight);
  }, []);

  useEffect(() => {
    const getAssets = async () => {
      try {
        const { assets } = await api.Config.getFullConfig();
        setAssets(assets);
        setLoadingAssets(false);
      } catch (error) {
        handleError({ error, history });
      }
    };
    if (
      features[TENANT_FEATURE.purchase]?.IsEnabled ||
      features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled
    ) {
      setLoadingAssets(false);
    } else {
      getAssets();
    }
  }, [features])

  const getFeatures = async () => {
    try {
      const { value } = await api.Config.Features.get();
      setFeatures(objectArrayToObject(value, "Type"));
      setLoadingFeatures(false);
    } catch (error) {
      handleError({ error, history });
    }
  };

  const {
    isLoading: updatingFeature,
    mutate: updateFeature
  } = useFeatureAvailability.mutation({
    onError: (error) => handleError({ error, history }),
    onSettled: () => {
      getFeatures();
      setShowConfirmationModal(false);
    }
  });

  const getCollectionMethods = async () => {
    try {
      const { value: collectionMethods } = await api.Config.CollectionMethods.getAll();
      setCollectionMethods(collectionMethods);
      setLoadingCollectionMethods(false);
    } catch (error) {
      handleError({ error, history })
    }
  };

  const updateCollectionMethods = async (values, dirtyFields) => {
    let collectionMethodIndicies = [];
    for (const flatPath of Object.keys(dirtyFields)) {
      const collectionMethodMatch = /^collectionMethods\[(\d+)\]/.exec(flatPath);
      if (collectionMethodMatch) {
        collectionMethodIndicies.push(collectionMethodMatch[1]);
      }
    }
    for (const collectionMethodIndex of collectionMethodIndicies) {
      const {
        Type: type,
        IsEnabled
      } = values.collectionMethods[collectionMethodIndex];
      try {
        await api.Config.CollectionMethods.patch(type, { IsEnabled });
      } catch (error) {
        values.collectionMethods[collectionMethodIndex].IsEnabled = !IsEnabled;
        handleError({ error, history });
      }
    }
  };

  const getPaymentMethods = async () => {
    try {
      const { value: paymentMethods } = await api.Config.PaymentMethods.getAll();
      paymentMethods.sort(
        ({ Type: a }, { Type: b }) => PaymentMethodEnum.order[a] - PaymentMethodEnum.order[b]
      );
      setPaymentMethods(paymentMethods);
      const { value: paymentProviders } = await api.Config.PaymentProviderConfigurations.getAll();
      setPaymentProviders(
        objectArrayToObject(paymentProviders, "Type")
      );
      setLoadingPaymentMethods(false);
    } catch (error) {
      handleError({ error, history })
    }
  };

  const updatePaymentMethods = async (values, dirtyFields) => {
    let paymentMethodIndicies = [];
    for (const flatPath of Object.keys(dirtyFields)) {
      const paymentMethodMatch = /^paymentMethods\[(\d+)\]/.exec(flatPath);
      if (paymentMethodMatch) {
        paymentMethodIndicies.push(paymentMethodMatch[1]);
      }
    }
    for (const paymentMethodIndex of paymentMethodIndicies) {
      const {
        Type: type,
        IsEnabled,
        "@odata.type": odataType
      } = values.paymentMethods[paymentMethodIndex];
      try {
        await api.Config.PaymentMethods.patch(
          type,
          {
            IsEnabled,
            "@odata.type": odataType
          }
        );
      } catch (error) {
        values.paymentMethods[paymentMethodIndex].IsEnabled = !IsEnabled;
        handleError({ error, history });
      }
    }
  };

  const save = async (values, form) => {
    setSavingSettings(true);
    const { dirtyFields } = form.getState();
    await updatePaymentMethods(values, dirtyFields);
    await updateCollectionMethods(values, dirtyFields);
    form.reset(values);
    setSavingSettings(false);
  };

  const saveAndEnableFeature = async (values, form) => {
    const { dirtyFields } = form.getState();
    if (Object.keys(dirtyFields).length) {
      await save(values, form);
    }
    updateFeature({
      type: TENANT_FEATURE.purchase,
      data: { IsEnabled: true }
    });
  };

  const disableFeature = () => {
    if (features[TENANT_FEATURE.purchase].IsEnabled) {
      updateFeature({
        type: TENANT_FEATURE.purchase,
        data: { IsEnabled: false }
      })
    }
    if (features[TENANT_FEATURE.customFeaturedAsset].IsEnabled) {
      updateFeature({
        type: TENANT_FEATURE.customFeaturedAsset,
        data: { IsEnabled: false }
      })
    }
  };

  const isPurchaseConfigured = ({ paymentMethods, collectionMethods }) => {
    if (loadingCollectionMethods || loadingPaymentMethods || loadingAssets) {
      return false;
    }
    const isPaymentEnabled = paymentMethods.some(({ IsEnabled }) => IsEnabled);
    const isCollectionEnabled = collectionMethods.some(({ IsEnabled }) => IsEnabled);
    const isAssetConfigured = !!assets.length;
    return isPaymentEnabled && isCollectionEnabled && isAssetConfigured;
  };

  return (
    <>
      <AnimateHeight
        duration={750}
        className="sticky z-10 w-full"
        style={{ top: notificationBarPosition }}
        height={showNotificationBar ? 'auto' : 0}
      >
        <div
          className="flex justify-between gap-24 px-32 py-10 bg-red"
        >
          <Text color="color-white">
            {i18nextTranslate(i18nextKeys.configPurchaseNotification)}
          </Text>
          <TextButton
            text={i18nextTranslate(i18nextKeys.buttonOk)}
            onClick={() => setShowNotificationBar(false)}
            color="color-white"
            dataQa="notification"
          />
        </div>
      </AnimateHeight>
      <FinalForm
        initialValues={{
          collectionMethods,
          paymentMethods
        }}
        onSubmit={save}
        render={({ handleSubmit, form, pristine, values }) => (
          <>
            <form>
              <div className="relative">
                <div className="px-32 py-24">
                  {loadingCollectionMethods || loadingPaymentMethods || loadingFeatures ? (
                    <div className="flex justify-center">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      <PaymentMethods
                        paymentMethods={values.paymentMethods}
                        paymentProviders={paymentProviders}
                        defaultLanguage={defaultLanguage}
                        disabled={savingSettings}
                        pristine={pristine}
                        isPurchaseEnabled={
                          features[TENANT_FEATURE.purchase].IsEnabled ||
                          features[TENANT_FEATURE.customFeaturedAsset].IsEnabled
                        }
                        showNotification={() => setShowNotificationBar(true)}
                      />
                      <hr className="border-color-5 my-32 xxl:my-40" />
                      <CollectionMethods
                        collectionMethods={values.collectionMethods}
                        defaultLanguage={defaultLanguage}
                        disabled={savingSettings}
                        pristine={pristine}
                        isPurchaseEnabled={
                          features[TENANT_FEATURE.purchase].IsEnabled ||
                          features[TENANT_FEATURE.customFeaturedAsset].IsEnabled
                        }
                        showNotification={() => setShowNotificationBar(true)}
                      />
                    </>
                  )}
                </div>
                {features[TENANT_FEATURE.purchase]?.IsEnabled ||
                  features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled ? (
                  <Footer
                    title={i18nextTranslate(i18nextKeys.configPurchaseFooterTitle)}
                    description={i18nextTranslate(i18nextKeys.configPurchaseFooterDescription)}
                    tooltipText={i18nextTranslate(i18nextKeys.configPurchaseFooterTooltip)}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configUpdateSettings)}
                    onPrimaryButtonClick={handleSubmit}
                    primaryButtonDisabled={pristine}
                    primaryButtonLoading={savingSettings || updatingFeature || loadingFeatures}
                    primaryButtonWidth={{
                      xxl: "210px",
                      default: "200px"
                    }}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configDisableFeature)}
                    onSecondaryButtonClick={() => setShowConfirmationModal(true)}
                    secondaryButtonDisabled={savingSettings || updatingFeature || loadingFeatures}
                    dataQa="purchase-settings"
                  />
                ) : (
                  <Footer
                    title={i18nextTranslate(i18nextKeys.configPurchaseFooterTitle)}
                    description={i18nextTranslate(i18nextKeys.configPurchaseFooterDescription)}
                    tooltipText={i18nextTranslate(i18nextKeys.configPurchaseFooterTooltip)}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configPurchaseFooterEnableButton)}
                    primaryButtonTooltipText={
                      isPurchaseConfigured(values)
                        ? null
                        : i18nextTranslate(i18nextKeys.configPurchaseFooterEnableTooltip)
                    }
                    onPrimaryButtonClick={() => setShowConfirmationModal(true)}
                    primaryButtonDisabled={!isPurchaseConfigured(values)}
                    primaryButtonLoading={savingSettings || updatingFeature || loadingFeatures || loadingAssets}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configSaveSettings)}
                    onSecondaryButtonClick={handleSubmit}
                    secondaryButtonDisabled={savingSettings || updatingFeature || loadingFeatures || pristine}
                    dataQa="purchase-settings"
                  />
                )}
              </div>
            </form>
            {features[TENANT_FEATURE.purchase]?.IsEnabled ||
              features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled ? (
              <ConfirmationModal
                show={showConfirmationModal}
                close={() => setShowConfirmationModal(false)}
                loading={updatingFeature}
                onConfirm={disableFeature}
                confirmButtonText={i18nextTranslate(i18nextKeys.buttonTurnOff)}
                icon={updatingFeature ? (
                  <LoadingSpinner
                    dataQa="purchase-confirmation-loading"
                  />
                ) : (
                  <ToggleSymbol
                    style={{
                      transform: "scale('1,1')",
                    }}
                    data-qa="purchase-confirmation-icon"
                  />
                )}
                title={
                  features[TENANT_FEATURE.purchase]?.IsEnabled &&
                  features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled
                    ? i18nextTranslate(i18nextKeys.configPurchaseDisableTitle)
                    : i18nextTranslateDynamically(
                        i18nextKeys.featureToggleDisable,
                        {
                          featureName: i18nextTranslate(
                            features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled
                              ? i18nextKeys.tenantSettingsFeaturesCustomPurchaseTitle
                              : i18nextKeys.tenantSettingsFeaturesPurchaseTitle
                          )
                        }
                      )
                }
                description={i18nextTranslate(
                  features[TENANT_FEATURE.purchase]?.IsEnabled &&
                  features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled
                    ? i18nextKeys.configPurchaseDisableText
                    : features[TENANT_FEATURE.customFeaturedAsset]?.IsEnabled
                      ? i18nextKeys.featureToggleModalCustomPurchaseDisableText
                      : i18nextKeys.featureToggleModalPurchaseDisableText
                )}
              />
              ) : (
                <ConfirmationModal
                  show={showConfirmationModal}
                  close={() => setShowConfirmationModal(false)}
                  loading={savingSettings || updatingFeature}
                  onConfirm={() => saveAndEnableFeature(values, form)}
                  confirmButtonText={i18nextTranslate(i18nextKeys.buttonTurnOn)}
                  icon={savingSettings || updatingFeature ? (
                    <LoadingSpinner
                      dataQa="purchase-confirmation-loading"
                    />
                  ) : (
                    <ToggleSymbol
                      style={{
                        transform: "scale('1,1')",
                      }}
                      data-qa="purchase-confirmation-icon"
                    />
                  )}
                  title={i18nextTranslateDynamically(
                    i18nextKeys.featureToggleActivate,
                    {
                      featureName: i18nextTranslate(
                        i18nextKeys.tenantSettingsFeaturesPurchaseTitle
                      )
                    }
                  )}
                  description={i18nextTranslate(
                    i18nextKeys.featureToggleModalPurchaseActivateText
                  )}
                />
              )}
          </>
        )}
      />
    </>
  );
};

  export default PurchaseConfig;
