import React, { useReducer, createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Big from 'big.js';
import api from 'Api';
import handleError from 'Helpers/handleError';
import { objectArrayToObject } from 'Utils/utils';
import cartReducer from './cartReducer';
import {
  LOAD_CART,
  LOAD_CART_SUCCESS,
  PENDING_CART_OPERATION,
  PENDING_CART_OPERATION_SUCCESS,
  PENDING_QUANTITY_UPDATE,
  EMPTY_CART,
  REMOVE_FROM_CART_SUCCESS,
  SET_FULL_ASSET_INFO,
} from './cartTypes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

export const CartContext = createContext();

const CartState = (props) => {
  const initialState = {
    userId: props.userId,
    cartItems: {},
    fullAssetInfo: null,
    loadingCart: true,
    pendingCartOperation: false,
    pendingQuantityUpdate: false
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const history = useHistory();

  const loadCart = async () => {
    try {
      dispatch({ type: LOAD_CART });
      const { value } = await api.Cart.get();
      dispatch({
        type: LOAD_CART_SUCCESS,
        payload: objectArrayToObject(value, 'UniqueAssetId'),
      });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartLoadCartError);
      handleError({ error, history, message });
    }
  };

  // invoked from asset modal
  const addToCart = async (uniqueAssetId, quantity) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      // 1. check if item already in cart
      const itemInCart = state.cartItems[uniqueAssetId];
      if (itemInCart) {
        // 2. if item already in cart add to current quantity, and patch via Id
        const newQuantity = Big(itemInCart.Units).plus(quantity).toNumber();
        await api.Cart.updateItem(itemInCart.Id, { Units: newQuantity });
        dispatch({
          type: PENDING_CART_OPERATION_SUCCESS,
          payload: {
            Id: itemInCart.Id,
            UniqueAssetId: uniqueAssetId,
            Units: newQuantity
          },
        });
      } else {
        // 3. if item not in cart, post and set Id from response
        const { Id, UniqueAssetId, Units } = await api.Cart.addItem({
          UniqueAssetId: uniqueAssetId,
          Units: quantity,
        });
        dispatch({
          type: PENDING_CART_OPERATION_SUCCESS,
          payload: { Id, UniqueAssetId, Units }
        });
      }
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartAddItemToCartError);
      handleError({ error, history, message });
    }
  };

  const removeFromCart = async (uniqueAssetId) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      const itemInCart = state.cartItems[uniqueAssetId];
      await api.Cart.removeItem(itemInCart.Id);
      dispatch({ type: REMOVE_FROM_CART_SUCCESS, payload: uniqueAssetId });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartRemoveItemToCartError);
      handleError({ error, history, message });
    }
  };

  const emptyCart = async () => {
    try {
      await api.Cart.empty();
      dispatch({ type: EMPTY_CART });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartEmptyCartError);
      handleError({ error, history, message });
    }
  };

  const setQuantity = (uniqueAssetId, newQuantity, deferred = false) => {
    const maxPurchasableUnits = state.fullAssetInfo[uniqueAssetId].maxPurchasableUnits;
    if (
      newQuantity > maxPurchasableUnits ||
      newQuantity === null ||
      newQuantity === 0
    ) {
      return;
    }
    if (deferred) {
      dispatch({ type: PENDING_QUANTITY_UPDATE });
      setDebounceTimeout(
        setTimeout(() => setQuantityAtServer(uniqueAssetId, newQuantity), 1500)
      );
    } else {
      setQuantityAtServer(uniqueAssetId, newQuantity);
    }
  };

  const setQuantityAtServer = async (uniqueAssetId, newQuantity) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      const itemInCart = state.cartItems[uniqueAssetId];
      await api.Cart.updateItem(itemInCart.Id, { Units: newQuantity });
      dispatch({
        type: PENDING_CART_OPERATION_SUCCESS,
        payload: {
          UniqueAssetId: uniqueAssetId,
          Id: itemInCart.Id,
          Units: newQuantity,
        },
      });
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartSetQuantityError);
      handleError({ error, history, message });
    }
  };

  const setFullAssetInfo = (assetsFromBlob) => {
    dispatch({ type: SET_FULL_ASSET_INFO, payload: assetsFromBlob });
  };

  return (
    <CartContext.Provider
      value={{
        loadingCart: state.loadingCart,
        pendingCartOperation: state.pendingCartOperation,
        pendingQuantityUpdate: state.pendingQuantityUpdate,
        cartItems: state.cartItems,
        fullAssetInfo: state.fullAssetInfo,
        loadCart,
        addToCart,
        emptyCart,
        removeFromCart,
        setQuantity,
        setFullAssetInfo
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartState;
