import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { matchPath, useHistory } from 'react-router-dom';
import { ROUTES } from 'Router/Routes';
import { UiContext } from "States/ui/uiState";
import { getUserManager } from 'Helpers/auth';
import { IconCircleWrapper } from 'Helpers/icons';
import Urls from 'Utils/urls';
import handleError from 'Helpers/handleError';
import Fade from 'Components/shared/Fade';
import Header from '../shared/Header/Header';
import { EmailIcon } from 'Components/shared/symbols';
import { Button } from 'Components/shared/buttons';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import usePostLoginRedirect from 'Hooks/usePostLoginRedirect';
import { useAmplitude } from 'react-amplitude-hooks';

const ConfirmEmail = () => {
  const postLoginRedirect = usePostLoginRedirect();
  const { logEvent } = useAmplitude();
  let history = useHistory();
  const unblockHistory = useRef(null);
  const [resendRequested, setResendRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  useEffect(
    () => async () => {
      const userManager = await getUserManager();
      userManager.signinRedirect({
        state: { originUrl: history.location.pathname },
      });
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    const blockHistory = () => {
      unblockHistory.current = history.block(({ pathname }) => {
        if (matchPath(pathname, ROUTES.logout)) {
          unblockHistory.current();
        } else {
          return false;
        }
      });
    };
    blockHistory();
  }, []);

  const resendEmail = async () => {
    if (resendRequested) return; // Email was already requested

    const res = await axios.post(`${Urls.get().clApi}/auth/confirmation`);
    if (res.status === 200) {
      setResendRequested(true);
    }
  };

  const confirmEmail = async () => {
    setLoading(true);
    try {
      const {
        data: {
          EmailValidated,
          Success
        } = {}
      } = await axios.get(`${Urls.get().authApi}/user/EmailValidated`);
      if (Success && EmailValidated) {
        unblockHistory.current();
        logEvent("Email confirmed");
        postLoginRedirect();
      }
    } catch (error) {
      handleError({ error, history });
    } finally {
      setLoading(false)
    }
  };

  const logout = () => {
    logEvent('Logout', {
      screen: ROUTES.confirmEmail.split("/")[1]
    });
    history.replace(ROUTES.logout);
  };

  const buttonWidth = {
    xxl: '190px',
    default: '170px'
  };

  return (
    <div>
      <Header text={i18nextTranslate(i18nextKeys.emailUnconfirmed)} />
      <Fade show={true}>
        <div data-qa="confirmEmail" className="flex flex-col gap-32">
          <div className="flex flex-col items-center gap-48">
            <p className="color-8 text-2xl font-bold">
              {i18nextTranslate(i18nextKeys.emailVerifyYourEmail)}
            </p>
            <IconCircleWrapper
              icon={<EmailIcon size={xxl ? 72 : 60} />}
              size={xxl ? "144px" : "120px"}
            />
            {resendRequested ? (
              <p className="text-sm text-green-600">
                {i18nextTranslate(i18nextKeys.emailConfirmEmailSent)}
              </p>
            ) : (
              <p className="color-8 text-sm">
                {i18nextTranslate(i18nextKeys.emailIfAlreadyReceived)}
              </p>
            )}
          </div>

          <div className="flex flex-col items-center gap-32">
            <Button
              dataQa="confirmEmail-resendButton"
              text={i18nextTranslate(i18nextKeys.emailResend)}
              onClick={resendEmail}
              disabled={resendRequested || loading}
              width={buttonWidth}
              secondary
            />
            <Button
              dataQa="confirmEmail-confirmButton"
              text={i18nextTranslate(i18nextKeys.emailDidConfirm)}
              onClick={confirmEmail}
              disabled={loading}
              loading={loading}
              width={buttonWidth}
              secondary
            />
            <Button
              dataQa="confirmEmail-logoutButton"
              text={i18nextTranslate(i18nextKeys.buttonLogout)}
              onClick={logout}
              disabled={loading}
              width={buttonWidth}
              secondary
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ConfirmEmail;
