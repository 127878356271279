import React from 'react';

function BitcoinSymbol({ active, className, ...props }) {
  return (
    <svg
      width={292}
      height={292}
      viewBox="0 0 292 292"
      fill="none"
      className={`${className} fill-current ${
        active ? 'color-8' : 'color-4'
      }`}
      {...props}
    >
      <path d="M133.233 147.54l-8.691 35.218c10.733 2.698 43.808 13.432 48.708-6.431 5.104-20.723-29.298-26.09-40.017-28.787zm11.857-48.082l-7.875 31.952c8.925 2.246 36.458 11.434 40.92-6.635 4.638-18.856-24.12-23.056-33.045-25.317zM145.833 0a145.833 145.833 0 100 291.666 145.833 145.833 0 000-291.666zm63.555 125.052a25.422 25.422 0 01-20.198 23.494 28.192 28.192 0 0114.539 38.806c-8.546 24.675-28.831 26.76-55.81 21.598l-6.548 26.542-15.823-3.996 6.46-26.177a515.224 515.224 0 01-12.6-3.311l-6.489 26.309-15.808-3.982 6.562-26.6c-3.704-.948-7.452-1.968-11.288-2.931l-20.591-5.192 7.86-18.316s11.667 3.135 11.492 2.902a5.758 5.758 0 004.426-.374 5.745 5.745 0 002.836-3.418l17.748-72.027a8.502 8.502 0 00-7.364-9.304c.233-.16-11.507-2.888-11.507-2.888l4.23-17.091 21.802 5.512-.015.088c3.281.817 6.665 1.604 10.106 2.392l6.475-26.28 15.823 3.996-6.358 25.754c4.244.992 8.517 1.97 12.687 3.02l6.3-25.595 15.823 3.996-6.489 26.28c19.979 6.956 34.591 17.397 31.719 36.793z" />
    </svg>
  );
}

export default BitcoinSymbol;
