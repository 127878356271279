import React from "react";
import { useHistory } from "react-router";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import handleError from 'Helpers/handleError';
import useConfigSettings from 'Hooks/useConfigSettings';
import Text from "Components/shared/Text";

const FeeExample = ({
  title,
  subtitle,
  amount,
  dataQa
}) => (
  <div
    className="flex flex-col justify-between flex-1 self-stretch gap-12 px-16 py-10 rounded bg-3-5"
    data-qa={dataQa}
  >
    <div>
      <Text textStyle="p2">
        {title}
      </Text>
      {subtitle ? (
        <Text textStyle="text-small">
          {subtitle}
        </Text>
      ) : null}
    </div>
    <Text>{amount}</Text>
  </div>
);

const FeesDescription = () => {
  const history = useHistory();
  const {
    data: {
      Currency = {}
    }
  } = useConfigSettings.query({
    onError: (error) => {
      handleError({ error, history });
    }
  });

  return (
    <div className="flex flex-col gap-40 xxl:gap-48">
      <div>
        <Text
          textStyle="h2"
          dataQa="fees-title"
          className="mb-24"
          uppercase
        >
          {i18nextTranslate(i18nextKeys.configCollectionFeesTitle)}
        </Text>
        <div className="flex flex-col gap-16">
          <Text
            textStyle="p2"
            dataQa="fees-subtitle"
          >
            {i18nextTranslate(
              i18nextKeys.configCollectionFeesSubtitle
            )}
          </Text>
          <Text
            textStyle="p4"
            dataQa="fees-description"
          >
            {i18nextTranslate(
              i18nextKeys.configCollectionFeesDescription
            )}
          </Text>
          <Text
            textStyle="p4"
            dataQa="fees-example"
          >
            {i18nextTranslateDynamically(
              i18nextKeys.configCollectionFeesExample,
              { currency: Currency.Code }
            )}
          </Text>
        </div>
        <div className="flex items-center gap-12 mt-16 xxl:mt-24">
          <FeeExample
            title={i18nextTranslate(i18nextKeys.commonFeeVariable)}
            subtitle={i18nextTranslate(i18nextKeys.commonFeePercentage)}
            amount={i18nextTranslateDynamically(
              i18nextKeys.configCollectionFeesExampleVariable,
              { currency: Currency.Code }
            )}
            dataQa="fees-example-variable"
          />
          +
          <FeeExample
            title={i18nextTranslate(i18nextKeys.commonFeeFixed)}
            amount={i18nextTranslateDynamically(
              i18nextKeys.configCollectionFeesExampleFixed,
              { currency: Currency.Code }
            )}
            dataQa="fees-example-fixed"
          />
          =
          <FeeExample
            title={i18nextTranslate(i18nextKeys.commonFeeTotal)}
            subtitle={i18nextTranslate(
              i18nextKeys.configCollectionFeesExampleTotalDescription
            )}
            amount={i18nextTranslateDynamically(
              i18nextKeys.configCollectionFeesExampleTotal,
              { currency: Currency.Code }
            )}
            dataQa="fees-example-total"
          />
        </div>
      </div>
    </div>
  );
};

export default FeesDescription;
