import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import Big from 'big.js';
import api from 'Api';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import { AuthContext } from 'States/auth/authState';
import { CartContext } from 'States/cart/cartState';
import { ConfigContext } from 'States/config/configState';
import { ROUTES } from 'Router/Routes';
import { Button } from 'Components/shared/buttons';
import handleError from 'Helpers/handleError';
import { useFormatNumber } from 'Utils/formatNumber';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { Arrow } from 'Components/shared/symbols';
import { NumberInput } from 'Components/shared/formElements';
import Text from 'Components/shared/Text';
import { getMaxPurchasableUnits } from 'Helpers/assets';
import useConfigSettings from 'Hooks/useConfigSettings';

const AddToCart = ({
  asset: {
    uniqueAssetId,
    maxAmount,
    price,
    issuerAddress,
    Name,
    UnitOfMeasureCode,
    CurrencyCode,
    SparkFactor
  },
  buttonWidth,
  requiredTier = 0,
  closeModal = () => {},
  trackModal = () => {},
  dataQa = "addToCart"
}) => {
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(undefined);
  const [isValid, setIsValid] = useState(true);
  const [availableUnits, setAvailableUnits] = useState(null);
  const formatNumber = useFormatNumber();
  const { isUserTierSufficient } = useKycTierCheck(requiredTier);
  const { addToCart, cartItems, pendingCartOperation } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const {
    config: { kycTiersDescription }
  } = useContext(ConfigContext);

  const history = useHistory();
  const {
    isLoading: loadingSettings,
    data: settings
  } = useConfigSettings.query({
    enabled: user?.isConfirmedByAdmin
  });
  const { logEvent } = useAmplitude();

  const unitsInCart = cartItems[uniqueAssetId]?.Units || 0;

  useEffect(() => {
    const loadAdditionalAssetData = async () => {
      try {
        const availableSparks = await api.Assets.sparksAtAddress(uniqueAssetId, issuerAddress);
        const currentAvailableUnits = Number(availableSparks.Amount) / Number(SparkFactor);
        setAvailableUnits(currentAvailableUnits);
      } catch (error) {
        if (error.response?.status === 400) {
          setAvailableUnits(0);
        } else {
          handleError({ error, history });
        }
      }
      finally {
        setLoading(false);
      }
    };

    if (uniqueAssetId && issuerAddress) {
      loadAdditionalAssetData();
    }
  }, [uniqueAssetId, issuerAddress]);

  const maxPurchasableUnits = useMemo(() => {
      if (availableUnits === null) {
        return undefined;
      }
      return getMaxPurchasableUnits(availableUnits, maxAmount, true)
    },
    [availableUnits, maxAmount, loading]
  );

  const defaultAmount = Math.min(maxPurchasableUnits, 1);
  const assetUnavailable = maxPurchasableUnits === 0
  const maxAmountInCart = unitsInCart === maxPurchasableUnits;

  const resetAmount = () => {
    setAmount(defaultAmount);
  };

  useEffect(() => {
    if (maxPurchasableUnits === undefined) {
      return;
    }
    resetAmount();
  }, [maxPurchasableUnits]);

  useEffect(() => {
    if (maxPurchasableUnits === undefined) {
      return;
    }
    trackModal(!(assetUnavailable || maxAmountInCart));
  }, [assetUnavailable, maxAmountInCart, maxPurchasableUnits]);

  const onChangeAmount = (amount) => {
    setAmount(amount);
    logEvent("Amount changed_add to cart", { amount });
  };

  const onAddToCart = async () => {
    if (isUserTierSufficient) {
      await addToCart(uniqueAssetId, amount);
      logEvent("Add to cart");
      resetAmount();
      closeModal();
    } else {
      logEvent("KYC started", {
        origin: "featured",
        provider: kycTiersDescription[1]?.providers[0].name !== KYC_PROVIDER.manual
      });
      settings.RestrictedMode
        ? history.push({
            pathname: ROUTES.kyc,
            state: {
              returnUrl: window.location.href
            }
          })
        : window.open(ROUTES.kyc, '_blank');
    }
  };

  return (
    <>
      {user.isConfirmedByAdmin ? (
        <div className="flex flex-col gap-16 w-full">
          {!loading && unitsInCart > 0 && (
            <span
              className="inline-block text-xs xxl:text-sm color-8"
              data-qa={`${dataQa}-currentAmount`}
            >
              {UnitOfMeasureCode || CurrencyCode ? (
                <Trans i18nKey={unitsInCart > 1
                  ? i18nextKeys.addToCartAreInTheCartUOM
                  : i18nextKeys.addToCartIsInTheCartUOM}
                >
                  <span className="color-4 font-bold">
                    {{ unitsInCart }}
                  </span>
                  <span className="font-bold">
                    {{ unitOfMeasure: UnitOfMeasureCode || CurrencyCode }}
                  </span>
                  <span className="font-bold">
                    {{ name: Name }}
                  </span>
                </Trans>
              ) : (
                <Trans i18nKey={unitsInCart > 1
                  ? i18nextKeys.addToCartAreInTheCart
                  : i18nextKeys.addToCartIsInTheCart}
                >
                  <span className="color-4 font-bold">
                    {{ unitsInCart }}
                  </span>
                  <span className="font-bold">
                    {{ name: Name }}
                  </span>
                </Trans>
              )}
            </span>
          )}
          <div className="flex items-start justify-between gap-12 md:gap-16">
            <div
              className="flex-1 flex flex-col items-start gap-2 relative"
              style={{ minWidth: "144px" }}
            >
              <NumberInput
                name={Name}
                dataQa={dataQa}
                innerLabelText={Name ? `${UnitOfMeasureCode || CurrencyCode || ''} ${Name}` : ''}
                className="w-full"
                max={maxPurchasableUnits - unitsInCart}
                value={amount}
                onChange={onChangeAmount}
                disabled={
                  !isUserTierSufficient || assetUnavailable ||
                  maxAmountInCart || loading
                }
                setValidity={setIsValid}
                showInnerLabel
                showOwnErrors
              />
              {loading || loadingSettings ? (
                <Text
                  textStyle="h3"
                  dataQa={`${dataQa}-loading`}
                >
                  {i18nextTranslate(i18nextKeys.addToCartLoading)}
                </Text>
              ) : (
                <>
                  {assetUnavailable || maxAmountInCart ? (
                    <Text
                      textStyle="h3"
                      color="color-red"
                      dataQa={`${dataQa}-price`}
                    >
                      {assetUnavailable
                        ? i18nextTranslate(i18nextKeys.addToCartNotAvailable)
                        : maxAmountInCart
                          ? i18nextTranslate(i18nextKeys.addToCartMaxQuantityInCart)
                          : ''
                      }
                    </Text>
                  ) : isValid ? (
                    <Text
                      textStyle="h3"
                      dataQa={`${dataQa}-price`}
                    >
                      = {formatNumber(Big(price).times(amount).toNumber())} {settings.Currency.Code}
                    </Text>
                  ) : ''}
                </>
              )}
            </div>

            <Button
              dataQa="purchase-assetModal-addButton"
              onClick={onAddToCart}
              text={
                isUserTierSufficient
                  ? i18nextTranslate(i18nextKeys.buttonAddToCart)
                  : i18nextTranslateDynamically(
                      i18nextKeys.kycGetTierToBuy,
                      { tier: requiredTier }
                    )
              }
              icon={isUserTierSufficient ? null : Arrow}
              width={buttonWidth}
              disabled={
                assetUnavailable ||
                !isValid ||
                maxAmountInCart ||
                amount === 0
              }
              loading={loading || pendingCartOperation}
            />
          </div>
        </div>
      ) : (
        <Text className="text-center">
          {i18nextTranslate(i18nextKeys.accountNotConfirmedByAdminShort)}
        </Text>
      )}
    </>
  );
};

export default AddToCart;
