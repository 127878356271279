import React from "react";
import { Field as FinalField } from "react-final-form";
import { composeValidators } from "revalidate";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { capitalizeString } from 'Utils/utils';
import { isMaxLengthExceeded, isRequiredIfWithMessage } from "Utils/validators";
import { FormField } from "Components/shared/formElements";

const Description = ({
  fieldName = "Description",
  fieldNamePrefix,
  fieldWidth,
  textAreaHeight,
  isDefaultLanguage = false,
  maxLength = 60,
  languageCode,
  placeholderKey,
  required = false,
  validateFields = []
}) => {
  const placeholder = i18nextTranslate(
    i18nextKeys[placeholderKey],
    { lng: languageCode }
  );

  const language = i18nextTranslate(i18nextKeys[
    `languages${capitalizeString(languageCode)}`
  ]);

  const label = isDefaultLanguage
    ? i18nextTranslate(
        i18nextKeys[`configTranslations${fieldName}LabelDefault`]
      )
    : i18nextTranslateDynamically(
        i18nextKeys[`configTranslations${fieldName}LabelLanguage`],
        { language }
      );

  const validator = composeValidators(
    isRequiredIfWithMessage(
      () => required,
      i18nextTranslate(i18nextKeys.configTranslationsField)
    ),
    isMaxLengthExceeded(maxLength)
  )();

  return (
    <FinalField
      name={`${fieldNamePrefix}.${fieldName}`}
      label={label}
      validate={required ? validator : null}
      validateFields={validateFields}
      render={(props) => (
        <FormField
          dataQa={`${
            fieldName.toLowerCase()
          }-${languageCode}`}
          maxLength={maxLength}
          placeholder={placeholder}
          style={{
            lineHeight: "1.194rem",
            marginBottom: "0.125rem"
          }}
          containerStyle={{ width: fieldWidth }}
          height={textAreaHeight}
          showNumberOfChars
          multiline
          {...props}
        />
      )}
      keepDirtyOnReinitialize
      required={required}
    />
  );
};

export default Description;
