import {
  LOAD_CONFIG_SUCCESS,
  CONFIG_ERROR,
  SET_COOKIE_SETTINGS,
} from './configTypes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const configReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload.config,
        error: null,
        loading: false,
      };
    }

    case SET_COOKIE_SETTINGS: {
      return {
        ...state,
        cookieSettings: action.payload.cookieSettings,
      };
    }

    case CONFIG_ERROR: {
      return {
        ...state,
        error: i18nextTranslate(i18nextKeys.errorStatesConfigConfigError),
        loading: false,
        config: {},
      };
    }
  }
};

export default configReducer;
