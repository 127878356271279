import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "./States/auth/authState";
import { ConfigContext } from "./States/config/configState";
import { EnvContext } from "./States/env/envState";
import CartState from "./States/cart/cartState";
import KycState from "States/kyc/kycState";
import loadFavicon from "./Helpers/loadFavicon";
import handleError from "Helpers/handleError";
import Urls from "./Utils/urls";
import Router from "./Router";
import LoadingPage from "./Components/LoadingPage/LoadingPage";
import { runAuthentication } from "./Helpers/auth";
import { isTenantFeatureAvailable } from "Helpers/toggleFeatures";
import "./Assets/tailwind.generated.css";
import "./App.css";
import i18nextTranslate from "./Lang/i18nextTranslate";
import { i18nextKeys } from "./Lang/i18nextKeys";
import { LangContext } from "States/lang/langState";
import useFeatureAvailability from "Hooks/useFeatureAvailability";
import TENANT_FEATURE from "Enums/TenantFeature";
import useUserData from "Hooks/useUserData";
import useConfigSettings from "Hooks/useConfigSettings";
import { Amplitude, LogOnMount, useAmplitude } from "react-amplitude-hooks";
import ColorMiddleware from "./Middlewares/ColorMiddleware";

export default function App() {
  const location = useLocation();
  const history = useHistory();

  const {
    setAuthenticated,
    isAuthenticated,
    user,
    setAdmin: setAdminContext,
    isAdmin,
    isAdminSet,
    loading: isLoadingAuth
  } = useContext(AuthContext);
  const setAdmin = useRef(setAdminContext);

  const {
    loading: loadingEnv,
    loadEnv: loadEnvContext,
    env,
    error: envError
  } = useContext(EnvContext);
  const loadEnv = useRef(loadEnvContext);
  const {
    loading: loadingConfig,
    config,
    loadConfig: loadConfigContext
  } = useContext(ConfigContext);
  const loadConfig = useRef(loadConfigContext);
  const { getLang } = useContext(LangContext);

  const {
    isLoading: isLoadingFeatures,
    data: features
  } = useFeatureAvailability.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : [],
    enabled: !!env && !isLoadingAuth,
    onError: (error) => {
      handleError({ error, history });
    }
  });

  const {
    isLoading: isLoadingCurrentUserData,
    data: userData
  } = useUserData.query({
    queryFnArgs: [
      user?.profile?.sub,
      "KycData"
    ],
    enabled: !!user && !user.isNewUser && !!features,
    onSuccess: ({ IsAdmin }) => setAdmin.current(IsAdmin),
    onError: (error) => handleError({ error, history })
  });

  const {
    isLoading: isLoadingConfigSettings,
    data: configSettings
  } = useConfigSettings.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : [],
    enabled: !!env && !isLoadingAuth,
    onError: (error) => {
      handleError({ error, history });
    }
  });

  // Load env settings before anything else so that URLs are discovered
  useEffect(() => {
    const loadEnvSettingsFromDiscoService = async () => {
      try {
        await loadEnv.current();
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorMessageLoadEnvSettings
        );
        handleError({ error, history, message });
      }
    };
    loadEnvSettingsFromDiscoService();
  }, [history]);

  // Initialize URLs and start auth procedure (after environment has loaded)
  useEffect(() => {
    const authProcedure = async () => {
      try {
        Urls.initialize(env);
        await runAuthentication(location, history, setAuthenticated, env);
      } catch (error) {
        setAuthenticated(false);
        const message = i18nextTranslate(
          i18nextKeys.errorMessageAuthProcessFail
        );
        handleError({ error, history, message });
      }
    };

    if (!loadingEnv && !envError) {
      authProcedure();
    }
  }, [loadingEnv, env]); // eslint-disable-line

  // When the environment has loaded, we can set the stylesheet and the favicon
  useEffect(() => {
    if (env) {
      try {
        const { BlobUrl } = env;
        loadFavicon(BlobUrl);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorMessageLoadStylesheetFavicon
        );
        handleError({ error, history, message });
      }
    }
  }, [env, history]);

  // Load config after environment has loaded, and on isAuthenticated change because non-logged in users will receive a reduced config
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await loadConfig.current(isAuthenticated, env.TenantId);
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.errorMessageLoadConfig);
        handleError({ error, history, message });
      }
    };

    if (!loadingEnv && !envError && !isLoadingAuth) {
      fetchConfig();
    }
  }, [loadingEnv, isLoadingAuth, env, history, isAuthenticated]);

  // When config is loaded change the document title
  useEffect(() => {
    if (config) {
      document.title = config.websiteTitle;
    }
  }, [config]);

  // When the config has been loaded we can find out the appropriate intersection between user language and available languages
  useEffect(() => {
    if (configSettings) {
      getLang(user, configSettings.Languages);
    }
  }, [configSettings, user]);

  const { logEvent } = useAmplitude();
  useEffect(() => {
    const isReload = window.performance
      .getEntriesByType('navigation')
      .some(({ type }) => type === 'reload')
    if (isReload) {
      logEvent("Refresh", {
        screen: location.pathname.split("/")[1] || "home"
      });
    }
  }, []);

  // If any of these required requests/processes are not finished yet, show a fullscreen loading page
  if (
    (loadingEnv ||
      loadingConfig ||
      isLoadingFeatures ||
      isLoadingConfigSettings ||
      isLoadingCurrentUserData ||
      !isAdminSet) &&
    !envError
  ) {
    return <LoadingPage />;
  }

  return (
    <Amplitude
      userProperties={{
        appName: config.websiteTitle,
        loggedIn: isAuthenticated,
        role: isAdmin ? "admin" : "default",
        state: user?.state?.stateId
      }}
    >
      <LogOnMount
        eventType="Session started"
        eventProperties={{
          screen: location.pathname.split("/")[1] || "home",
          mode: configSettings.RestrictedMode ? "direct_link" : "regular"
        }}
      >
        <ColorMiddleware>
          <KycState
            isKycEnabled={isTenantFeatureAvailable(features, TENANT_FEATURE.kyc)}
            currentKycTier={userData?.KycData.UserTier}
            isAuthenticated={isAuthenticated}
          >
            <CartState userId={user?.profile?.sub}>
              <Router
                features={features}
                isAuthenticated={isAuthenticated}
                restrictedMode={!isAdmin && configSettings.RestrictedMode}
              />
            </CartState>
          </KycState>
        </ColorMiddleware>
      </LogOnMount>
    </Amplitude>
  );
}
