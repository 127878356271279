import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';
import api from 'Api';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { KycContext } from 'States/kyc/kycState';
import handleError from 'Helpers/handleError';
import Fade from 'Components/shared/Fade';
import UserInfoCard from './cards/UserInfoCard/UserInfoCard';
import KycCard from './cards/KycCard/KycCard';
import BankAccountsCard from './cards/BankAccountsCard/BankAccountsCard';
import WalletsCard from './cards/WalletsCard/WalletsCard';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import AccountHeader from './AccountHeader';
import useCollectionMethods from 'Hooks/useCollectionMethods';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import {
  BANK_TRANSFER,
  BITCOIN,
  ERC20,
  ETH
} from 'Enums/RedemptionMethodTypes';
import TENANT_FEATURE from 'Enums/TenantFeature';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';

const Account = () => {
  const {
    config: { redeemSettings }
  } = useContext(ConfigContext);
  const history = useHistory();
  const { data: features } = useFeatureAvailability.query({});
  const {
    data: isOneTimeWalletCollectionEnabled = false
  } = useCollectionMethods.methodQuery({
    type: COLLECT_METHOD.OneTimeWallet,
    refetchOnWindowFocus: false,
    select: ({ IsEnabled }) => IsEnabled
  });
  const {
    isAdmin,
    user: {
      profile: { sub },
    },
  } = useContext(AuthContext);
  const {
    isKycEnabled,
    currentKycTier,
    latestKycCase,
    isKycCaseLoading,
  } = useContext(KycContext);

  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [maxKycTier, setMaxKycTier] = useState(null);

  const fetchUserWallets = async () => {
    const data = await api.User.get(
      sub,
      'Wallets($filter=IsActive)'
    );
    setUser({ ...user, Wallets: data.Wallets });
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await api.User.get(
          sub,
          'HomeAddress,Wallets($filter=IsActive)'
        );
        setUser(user);
        if (isKycEnabled) {
          const tierList = await api.Kyc.getTierList();
          setMaxKycTier(tierList.value.length - 1);
        }
        setIsLoading(false);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorAccountNoRequiredData
        );
        handleError({ error, history, message });
      }
    };
    getUserData();
  }, []); // eslint-disable-line

  const [isBankRedemptionEnabled, isCryptoRedemptionEnabled] = useMemo(() => {
    let isBankRedemptionEnabled = false;
    let isCryptoRedemptionEnabled = false;
    if (isTenantFeatureAvailable(features, TENANT_FEATURE.redeem)) {
      for (const { enabled, type } of redeemSettings.redemptionMethods) {
        if (!enabled) {
          continue;
        }
        isBankRedemptionEnabled = isBankRedemptionEnabled || type === BANK_TRANSFER;
        isCryptoRedemptionEnabled = isCryptoRedemptionEnabled
          || type === BITCOIN || type === ERC20 || type === ETH;
        if (isBankRedemptionEnabled && isCryptoRedemptionEnabled) {
          break;
        }
      };
    }
    return [isBankRedemptionEnabled, isCryptoRedemptionEnabled];
  }, [features, redeemSettings]);


  return (
    <LogOnMount eventType="Account checked">
      <div data-qa="account" className="color-8">
        <Fade show={true}>
          <AccountHeader features={features} />
          <div className="flex flex-col items-center gap-12 md:gap-24">
            <UserInfoCard
              user={user}
              isLoading={isLoading}
              setUser={setUser}
            />
            {isKycEnabled && !isAdmin && (
              <KycCard
                kycTierNumber={currentKycTier}
                maxTierNumber={maxKycTier}
                kycCaseTier={latestKycCase.RequestedTierNumber}
                kycCaseStatus={latestKycCase.Status}
                kycCaseDeclineReason={latestKycCase.Comment}
                isLoading={isLoading || isKycCaseLoading}
              />
            )}

            {isBankRedemptionEnabled && <BankAccountsCard />}

            {((isTenantFeatureAvailable(
              features,
              [
                TENANT_FEATURE.purchase,
                TENANT_FEATURE.customFeaturedAsset
              ]
            ) && isOneTimeWalletCollectionEnabled) || isCryptoRedemptionEnabled) && (
              <WalletsCard
                wallets={user.Wallets}
                fetchUserWallets={fetchUserWallets}
              />
            )}
          </div>
        </Fade>
      </div>
    </LogOnMount>
  );
};

export default Account;
