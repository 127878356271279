import api from 'Api';

export const getMaxPurchasableUnits = (
  availableAmountAtIssuer,
  maxAmountBlobConfig,
  roundDown = true,
) => {
  let maxUnits;
  if (!isNaN(maxAmountBlobConfig)) {
    maxUnits = Math.min(availableAmountAtIssuer, maxAmountBlobConfig);
  } else {
    maxUnits = availableAmountAtIssuer;
  }
  return roundDown ? Math.floor(maxUnits) : maxUnits;
};

export const getAssetsConfig = (isAuthenticated, tenantId) => {
  if (isAuthenticated) {
    return api.Config.getFullConfig().then((config) => config.assets);
  }
  return api.Config.getReducedConfig(tenantId).then(
    (config) => config.assets,
  );
};

export const sortCartItems = (cartItems, fullAssetsInfo) => {
  const sortedAssets = {
    availableAssets: [],
    unavailableAssets: [],
    assetsExceedingMaxAmount: []
  };
  if (!fullAssetsInfo) {
    return sortedAssets;
  }
  Object.keys(cartItems).reduce((sortedAssets, uniqueAssetId) => {
    const currentItemFullInfo = fullAssetsInfo[uniqueAssetId];
    if (currentItemFullInfo?.availableAmount === 0 || currentItemFullInfo?.maxAmount === 0) {
      sortedAssets.unavailableAssets.push(uniqueAssetId);
    } else {
      sortedAssets.availableAssets.push(uniqueAssetId);
      if (cartItems[uniqueAssetId]?.Units > currentItemFullInfo?.maxPurchasableUnits) {
        sortedAssets.assetsExceedingMaxAmount.push(uniqueAssetId);
      }
    }
    return sortedAssets;
  }, sortedAssets);
  return sortedAssets;
};
