import axios from 'axios';
import { matchPath } from 'react-router-dom';
import Urls from '../Utils/urls';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { STORAGE_KEYS } from 'Enums';
import WLM_AUTH_STATE from 'Enums/WlmAuthState';
import { getPreferedRedirectPath } from 'Hooks/usePostLoginRedirect';
import { ROUTES } from 'Router/Routes';

let userManager = null;
export async function getUserManager(env) {
  if (userManager) {
    return userManager;
  }

  const { protocol, hostname, port = '' } = window.location;
  const baseRedirectUri = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const loginRedirectUri = `${baseRedirectUri}`;
  const postlogoutRedirectUri = `${baseRedirectUri}`;

  const authConfig = env.AuthConfig;
  userManager = new UserManager({
    authority: env.Endpoints.AuthApi,
    client_id: authConfig.ClientId,
    redirect_uri: `${authConfig.RedirectUri || loginRedirectUri}/auth/callback`,
    post_logout_redirect_uri: `${
      authConfig.PostLogoutRedirectUri || postlogoutRedirectUri
    }`,
    response_type: 'id_token token',
    scope: `${authConfig.Scopes}`,
    userStore: new WebStorageStateStore({ store: localStorage }),
  });

  return userManager;
}

export async function runAuthentication(
  location,
  history,
  setAuthenticated,
  env
) {
  const userManager = await getUserManager(env);
  userManager.clearStaleState();

  if (matchPath(location.pathname, '/auth/callback')) {
    // redirected from IS
    // execute callback und change location to either previously accessed one or root
    await userManager.signinCallback();
    window.location.replace(getPreferedRedirectPath());
  } else {
    // get user from browser store
    // redirect to IS login if user does not exist
    const user = await userManager.getUser();
    if (user) {
      const { access_token } = user;
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      try {
        // 1. check if T&Cs are accepted
        const {
          data: { isNewUser, state },
        } = await axios.post(`${Urls.get().clApi}/auth/signin-oidc`, {});
        user.isNewUser = isNewUser;
        // state will be null for new users using 3rd party login thus we can only trust it for existing users
        user.isConfirmedByAdmin =
          state?.stateId == WLM_AUTH_STATE.CONFIRMED_BY_ADMIN;
        user.state = state;
        setAuthenticated(true, user);

        if (isNewUser) {
          history.replace(ROUTES.terms);
          return;
        }
        // 2. Check if email is confirmed
        const {
          profile: { email_verified },
        } = user;
        if (!email_verified) {
          history.replace(ROUTES.confirmEmail);
        }
      } catch (err) {
        console.log(err);
        delete axios.defaults.headers.common['Authorization'];
        setAuthenticated(false);
      }
    } else {
      // redirect to IS login for auth requiring routes
      if (matchPath(location.pathname, [
        ROUTES.cart,
        ROUTES.checkout,
        ROUTES.redemption,
        ROUTES.account,
        ROUTES.admin.base
      ])) {
        sessionStorage.setItem(
          STORAGE_KEYS.authReturnPath,
          location.pathname
        );
        delete axios.defaults.headers.common['Authorization'];
        userManager.signinRedirect({
          state: { originUrl: window.location.href },
        });
        // set auth false if unprotected routes are accessed
      } else {
        delete axios.defaults.headers.common['Authorization'];
        setAuthenticated(false);
      }
    }
  }
}
