import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';
import errorNotification from './errorNotification';

const handleError = ({
  error,
  history,
  message = i18nextTranslate(i18nextKeys.errorDefaultMessage),
  finalNote = true,
  errorDetails = [],
}) => {
  const errorCode = error?.response?.status;
  if (errorCode === 400) {
    errorNotification(error, message, finalNote);
  } else {
    history.push(ROUTES.error, { errorCode, errorDetails });
  }
};

export default handleError;
