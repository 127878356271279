import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import handleError from 'Helpers/handleError';
import { UiContext } from 'States/ui/uiState';
import { Button } from 'Components/shared/buttons';
import { DropdownField } from 'Components/shared/formElements';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const StatusUpdater = ({
  label,
  errorMessage,
  id,
  status,
  statusEnum = {},
  updateRemoteStatus = async () => {},
  updateLocalStatus = () => {},
  dataQa = "status-update"
}) => {
  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [newStatus, setNewStatus] = useState(null);

  useEffect(() => {
    if (!status || status === newStatus) {
      return;
    }
    setNewStatus({
      label: statusEnum.enumToFriendlyString(status),
      value: status
    });
  }, [status]);

  useEffect(() => {
    const generateStatusOptions = () => {
      const options = Object.values(statusEnum.values)
        .map((value) => ({
          label: statusEnum.enumToFriendlyString(value),
          value
        }));
      setStatusOptions(options);
    };
    if (statusEnum.values) {
      generateStatusOptions();
    }
  }, [statusEnum]);

  const onStatusChange = (value, option) => setNewStatus(option);

  const saveStatus = async () => {
    if (newStatus.value === status) {
      return;
    };
    try {
      setIsLoading(true);
      await updateRemoteStatus(id, {
        Id: id,
        Status: newStatus.value,
      })
      updateLocalStatus(newStatus.value);
    } catch (error) {
      handleError({ error, history, errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center gap-16">
      <DropdownField
        dataQa={dataQa}
        label={label}
        options={statusOptions}
        input={{
          onChange: onStatusChange,
          value: newStatus
        }}
        meta={{}}
        style={{ width: xxl ? '376px' : md ? '296px' : "100%" }}
        disabled={isLoading || !newStatus}
        showErrors={false}
      />
      <Button
        dataQa={`${dataQa}-button`}
        text={i18nextTranslate(i18nextKeys.buttonSave)}
        onClick={saveStatus}
        className="self-end"
        width={{
          xxl: '210px',
          md: '160px',
          default: '100%'
        }}
        loading={isLoading || !newStatus}
      />
    </div>
  );
};

export default StatusUpdater;
