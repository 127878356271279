import React, { useState } from "react";
import { Field as FinalField } from "react-final-form";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { capitalizeString } from 'Utils/utils';
import { isRequiredIfWithMessage } from "Utils/validators";
import { TextButton } from "Components/shared/buttons";
import { FormField } from "Components/shared/formElements";
import { TrashCanSymbol } from "Components/shared/symbols";
import { SubsectionHeading } from "Features/admin/ConfigManagement/shared";
import { Description, TranslationsForm } from "Features/admin/ConfigManagement/shared/translations";

const AdditionalIdentifier = ({
  translations,
  fieldNamePrefix,
  fieldWidth,
  textAreaHeight,
  remove = () => {}
}) => {
  const [heading, setHeading] = useState(null);

  const defaultTranslation = {
    Name: null,
    Description: null
  };

  return (
    <div className="flex flex-col gap-24 p-24 bg-4_7 rounded border border-dashed border-color-5">
      <div className="flex items-center justify-between">
        <SubsectionHeading
          text={heading || i18nextTranslate(
            i18nextKeys.configPaymentBankTransferIdentifiersLabelNameDefault
          )}
          className=""
        />
        <TextButton
          icon={TrashCanSymbol}
          iconSize={{
            xxl: "20",
            default: "16"
          }}
          onClick={remove}
        />
      </div>
      <TranslationsForm
        fieldArrayName={`${fieldNamePrefix}.Translations`}
        translations={translations}
        defaultValue={defaultTranslation}
        fieldWidth={fieldWidth}
        fields={({
          fieldNamePrefix: translationFieldNamePrefix,
          languageCode,
          isDefaultLanguage = false
        }) => {
          const language = i18nextTranslate(i18nextKeys[
            `languages${capitalizeString(languageCode)}`
          ]);
          return (
            <div className="flex flex-col gap-8">
              <FinalField
                name={`${translationFieldNamePrefix}.Name`}
                label={
                  isDefaultLanguage
                    ? i18nextTranslate(
                      i18nextKeys.configPaymentBankTransferIdentifiersLabelNameDefault
                    )
                    : i18nextTranslateDynamically(
                      i18nextKeys.configPaymentBankTransferIdentifiersLabelNameLanguage,
                      { language }
                    )
                }
                required={true}
                validate={isRequiredIfWithMessage(
                  () => true,
                  i18nextTranslate(
                    i18nextKeys.configPaymentBankTransferIdentifiersLabelNameDefault
                  )
                )}
              >
                {({ input: { onChange, ...inputProps } , meta, ...props }) => (
                  <FormField
                    style={{ width: fieldWidth }}
                    input={{
                      onChange: ({ target: { value } }) => {
                        onChange(value);
                        if (isDefaultLanguage) {
                          setHeading(value);
                        }
                      },
                      ...inputProps
                    }}
                    meta={meta}
                    {...props}
                  />
                )}
              </FinalField>
              {isDefaultLanguage && (
                <FinalField
                  name={`${fieldNamePrefix}.Value`}
                  label={i18nextTranslate(
                    i18nextKeys.configPaymentBankTransferIdentifiersLabelValue
                  )}
                  required
                  validate={isRequiredIfWithMessage(
                    () => true,
                    i18nextTranslate(i18nextKeys.configPaymentBankTransferIdentifiersLabelValue)
                  )}
                >
                  {(props) => (
                    <FormField
                      style={{ width: fieldWidth }}
                      {...props}
                    />
                  )}
                </FinalField>
              )}
              <FinalField
                name={`${translationFieldNamePrefix}.Description`}
                label={isDefaultLanguage
                  ? i18nextTranslate(
                    i18nextKeys.configPaymentBankTransferIdentifiersLabelDescriptionDefault
                  )
                  : i18nextTranslateDynamically(
                    i18nextKeys.configPaymentBankTransferIdentifiersLabelDescriptionLanguage,
                    { language }
                  )
                }
              >
                {(props) => (
                  <FormField
                    style={{ width: fieldWidth }}
                    height={textAreaHeight}
                    multiline
                    {...props}
                  />
                )}
              </FinalField>
            </div>
          )
        }}
      />
    </div>
  );
};

export default AdditionalIdentifier;
