import React, { useState } from 'react';
import {
  Field as FinalField,
} from 'react-final-form';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { FormField } from 'Components/shared/formElements';
import CurrencyInput from 'Components/shared/formElements/CurrencyInput';
import { Button } from 'Components/shared/buttons';
import { TrashCanSymbol } from 'Components/shared/symbols';
import FormStep from 'Components/shared/formElements/FormStep';
import { isRequiredWithMessage, isRequiredIfWithMessage } from 'Utils/validators';

const BankDetailsForm = ({
  initialValues = {},
  removeIntermediaryBank = () => {},
  intermediary = false,
  showDeleteButton = false,
  style = {},
  dataQa = "bank"
}) => {
  const [saveAccount, setSaveAccount] = useState(false);
  const valuesKey = `bankAccount${intermediary ? '.IntermediaryBankAccount' : ''}`;
  const dataQaPrefix = intermediary
    ? `${dataQa}-intermediary`
    : dataQa

  const shouldSaveAccount = (IsActive) => {
    if (saveAccount !== IsActive) {
      setSaveAccount(IsActive);
    }
    return IsActive;
  };

  return (
    <div
      className="flex flex-col"
      style={style}
    >
      <FormStep
        number="1"
        text={i18nextTranslate(
          intermediary
            ? i18nextKeys.commonBankIntermediaryAccountDetails
            : i18nextKeys.commonBankAccountDetails
        )}
        dataQa={`${dataQaPrefix}-details-title`}
      />
      <div className="flex-grow flex flex-col gap-6 xxl:gap-3">
        <div className="flex justify-between">
          <FinalField
            name={`${valuesKey}.BankName`}
            label={i18nextTranslate(
              intermediary
                ? i18nextKeys.commonBankIntermediaryName
                : i18nextKeys.commonBankName
            )}
            initialValue={initialValues?.BankName}
            validate={isRequiredWithMessage(
              i18nextTranslate(i18nextKeys.commonBankName)
            )}
            render={(props) => (
              <FormField
                containerStyle={{
                  width: intermediary ? '100%' : '45%'
                }}
                dataQa={`${dataQaPrefix}-name`}
                {...props}
              />
            )}
            required
          />
          {!intermediary && (
            <FinalField
              name={`${valuesKey}.Currency`}
              label={i18nextTranslate(i18nextKeys.commonCurrency)}
              initialValue={initialValues?.Currency}
              validate={isRequiredIfWithMessage(
                ({ bankAccount }) => {
                  return bankAccount?.Id
                    ? saveAccount
                    : shouldSaveAccount(!!bankAccount?.IsActive)
                },
                i18nextTranslate(i18nextKeys.commonCurrency)
              )}
              render={(props) => (
                <CurrencyInput
                  dataQa={`${dataQaPrefix}-currency`}
                  required={saveAccount}
                  style={{ width: '45%' }}
                  {...props}
                />
              )}
            />
          )}
        </div>

        <FinalField
          name={`${valuesKey}.IBAN`}
          label={i18nextTranslate(
            intermediary
              ? i18nextKeys.commonBankIntermediaryIban
              : i18nextKeys.commonBankIban
          )}
          initialValue={initialValues?.IBAN}
          validate={isRequiredWithMessage(
            i18nextTranslate(i18nextKeys.commonBankName)
          )}
          render={(props) => (
            <FormField
              dataQa={`${dataQaPrefix}-iban`}
              {...props}
            />
          )}
          required
        />
        <FinalField
          name={`${valuesKey}.SWIFT`}
          required={intermediary}
          initialValue={initialValues?.SWIFT}
          validate={isRequiredIfWithMessage(
            () => intermediary,
            i18nextTranslate(i18nextKeys.commonBankIntermediarySwift)
          )}
          label={i18nextTranslate(
            intermediary
              ? i18nextKeys.commonBankIntermediarySwift
              : i18nextKeys.commonBankSwift
          )}
          render={(props) => (
            <FormField
              dataQa={`${dataQaPrefix}-swift`}
              {...props}
            />
          )}
        />
        {!intermediary && (
          <>
            <FinalField
              name={`${valuesKey}.BIC`}
              label={i18nextTranslate(i18nextKeys.commonBankBic)}
              initialValue={initialValues?.BIC}
              render={(props) => (
                <FormField
                  dataQa={`${dataQaPrefix}-bic`}
                  {...props}
                />
              )}
            />
            <FinalField
              name={`${valuesKey}.AdditionalDetails`}
              label={i18nextTranslate(i18nextKeys.commonBankAdditionalDetails)}
              initialValue={initialValues?.AdditionalDetails}
              placeholder={i18nextTranslate(i18nextKeys.commonBankAdditionalDetailsPlaceholder)}
              keepDirtyOnReinitialize
              render={(props) => (
                <FormField
                  dataQa={`${dataQaPrefix}-additionalData`}
                  maxLength={300}
                  style={{
                    lineHeight: "1.194rem",
                    marginBottom: "0.125rem"
                  }}
                  {...props}
                  showErrors={false}
                  multiline
                />
              )}
            />
          </>
        )}
        {showDeleteButton && (
          <Button
            text={i18nextTranslate(i18nextKeys.commonBankIntermediaryDelete)}
            icon={TrashCanSymbol}
            onClick={removeIntermediaryBank}
            dataQa="bank-intermediary-delete"
            className="flex-row-reverse mt-auto"
            fullWidth
            outlined
          />
        )}
      </div>
    </div>
  );
};

export default BankDetailsForm;
