import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from 'States/lang/langState';
import Select from 'react-select';
import { DropdownIndicator } from 'Components/shared/formElements/Dropdown/DropdownField';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import useConfigSettings from 'Hooks/useConfigSettings';
import { useAmplitude } from 'react-amplitude-hooks';

const LanguageSwitcher = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { lang, setLang } = useContext(LangContext);
  const { user, isAuthenticated } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const { logEvent } = useAmplitude((inheritedProps) => ({
    ...inheritedProps,
  }));
  const { data: settings } = useConfigSettings.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : []
  });

  useEffect(() => {
    setSelectedLanguage({ Code: lang });
  }, [lang]);

  if (settings.Languages.length === 0 || !selectedLanguage) return null;

  const options = settings.Languages.sort((a, b) => a.Code.localeCompare(b.Code));

  const onLanguageSelectionChange = ({ Code }) => {
    logEvent("Language changed", { "Language selected": Code });
    setSelectedLanguage({ Code });
    setLang(Code, user);
  };
  return (
    <div
      className="flex-shrink-0"
      data-qa="language-switcher-container"
    >
      <Select
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        isDisabled={false}
        menuPlacement="bottom"
        maxMenuHeight={200}
        isSearchable={false}
        options={options}
        getOptionLabel={(option) => option.Code}
        getOptionValue={(option) => option.Code}
        value={selectedLanguage}
        onChange={onLanguageSelectionChange}
        styles={{
          control: (
            { color, borderColor, boxShadow, ...provided },
            { theme, menuIsOpen }
          ) => ({
            ...provided,
            cursor: 'pointer',
            padding: 0,
            minHeight: 0,
            color: 'var(--color-7)',
            border: 0,
            borderColor: theme.colors.neutral20,
            background: 'transparent',
            backgroundColor:
                menuIsOpen
                  ? 'var(--color-9-10)'
                  : 'var(--color-9)',
          }),

          dropdownIndicator: (provided, { selectProps }) => ({
            transform: selectProps.menuIsOpen ? 'scale(1, -1)' : 'scale(1)',
          }),

          menu: (provided) => ({
            ...provided,
            margin: 0,
          }),

          menuList: (provided) => ({
            backgroundColor: "var(--color-9-10)",
            ...provided,
          }),
          option: ({ cursor, ...provided }, { isFocused, isSelected }) => ({
            paddingTop: '2px',
            paddingBottom: '2px',
            color: 'var(--color-10)',
            paddingLeft: '1px',
            paddingRight: '1px',
            margin: 'auto',
            textAlign: 'center',
            borderTop: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            backgroundColor: (isSelected && isFocused) || isFocused ? 'var(--color-9--5)' : 'var(--color-9-10)',
          }),

          singleValue: ({ color, ...provided }, { isDisabled }) => ({
            ...provided,
            gridColumnStart: 2,
            color: isDisabled ? 'var(--color-10)' : 'inherit',
            fontWeight: '700',
            marginTop: '3px',
            textTransform: 'uppercase',
          }),

          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
