import api from 'Api';
import { useMutation, useQuery } from 'react-query';
import queryClients from 'Helpers/queryClients';

const FEATURE_AVAILABILITY_QUERY_KEY = 'featureAvailabilityQuery';

const useFeatureAvailabilityQuery = ({
  queryKeys = [],
  queryFnArgs = [],
  ...config
}) => {
  return useQuery({
    queryKey: [FEATURE_AVAILABILITY_QUERY_KEY, ...queryKeys],
    queryFn: () =>
      api.Config.Features.get(...queryFnArgs).then((data) => data.value),
    refetchOnWindowFocus: false,
    ...config,
  });
};

const FEATURE_AVAILABILITY_MUTATION_KEY = 'featureAvailabilityMutation';

const useFeatureAvailabilityMutation = ({ mutationKeys = [], ...config }) => {
  return useMutation({
    mutationKey: [FEATURE_AVAILABILITY_MUTATION_KEY, ...mutationKeys],
    mutationFn: ({ type, data }) => api.Config.Features.patch(type, data),
    onMutate: async ({ type, data }) => {
      await queryClients.rootQueryClient.cancelQueries(
        FEATURE_AVAILABILITY_QUERY_KEY
      );
      queryClients.rootQueryClient.setQueryData(
        FEATURE_AVAILABILITY_QUERY_KEY,
        (oldFeatureAvailability) => {
          return oldFeatureAvailability.map((feature) => {
            if (feature.Type === type) {
              return { ...feature, ...data };
            }
            return feature;
          });
        }
      );
    },
    onSuccess: () => {
      queryClients.rootQueryClient.invalidateQueries(
        FEATURE_AVAILABILITY_QUERY_KEY
      );
    },
    ...config,
  });
};

const useFeatureAvailability = {
  query: useFeatureAvailabilityQuery,
  mutation: useFeatureAvailabilityMutation,
};

export default useFeatureAvailability;
