import { numericSort, dateSort } from 'Components/shared/Table/sorters';
import formatDate from 'Utils/formatDate';
import CollectMethodEnum from 'Enums/CollectMethod';
import PaymentMethodEnum from 'Enums/PaymentMethod';
import OrderStatusEnum from 'Enums/OrderStatus';
import i18nextTranslate from '../Lang/i18nextTranslate';
import { i18nextKeys } from '../Lang/i18nextKeys';

export const getOrderTableColumns = (formatNumber, xxl) => [
  {
    Header: i18nextTranslate(i18nextKeys.commonReferenceCode),
    accessor: 'ReferenceCode',
    id: 'ReferenceCode',
    disableSortBy: true,
    color: 'color-4',
    width: xxl ? '150px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperOrderUser),
    accessor: (row) => `${row.User.FirstName} ${row.User.LastName}`,
    sortType: 'basic',
    id: 'User',
    color: 'color-4',
    width: xxl ? '190px' : '150px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.commonDate),
    accessor: (row) => `${formatDate(row.ReceivedOn)}`,
    id: 'ReceivedOn',
    sortType: (rowA, rowB) => dateSort(rowA, rowB, 'ReceivedOn'),
    color: 'color-8',
    width: xxl ? '90px' : '82px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperOrderBilled),
    accessor: (row) =>
      `${formatNumber(row.AmountDue)} ${row.AmountDueCurrency}`,
    id: 'AmountDue',
    sortType: (rowA, rowB) => numericSort(rowA, rowB, 'AmountDue'),
    color: 'color-8',
    width: xxl ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperOrderCollectedVia),
    accessor: (row) =>
      CollectMethodEnum.enumToFriendlyString(row.CollectMethod),
    id: 'CollectMethod',
    sortType: 'basic',
    color: 'color-8',
    width: xxl ? '134px' : '125px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperOrderPayVia),
    accessor: (row) =>
      PaymentMethodEnum.enumToFriendlyString(row.PaymentMethod),
    id: 'PaymentMethod',
    sortType: 'basic',
    color: 'color-8',
    width: xxl ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperOrderStatus),
    accessor: (row) => OrderStatusEnum.enumToFriendlyString(row.Status),
    id: 'Status',
    sortType: 'basic',
    color: 'color-8',
    width: xxl ? '119px' : '85px',
    disableFilters: true,
  },
];
