import React, { useContext } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { ROUTES } from 'Router/Routes';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import { Button } from 'Components/shared/buttons';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { ConfigContext } from 'States/config/configState';
import { UiContext } from 'States/ui/uiState';
import { Arrow } from '../symbols';

const DefaultCardKycButtonGroup = ({
  buttonConfig = {
    topButton: {
      show: true,
      label: {
        insufficientTier: i18nextKeys.buttonMoreInfo,
        sufficientTier: i18nextKeys.buttonMoreInfo,
      },
      onClick: () => { },
    },
    bottomButton: {
      show: true,
      disabled: false,
      label: {
        insufficientTier: i18nextKeys.kycGetTierToSelect,
        sufficientTier: i18nextKeys.buttonSelectThisMethod,
        disabled: i18nextKeys.buttonNotAvailable
      },
      onClick: () => {},
      origin: ""
    }
  },
  requiredTier
}) => {
  const { logEvent } = useAmplitude();
  const {
    config: { kycTiersDescription }
  } = useContext(ConfigContext);
  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const { isUserTierSufficient } = useKycTierCheck(requiredTier);

  const { topButton, bottomButton } = buttonConfig;

  const onClick = () => {
    if (isUserTierSufficient) {
      bottomButton.onClick();
      return;
    }
    logEvent("KYC started", {
      origin: bottomButton.origin,
      provider: kycTiersDescription[1]?.providers[0].name !== KYC_PROVIDER.manual
    });
    window.open(ROUTES.kyc, '_blank');
  };

  const buttonWidth = {
    md: "100%",
    default: topButton.show && bottomButton.show ? "142px" : "100%"
  };

  return (
    <div
      className="grid md:px-16 gap-12"
      style={{
        gridArea: 'buttons',
        gridTemplateColumns: !md
          ? topButton.show && bottomButton.show
            ? '1fr 1fr'
            : '1fr'
          : '1fr',
        height: "min-content"
      }}
    >
      {topButton.show && (
        <Button
          dataQa="kycButtonGroup-topButton"
          onClick={topButton.onClick}
          text={i18nextTranslate(
            isUserTierSufficient
              ? topButton.label?.sufficientTier
              : topButton.label?.insufficientTier
          )}
          width={buttonWidth}
        />
      )}

      {bottomButton.show && (
        <Button
          dataQa="kycButtonGroup-bottomButton"
          text={
            isUserTierSufficient
              ? bottomButton.disabled
                ? i18nextTranslate(bottomButton.label?.disabled)
                : i18nextTranslate(bottomButton.label?.sufficientTier)
              : i18nextTranslateDynamically(
                  bottomButton.label?.insufficientTier,
                  { tier: requiredTier }
                )
          }
          icon={isUserTierSufficient ? null : Arrow}
          onClick={onClick}
          className="justify-self-end"
          secondary={!isUserTierSufficient}
          width={buttonWidth}
          disabled={bottomButton.disabled}
        />
      )}
    </div>
  );
};

export default DefaultCardKycButtonGroup;
