let dev = {
  blob: '/blob',
  clApi: '/clapi/api',
  wlpApi: '/wlpapi/odata',
  wlpApiHost: '/wlpapi',
  kycApi: '/kycapi/odata',
  teosApi: '/teosapi/odata/v0.9',
  authApi: '/authapi/api'
};

let prod;

let initialized = false;
export const initialize = (env) => {
  if (initialized) return;
  initialized = true;
  prod = {
    blob: env.BlobUrl,
    clApi: env.Endpoints.WlmApi,
    wlpApi: env.Endpoints.WlpApi + '/odata',
    wlpApiHost: env.Endpoints.WlpApi,
    kycApi: env.Endpoints.KycApi + '/odata',
    teosApi: env.Endpoints.TeosApi + '/odata/v0.9',
    authApi: env.Endpoints.AuthApi + '/api'
  };
};

export const get = () => {
  if (!initialized) {
    return;
  }
  return process.env.NODE_ENV === 'development' ? dev : prod;
};

const Urls = {
  initialize,
  get,
};

export default Urls;
