import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import formatDate from 'Utils/formatDate';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import { Button } from 'Components/shared/buttons';
import { Tile, HorizontalRule } from 'Components/shared/Tile';
import Tooltip from 'Components/shared/Tooltip';
import DeclineCaseModal from './Modal/DeclineCaseModal';
import handleError from 'Helpers/handleError';
import api from 'Api';
import { KYC_CASE_STATUS } from 'Enums/KycCaseStatus';
import { ShuftiProVerificationState } from 'Enums/KycVerificationState';

const KycInformation = ({
  kycRecord: {
    Id,
    Status,
    OpenDate,
    CloseDate,
    InitialTierNumber,
    RequestedTierNumber,
    Comment,
  },
  verification: {
    Provider,
    UpdatedOn,
    Status: verificationStatus,
    DeclinedReason
  },
  onShowProviderResponse = () => { },
  onShowVerificationDeclineReason = () => { },
  onShowCaseDeclineReason = () => { },
}) => {
  const history = useHistory();
  const [denialReason, setDenialReason] = useState('');
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const { logEvent } = useAmplitude();

  const isCaseOpen = Status === KYC_CASE_STATUS.open;
  const isCaseDeclined = Status === KYC_CASE_STATUS.declined;
  const isVerificationAccepted =
    verificationStatus === ShuftiProVerificationState.VERIFICATION_ACCEPTED ||
    Provider === KYC_PROVIDER.manual;
  const isVerificationDeclined =
    verificationStatus === ShuftiProVerificationState.VERIFICATION_DECLINED;
  const getStatusColorClass = (status) => {
    switch (status) {
      case ShuftiProVerificationState.VERIFICATION_ACCEPTED:
      case KYC_CASE_STATUS.approved:
        return 'text-green-400';
      case ShuftiProVerificationState.REQUEST_RECEIVED:
      case ShuftiProVerificationState.REQUEST_PENDING:
      case KYC_CASE_STATUS.open:
        return 'text-orange-400';
      default:
        return 'text-red-400';
    }
  }

  const onDecline = () => {
    setShowDeclineModal(true);
  };

  const onApprove = () => {
    approveCase();
  };

  const approveCase = async () => {
    try {
      await api.Kyc.closeCase(Id, {
        Status: KYC_CASE_STATUS.approved,
      });
      logEvent("KYC status changed", {
        "reason added": false
      });
      window.close();
    } catch (error) {
      handleError({ error, history });
    }
  };

  const declineCase = async (comment = '') => {
    const caseData = {
      Status: KYC_CASE_STATUS.declined,
    };
    if (comment.length) {
      caseData.Comment = comment;
    }

    try {
      await api.Kyc.closeCase(Id, caseData);
      logEvent("KYC status changed", {
        "reason added": !!comment.length
      });
      window.close();
    } catch (error) {
      handleError({ error, history });
    }
  };

  const buttonWidth = {
    xxl: "190px",
    xl: "170px",
    md: "146px",
    default: "140px"
  };

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.kycManagementRecordInformationHeader)}
      dataQa="kyc-management-case"
    >
      <div
        data-qa="admin-kycDetails-recordInfo"
      >
        <div className="flex flex-col md:flex-row justify-between pb-16">
          <div className="flex flex-col flex-basis-100">
            <span data-qa="admin-kycDetails-recordInfo-statusUpdateDate">
              <span className="font-bold">
                {i18nextTranslate(
                  i18nextKeys.kycManagementDetailsStatusUpdateDate
                )}
                {`: `}
              </span>
              {formatDate(UpdatedOn)}
            </span>
            <span data-qa="admin-kycDetails-recordInfo-requestStatus">
              <span className="font-bold">
                {i18nextTranslate(
                  i18nextKeys.kycManagementDetailsRequestStatus
                )}
                {`: `}
              </span>
              <span className={getStatusColorClass(verificationStatus)}>
                {verificationStatus}
              </span>
            </span>
          </div>
          <div className="flex flex-col flex-basis-100 pt-16 md:pt-0">
            <span data-qa="admin-kycDetails-recordInfo-openDate">
              <span className="font-bold">
                {i18nextTranslate(i18nextKeys.kycManagementDetailsOpenDate)}
                {`: `}
              </span>
              {formatDate(OpenDate)}
            </span>
            <span data-qa="admin-kycDetails-recordInfo-completionDate">
              <span className="font-bold">
                {i18nextTranslate(
                  i18nextKeys.kycManagementDetailsCompletionDate
                )}
                {`: `}
              </span>
              {CloseDate ? formatDate(CloseDate) : '-'}
            </span>
            {Provider !== KYC_PROVIDER.manual && (
              <span data-qa="admin-kycDetails-recordInfo-providerResponse">
                <span className="font-bold">
                  {i18nextTranslate(
                    i18nextKeys.kycManagementDetailsProviderResponse
                  )}
                  {`: `}
                </span>
                <span
                  className="color-4 underline cursor-pointer"
                  onClick={() => onShowProviderResponse()}
                >
                  {i18nextTranslate(i18nextKeys.kycManagementDetailsReadResponse)}
                </span>
              </span>
            )}
          </div>
        </div>
        {isVerificationDeclined
          && DeclinedReason
          && (
            <div
              data-qa="admin-kycDetails-providerResponseExerpt"
              className="flex flex-col rounded"
            >
              <span
                data-qa="admin-kycDetails-providerResponseExerpt-text"
                className="mb-12 multiline-truncate-3"
              >
                <span className="font-bold">
                  {i18nextTranslate(i18nextKeys.kycManagementDetailsAdminReason)}
                  {`: `}
                </span>
                {DeclinedReason}
              </span>
              <span
                className="color-4 underline cursor-pointer"
                onClick={() => onShowVerificationDeclineReason()}
              >
                {i18nextTranslate(i18nextKeys.kycManagementDetailsReadFullReason)}
              </span>
            </div>
          )
        }
        <HorizontalRule className="my-24" />
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col flex-basis-100">
            <span data-qa="admin-kycDetails-recordInfo-tierAtStart">
              <span className="font-bold">
                {i18nextTranslate(
                  i18nextKeys.kycManagementDetailsProviderTierAtStart
                )}
                {`: `}
              </span>
              {InitialTierNumber}
            </span>
            <span data-qa="admin-kycDetails-recordInfo-requestedTier">
              <span className="font-bold">
                {i18nextTranslate(
                  i18nextKeys.kycManagementDetailsProviderRequestedTier
                )}
                {`: `}
              </span>
              {RequestedTierNumber}
            </span>
          </div>
          {isCaseOpen ? (
            <div className="flex flex-row gap-16 justify-around items-center mt-16 md:mt-0">
              <Tooltip
                text={i18nextTranslate(i18nextKeys.kycCaseTooltipDecline)}
              >
                <Button
                  data-qa="admin-kycDetails-recordInfo-buttonDecline"
                  text={i18nextTranslate(i18nextKeys.buttonDecline)}
                  onClick={onDecline}
                  width={buttonWidth}
                  secondary
                />
              </Tooltip>

              {isVerificationAccepted ? (
                <Tooltip
                  text={i18nextTranslate(i18nextKeys.kycCaseTooltipApprove)}
                >
                  <Button
                    data-qa="admin-kycDetails-recordInfo-buttonApprove"
                    text={i18nextTranslate(i18nextKeys.buttonApprove)}
                    onClick={onApprove}
                    width={buttonWidth}
                  />
                </Tooltip>
              ) : null}
            </div>
          ) : (
            <div className="flex flex-col flex-basis-100">
              <span data-qa="admin-kycDetails-recordInfo-caseStatus mt-16 md:mt-0">
                <span className="font-bold">
                  {i18nextTranslate(i18nextKeys.kycManagementDetailsCaseStatus)}
                  {`: `}
                </span>
                <span className={getStatusColorClass(Status)}>
                  {Status}
                </span>
              </span>
              {isCaseDeclined
                && Comment
                && (
                  <>
                    <span
                      data-qa="admin-kycDetails-recordInfo-adminResponse"
                      className="my-12 multiline-truncate-3"
                    >
                      <span className="font-bold">
                        {i18nextTranslate(
                          i18nextKeys.kycManagementDetailsAdminReason
                        )}
                        {`: `}
                      </span>
                      {Comment}
                    </span>
                    <span
                      className="color-4 underline cursor-pointer"
                      onClick={() => onShowCaseDeclineReason()}
                    >
                      {i18nextTranslate(
                        i18nextKeys.kycManagementDetailsReadFullReason
                      )}
                    </span>
                  </>
                )
              }
            </div>
          )}
        </div>
      </div>
      <DeclineCaseModal
        showModal={showDeclineModal}
        hideModal={() => setShowDeclineModal(false)}
        denialReason={denialReason}
        setDenialReason={(value) => setDenialReason(value)}
        onSkip={() => declineCase('')}
        onAdd={() => declineCase(denialReason)}
      />
    </Tile>
  );
};

export default KycInformation;
