import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Field as FinalField,
  Form as FinalForm
} from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useHistory, useParams } from "react-router-dom";
import api from "Api";
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import handleError from "Helpers/handleError";
import { DIRECTION } from 'Helpers/icons';
import useConfigSettings from 'Hooks/useConfigSettings';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { PAYMENT_PROVIDER, TENANT_FEATURE } from "Enums";
import { PAYMENT_METHOD } from "Enums/PaymentMethod";
import { UiContext } from "States/ui/uiState";
import { NumberInput } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { Arrow } from "Components/shared/symbols";
import { HorizontalRule } from "Components/shared/Tile";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import SubsectionHeading from "../../shared/SubsectionHeading";
import { Description, TranslationsForm } from "../../shared/translations";
import Footer from "../Footer";
import {
  AdditionalIdentifiers,
  BankAccount,
  IntermediaryBankAccount
} from "./BankAccount";
import BankCard from "./BankCard";
import CryptoCurrency from "./CryptoCurrency";
import KYCTier from "./KYCTier";

const PaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [providerConfig, setProviderConfig] = useState(null);
  const [isSingleEnabledMethod, setIsSingleEnabledMethod] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    type: paymentMethodType
  } = useParams();
  const history = useHistory();

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const {
    data: {
      Currency = {}
    }
  } = useConfigSettings.query({
    onError: (error) => {
      handleError({ error, history });
    }
  });

  const {
    data: isPurchaseEnabled
  } = useFeatureAvailability.query({
    refetchOnMount: false,
    select: useCallback(features => features.some(feature =>
      feature.IsEnabled &&
      (feature.Type === TENANT_FEATURE.purchase || feature.Type === TENANT_FEATURE.customFeaturedAsset)
    ), []),
    onError: (error) => handleError({ error, history })
  });

  useEffect(() => {
    const getPaymentMethod = async (type) => {
      try {
        if (isPurchaseEnabled) {
          const { value: paymentMethods } = await api.Config.PaymentMethods.getAll();
          const paymentMethod = paymentMethods.find(({ Type }) => Type === paymentMethodType);
          if (paymentMethod.IsEnabled) {
            const enabledMethods = paymentMethods.filter(
              ({ IsEnabled }) => IsEnabled
            );
            setIsSingleEnabledMethod(enabledMethods.length < 2);
          }
          setPaymentMethod(paymentMethod);
        } else {
          const paymentMethod = await api.Config.PaymentMethods.get(type);
          setPaymentMethod(paymentMethod);
        }
        if (type !== PAYMENT_METHOD.BankTransfer) {
          const providerConfig = await api.Config.PaymentProviderConfigurations.get(
            type === PAYMENT_METHOD.CreditCard
              ? PAYMENT_PROVIDER.Payrexx
              : PAYMENT_PROVIDER.Coinify
          );
          setProviderConfig(providerConfig);
        }
        setLoading(false);
      } catch (error) {
        handleError({ error, history });
      }
    };
    if (paymentMethodType) {
      getPaymentMethod(paymentMethodType)
    } else {
      history.replace(ROUTES.admin.config.purchase);
    }
  }, [isPurchaseEnabled]);

  const openPurchaseConfig = () =>
    history.replace(ROUTES.admin.config.purchase);

  const save = async (values, form) => {
    setLoading(true);
    const {
      AdditionalBankIdentifiers,
      coinify,
      payrexx,
      ...methodParams
    } = values;
    const { dirtyFields } = form.getState();
    try {
      await api.Config.PaymentMethods.patch(
        paymentMethod.Type,
        {
          ...paymentMethod?.Type === PAYMENT_METHOD.BankTransfer && {
            AdditionalBankIdentifiers: {
              Identifiers: [],
              ...AdditionalBankIdentifiers
            }
          },
          ...methodParams,
          "@odata.type": paymentMethod["@odata.type"]
        }
      );
      if (dirtyFields["payrexx.ApiUrl"] || dirtyFields["payrexx.ApiSecret"]) {
        await api.Config.PaymentProviderConfigurations.patch(
          PAYMENT_PROVIDER.Payrexx,
          {
            ...payrexx,
            "@odata.type": providerConfig["@odata.type"]
          }
        );
      }
      if (dirtyFields["coinify.ApiKey"] || dirtyFields["coinify.ApiUrl"]) {
        await api.Config.PaymentProviderConfigurations.patch(
          PAYMENT_PROVIDER.Coinify,
          {
            ...coinify,
            "@odata.type": providerConfig["@odata.type"]
          }
        );
      }
      form.restart(values);
    } catch (error) {
      handleError({ error, history });
    }
    setLoading(false);
  };

  const saveAndEnable = async (values, form) => {
    await save({
      ...values,
      IsEnabled: true
    }, form);
    history.push(ROUTES.admin.config.purchase);
  };

  const disable = async () => {
    setLoading(true);
    await api.Config.PaymentMethods.patch(
      paymentMethod.Type,
      {
        IsEnabled: false,
        "@odata.type": paymentMethod["@odata.type"]
      }
    );
    history.push(ROUTES.admin.config.purchase);
  }

  const methodNameTranslation = paymentMethod
    ? i18nextTranslate(
      i18nextKeys[`configPayment${paymentMethod?.Type}Title`]
    )
    : "";
  const fieldWidth = xxl ? "500px" : "404px";
  const textAreaHeight = xxl ? "119px" : "84px";
  const contentWidth = xxl ? "700px" : "600px";

  const insertAtMutator = ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, array => {
      const copy = [...(array || [])];
      copy.splice(index, 0, value);
      return copy;
    })
  };

  const defaultTranslation = {
    Description: null
  };

  return (
    <FinalForm
      onSubmit={save}
      mutators={{
        ...arrayMutators,
        insertAt: insertAtMutator
      }}
      validateOnBlur={paymentMethod?.Type !== PAYMENT_METHOD.BankTransfer}
      render={({
        handleSubmit,
        invalid,
        pristine,
        validating,
        form,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <div className="relative">
            {!paymentMethod ? (
              <div className="flex justify-center px-32 py-24">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="px-32 py-24">
                  <div className="flex flex-col gap-32">
                    <div className="flex gap-16 items-center">
                      <Arrow
                        size={xxl ? "24" : "20"}
                        direction={DIRECTION.left}
                        className="color-6 cursor-pointer"
                        onClick={openPurchaseConfig}
                      />
                      <Text
                        textStyle="h2"
                        dataQa="payment-method-title"
                        uppercase
                      >
                        {i18nextTranslate(i18nextKeys.configPaymentMethod)} - {methodNameTranslation}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-32">
                      <div
                        className="flex flex-col gap-16"
                        style={{ width: contentWidth }}
                      >
                        <SubsectionHeading
                          text={i18nextTranslateDynamically(
                            i18nextKeys.configPaymentDescriptionTitle,
                            { name: methodNameTranslation }
                          )}
                          tooltipText={i18nextTranslate(
                            i18nextKeys.configPaymentTranslationDescriptionTooltip
                          )}
                          className=""
                          dataQa="description"
                        />
                        <TranslationsForm
                          translations={paymentMethod.Translations}
                          defaultValue={defaultTranslation}
                          fieldWidth={fieldWidth}
                          fields={(props) => (
                            <Description
                              fieldName="Description"
                              fieldWidth={fieldWidth}
                              textAreaHeight={textAreaHeight}
                              placeholderKey={`configPayment${paymentMethod.Type}TranslationDescription`}
                              required
                              {...props}
                            />
                          )}
                        />
                      </div>
                      <HorizontalRule />
                      <div
                        className="flex flex-col gap-16"
                        style={{ width: fieldWidth }}
                      >
                        <SubsectionHeading
                          text={i18nextTranslate(
                            i18nextKeys.configPaymentLimitTitle
                          )}
                          tooltipText={i18nextTranslate(
                            i18nextKeys.configPaymentLimitTooltip
                          )}
                          className=""
                          dataQa="limit"
                        />
                        <FinalField
                          name="MaxAmount"
                          initialValue={paymentMethod.MaxAmount}
                          render={({ input, meta }) => (
                            <NumberInput
                              scale={2}
                              labelText={i18nextTranslate(
                                i18nextKeys.configPaymentLimitLabel
                              )}
                              innerLabelText={Currency.Code}
                              dataQa="limit"
                              showInnerLabel
                              staticInnerLabel
                              fullWidth
                              decimal
                              {...input}
                              {...meta}
                            />
                          )}
                        />
                      </div>
                      <HorizontalRule />
                      {paymentMethod.Type === PAYMENT_METHOD.BankTransfer && (
                        <>
                          <BankAccount
                            account={paymentMethod.BeneficiaryDetails}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                          <AdditionalIdentifiers
                            identifiers={paymentMethod.AdditionalBankIdentifiers}
                            contentWidth={contentWidth}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                          <IntermediaryBankAccount
                            account={paymentMethod.IntermediaryBank}
                            contentWidth={contentWidth}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                        </>
                      )}
                      {paymentMethod.Type === PAYMENT_METHOD.CreditCard && (
                        <BankCard
                          providerConfig={providerConfig}
                          fieldWidth={fieldWidth}
                        />
                      )}
                      {paymentMethod.Type === PAYMENT_METHOD.CryptoCurrency && (
                        <CryptoCurrency
                          providerConfig={providerConfig}
                          fieldWidth={fieldWidth}
                        />
                      )}
                      <HorizontalRule />
                      <KYCTier
                        currentTier={paymentMethod.KYCTier}
                        contentWidth={contentWidth}
                        fieldWidth={fieldWidth}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                {paymentMethod.IsEnabled ? (
                  <Footer
                    title={methodNameTranslation}
                    description={i18nextTranslate(
                      i18nextKeys.configFooterMethodEnabledDescription
                    )}
                    tooltipText={i18nextTranslate(
                      i18nextKeys[`configPayment${paymentMethod.Type}Description`]
                    )}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configUpdateSettings)}
                    primaryButtonWidth={{
                      xxl: "210px",
                      default: "200px"
                    }}
                    onPrimaryButtonClick={handleSubmit}
                    primaryButtonDisabled={loading || invalid || pristine || validating}
                    primaryButtonLoading={loading}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configDisableMethod)}
                    secondaryButtonTooltipText={
                      isSingleEnabledMethod
                        ? i18nextTranslate(i18nextKeys.configPaymentTooltip)
                        : null
                    }
                    onSecondaryButtonClick={disable}
                    secondaryButtonDisabled={loading || isSingleEnabledMethod}
                    dataQa="method"
                  />
                ) : (
                  <Footer
                    title={methodNameTranslation}
                    description={i18nextTranslate(
                      i18nextKeys.configPaymentFooterDescription
                    )}
                    tooltipText={i18nextTranslate(
                      i18nextKeys[`configPayment${paymentMethod.Type}Description`]
                    )}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configEnableMethod)}
                    primaryButtonWidth={{
                      xxl: "383px",
                      default: "281px"
                    }}
                    onPrimaryButtonClick={() => saveAndEnable(values, form)}
                    primaryButtonDisabled={loading || paymentMethod?.IsEnabled || invalid || validating}
                    primaryButtonLoading={loading}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configSaveSettings)}
                    onSecondaryButtonClick={handleSubmit}
                    secondaryButtonDisabled={loading || invalid || pristine || validating}
                    dataQa="method"
                  />
                )}
              </>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default PaymentMethod;
