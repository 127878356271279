import React, { useContext, useEffect, useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import handleError from 'Helpers/handleError';
import { EnvContext } from 'States/env/envState';
import { ConfigContext } from 'States/config/configState';
import Fade from 'Components/shared/Fade';
import { DefaultCard } from 'Components/shared/cards';
import InfoModal from 'Components/shared/Modal/InfoModal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import {
  BankTransferSymbol,
  BitcoinSymbol,
  EthereumSymbol,
  ForkliftSymbol,
  Erc20Symbol,
} from 'Components/shared/symbols';
import {
  BANK_TRANSFER,
  BITCOIN,
  ETH,
  PHYSICAL,
  ERC20,
} from 'Enums/RedemptionMethodTypes';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import RedeemMethodSelectionButtonGroup from './RedeemMethodSelectionButtonGroup';
import { UiContext } from 'States/ui/uiState';
import { ROUTES } from 'Router/Routes';

const getSymbolFromType = (type, size = "50px") => {
  switch (type) {
    case BANK_TRANSFER: {
      return <BankTransferSymbol size={size} />;
    }
    case BITCOIN: {
      return (
        <BitcoinSymbol
          width={size}
          height={size}
        />
      );
    }
    case ETH: {
      return (
        <EthereumSymbol
          width={size}
          height={size}
        />
      );
    }
    case PHYSICAL: {
      return (
        <ForkliftSymbol
          width={size}
          height={size}
        />
      );
    }
    case ERC20: {
      return (
        <Erc20Symbol
          width={size}
          height={size}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const RedeemMethodSelection = () => {
  const { env } = useContext(EnvContext);
  const {
    breakpoints: { md },
  } = useContext(UiContext);
  const {
    config: {
      redeemSettings: {
        redemptionMethods: redemptionMethodsConfig,
        methodSelectionDescription
      }
    }
  } = useContext(ConfigContext);
  const history = useHistory();
  const [currentMethod, setCurrentMethod] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [redemptionMethods, setRedemptionMethods] = useState(null);
  const [loading, setLoading] = useState(true);
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const processMethods = async () => {
      try {
        let updatedMethods = [];
        const { value: assetsData } = await api.Assets.getAssets();
        for (const methodConfig of redemptionMethodsConfig) {
          if (!methodConfig.enabled) {
            continue;
          }
          const {
            Name,
            UnitOfMeasureCode,
            CurrencyCode
          } = assetsData.find(
            ({ Id }) => methodConfig.userPays.uniqueAssetId === Id
          );
          if (Name) {
            updatedMethods.push({
              ...methodConfig,
              userPays: {
                ...methodConfig.userPays,
                Name,
                UnitOfMeasureCode,
                CurrencyCode
              }
            });
          }
        }
        updatedMethods.sort((a, b) => a.userPays?.Name.localeCompare(b.userPays.Name));
        setRedemptionMethods(updatedMethods);
        setLoading(false);
      } catch (error) {
        handleError({ error, history });
      }
    }
    if (redemptionMethodsConfig && redemptionMethodsConfig.length) {
      processMethods();
    }
  }, [redemptionMethodsConfig]);

  const goToForm = (redemptionMethod) => {
    const { id } = redemptionMethod;
    history.push(generatePath(
      ROUTES.redemptionForm, { id }
    ));
  };

  const openModal = (redemptionMethod) => {
    const {
      id,
      type,
      icon: iconPath,
      name: title,
      description
    } = redemptionMethod;
    const icon = iconPath
      ? `${env.BlobUrl}/${iconPath}`
      : getSymbolFromType(type, '60%');
    setCurrentMethod({ id, type, icon, title, description });
    setShowInfoModal(true);
    logEvent("Redemption details checked", {
      "redemption method type": type
    });
  };

  return (
    <LogOnMount eventType="Redemption opened">
      {loading ? (
        <div className="flex justify-center">
          <LoadingSpinner dataQa="loading-page" />
        </div>
      ) : (
        <>
          <Text
            className="mb-16 md:mb-24"
            dataQa="redemption-description"
          >
            {methodSelectionDescription}
          </Text>
          <Fade show={true}>
            <div
              data-qa="redeem-method"
              className="grid row-gap-32 col-gap-40 justify-center"
              style={{
                gridTemplateColumns: md
                  ? 'repeat(auto-fit, 200px)'
                  : 'repeat(auto-fit, 100%)',
                gridAutoRows: '1fr',
              }}
            >
              {redemptionMethods.map(
                (redemptionMethod) =>
                  <DefaultCard
                    key={redemptionMethod.id}
                    dataQa={`redeem-method-${redemptionMethod.id}`}
                    name={redemptionMethod.name}
                    description={i18nextTranslateDynamically(
                      i18nextKeys.redeemMethodSelectionPayWith,
                      { userPaysName: redemptionMethod.userPays.Name }
                    )}
                    iconUrl={
                      redemptionMethod.icon && `${env.BlobUrl}/${redemptionMethod.icon}`
                    }
                    svg={
                      !redemptionMethod.icon &&
                      getSymbolFromType(
                        redemptionMethod.type,
                        md ? '100px' : '50px'
                      )
                    }
                    buttonGroup={() =>
                      RedeemMethodSelectionButtonGroup({
                        redemptionMethod,
                        openModal,
                        goToForm
                      })
                    }
                  />
              )}
              <InfoModal
                show={showInfoModal}
                close={() => setShowInfoModal(false)}
                content={currentMethod}
                actionButton={{
                  onClick: () => goToForm(currentMethod)
                }}
                dataQa="redemption-method-infoModal"
              />
            </div>
          </Fade>
        </>
      )}
    </LogOnMount>
  );
};

export default RedeemMethodSelection;
