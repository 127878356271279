import React from 'react';

const LinkIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    className="cursor-pointer"
  >
    <path d="M9.66665 13H2.99999C2.46573 13 1.9635 12.7919 1.58577 12.4142C1.20803 12.0364 1 11.5342 1 11V4.33333C1 3.7991 1.20806 3.29687 1.58579 2.91913C1.96352 2.5414 2.46576 2.33334 3.00001 2.33334H5.66667C6.03488 2.33334 6.33334 2.6318 6.33334 3.00001C6.33334 3.36822 6.03488 3.66668 5.66667 3.66668H2.99999C2.8219 3.66668 2.65451 3.73603 2.52861 3.86193C2.40272 3.98783 2.33334 4.15525 2.33334 4.33333V11C2.33334 11.1781 2.40269 11.3455 2.52861 11.4714C2.65451 11.5973 2.8219 11.6667 2.99999 11.6667H9.66662C9.84471 11.6667 10.0121 11.5973 10.138 11.4714C10.2639 11.3455 10.3333 11.1781 10.3333 11V8.33333C10.3333 7.96512 10.6318 7.66668 11 7.66668C11.3682 7.66668 11.6667 7.96514 11.6667 8.33333V11C11.6667 11.5342 11.4586 12.0364 11.0808 12.4142C10.7031 12.7919 10.2008 13 9.66665 13ZM5.66667 8.99998C5.49604 8.99998 5.32543 8.93491 5.19527 8.80473C4.93493 8.54439 4.93493 8.12225 5.19527 7.86191L10.7238 2.33334H8.33333C7.96512 2.33334 7.66668 2.03488 7.66668 1.66667C7.66668 1.29846 7.96512 1 8.33333 1H12.3333C12.5074 0.9998 12.6746 1.0679 12.799 1.18966L12.7991 1.18971L12.8004 1.19101L12.8008 1.19138L12.8018 1.19241L12.8025 1.19308L12.8032 1.19383L12.8047 1.19527L12.8061 1.19672L12.8068 1.19746L12.8075 1.19814C12.808 1.19858 12.8085 1.19905 12.8089 1.19956L12.8102 1.20088L12.8102 1.20093C12.932 1.32535 13.0001 1.49257 12.9999 1.66665V5.66665C12.9999 6.03486 12.7014 6.33332 12.3332 6.33332C11.965 6.33332 11.6666 6.03486 11.6666 5.66665V3.27616L6.13795 8.80475C6.00789 8.93493 5.83728 8.99998 5.66667 8.99998Z" />
  </svg>
);

export default LinkIcon;
