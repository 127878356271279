import React, { useReducer, createContext } from 'react';
import authReducer from './authReducer';
import { SET_AUTHENTICATED, SET_LOADING, SET_ADMIN } from './authTypes';

export const AuthContext = createContext();

const AuthState = (props) => {
  const initialState = {
    isAuthenticated: false,
    isAdmin: false,
    isAdminSet: false,
    loading: true,
    error: null,
    user: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const setAuthenticated = (isAuthenticated, user = null) => {
    dispatch({ type: SET_AUTHENTICATED, payload: { isAuthenticated, user } });
  };

  const setAdmin = (isAdmin) => {
    dispatch({ type: SET_ADMIN, payload: isAdmin });
  };

  const setLoading = (bool) => {
    dispatch({ type: SET_LOADING, payload: bool });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        isAdmin: state.isAdmin,
        isAdminSet: state.isAdminSet,
        user: state.user,
        loading: state.loading,
        error: state.error,
        setLoading,
        setAuthenticated,
        setAdmin,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
