import React from 'react';

function EthereumSymbol({ active, className, ...props }) {
  return (
    <svg
      width={182}
      height={292}
      viewBox="0 0 182 292"
      fill="none"
      className={`${className} fill-current ${
        active ? 'color-8' : 'color-4'
      }`}
      {...props}
    >
      <path d="M90.334 218.626L.7 165.728l89.58 126.281 89.681-126.281-89.68 52.898h.054zM91.666 0L2.068 148.71l89.598 52.989 89.635-52.934L91.666 0z" />
    </svg>
  );
}

export default EthereumSymbol;
