import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import api from 'Api';
import handleError from "Helpers/handleError";
import { DropdownField } from 'Components/shared/formElements';

const CurrencyInput = ({
  input: { value, onChange },
  menuPlacement = "auto",
  required = false,
  style={},
  dataQa,
  ...props
}) => {
  const history = useHistory();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const { value: currencies } = await api.Config.Currencies.get();
        currencies.sort((a, b) => a.Code.localeCompare(b.Code));
        setCurrencies(currencies);
      } catch (error) {
        handleError({ error, history })
      }
    };
    getCurrencies();
  }, []);

  return (
    <DropdownField
      dataQa={dataQa}
      style={style}
      valueKey="Code"
      labelKey="Code"
      input={{
        value: {
          Code: value
        },
        onChange
      }}
      options={currencies}
      menuPlacement={menuPlacement}
      required={required}
      showClearSelection={!required}
      isSearchable
      {...props}
    />
  );
};

export default CurrencyInput;
