import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import { ROUTES } from 'Router/Routes';
import Fade from 'Components/shared/Fade';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { DefaultCard } from 'Components/shared/cards';
import InfoModal from 'Components/shared/Modal/InfoModal';
import { useFormatNumber } from 'Utils/formatNumber';
import { isValidUrl, objectArrayToObject } from 'Utils/utils';
import { getCollectionMethodIcon } from 'Helpers/icons';
import { getTranslation } from 'Helpers/translations';
import handleError from 'Helpers/handleError';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import WalletConfirmationModal from './WalletConfirmationModal';
import OneTimeWalletModal from './OneTimeWalletModal';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { ETH_OPTION } from 'Enums/WalletType';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import useConfigSettings from 'Hooks/useConfigSettings';
import useCollectionMethods from 'Hooks/useCollectionMethods';
import CollectionMethodCardButtonGroup from './CollectionMethodCardButtonGroup';

const COLLECTION_METHOD_DETAILS = {
  title: '',
  description: '',
  disclaimer: '',
  type: ''
};

const CollectionMethodSelection = () => {
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md },
  } = useContext(UiContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [signableWallets, setSignableWallets] = useState([]);
  const [wlmWallets, setWlmWallets] = useState([]);
  const [wlpWallets, setWlpWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showOneTimeWalletModal, setShowOneTimeWalletModal] = useState(false);
  const [
    currentMethodDetails,
    setCurrentMethodDetails
  ] = useState(COLLECTION_METHOD_DETAILS);
  const formatNumber = useFormatNumber();
  const { logEvent } = useAmplitude();

  const {
    isLoading: loadingSettings,
    data: {
      currencyCode,
      defaultLanguage,
      appName
    }
  } = useConfigSettings.query({
    refetchOnWindowFocus: false,
    select: useCallback(
      ({ Currency, Languages, MobileApplicationName }) => {
        const currencyCode = Currency.Code;
        const defaultLanguage = Languages.find(
          ({ IsDefault }) => IsDefault
        );
        return {
          currencyCode,
          defaultLanguage,
          appName: MobileApplicationName
        }
      },
      []
    ),
    onError: (error) => {
      handleError({ error, history });
    }
  });

  const {
    isLoading: loadingCollectionMethods,
    data: collectionMethods
  } = useCollectionMethods.methodsQuery({
    refetchOnWindowFocus: false,
    select: useCallback(
      ({ value: collectionMethods }) => objectArrayToObject(collectionMethods, "Type"),
      []
    ),
    onError: (error) => {
      handleError({ error, history });
    }
  });

  useEffect(() => {
    const getWallets = async (signableWalletsEnabled, nonSignableWalletsEnabled) => {
      try {
        if (signableWalletsEnabled) {
          const { value: signableWallets } = await api.WalletAddresses.getTeosWallets({
            filter: "Signable"
          });
          setSignableWallets(signableWallets);
        }
        if (nonSignableWalletsEnabled) {
          const { value: wlpWallets } = await api.WalletAddresses.getFromWlpBe({
            filter: `IsActive and Type eq '${ETH_OPTION}'`,
          });
          setWlpWallets(wlpWallets);

          if (collectionMethods.OwnNonSignableWallet.UseWlmWallets) {
            const { value: wlmWallets } = await api.WalletAddresses.getTeosWallets({
              filter: "Signable eq false"
            });
            setWlmWallets(wlmWallets);
          }
        }
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorCartcollectionMethodRetrievingAddressFailure
        );
        handleError({ error, history, message });
      }
      setLoading(false);
    };

    if (loadingCollectionMethods) {
      return;
    }
    const signableWalletsEnabled = collectionMethods?.OwnWallet?.IsEnabled;
    const nonSignableWalletsEnabled = collectionMethods?.OwnNonSignableWallet?.IsEnabled;
    if (signableWalletsEnabled || nonSignableWalletsEnabled) {
      getWallets(signableWalletsEnabled, nonSignableWalletsEnabled);
    } else {
      setLoading(false);
    }
  }, [collectionMethods]);

  const onSelectCollectionMethod = (
    collectionMethodType,
    { ethereumAddress } = {}
  ) => {
    logEvent("Collection method selected", {
      type: collectionMethodType,
    });
    history.push({
      pathname: ROUTES.checkoutCustomerDetails,
      state: {
        collectionMethodType,
        collectionMethodAddress: ethereumAddress
      }
    });
  };

  const selectWallet = (wallet) => {
    setSelectedWallet(wallet);
    setShowInfoModal(false);
    setShowQrModal(true);
  };

  const confirmWallet = (wallet) => {
    logEvent(`${
      selectedWallet.type === COLLECT_METHOD.OwnWallet
        ? 'Signable'
        : 'Non-signable'
    } wal confirmed`);
    onSelectCollectionMethod(wallet.type, wallet);
  };

  const closeWalletConfirmation = () => {
    setShowQrModal(false);
    logEvent(`${
      selectedWallet.type === COLLECT_METHOD.OwnWallet
        ? 'Signable'
        : 'Non-signable'
    } wal not confirmed`);
  };

  const showMoreDetails = (
    collectionMethodType,
    selectCollectionMethod,
    collectionMethod
  ) => {
    logEvent("Collection method details checked", {
      type: collectionMethodType,
    });
    const description = getTranslation(
      "Description",
      lang,
      defaultLanguage.Code,
      collectionMethods[collectionMethodType].Translations
    );

    if (isValidUrl(description)) {
      window.open(description, '_blank', 'noopener', 'noreferrer');
    } else {
      const icon = getCollectionMethodIcon({
        size: md ? '100px' : '50px',
        collectionMethod: collectionMethodType
      })

      setCurrentMethodDetails({
        title: translations[collectionMethodType].title,
        ...collectionMethod?.address && {
          subtitle: collectionMethod.address
        },
        ...collectionMethod?.name && {
          subtitle: collectionMethod.name
        },
        description,
        type: collectionMethodType,
        selectCollectionMethod,
        icon
      });
      setShowInfoModal(true);
    }
  };

  const openOneTimeWalletModal = () => {
    const description = getTranslation(
      "Description",
      lang,
      defaultLanguage.Code,
      collectionMethods[COLLECT_METHOD.OneTimeWallet].Translations
    );
    const disclaimer = getTranslation(
      "Disclaimer",
      lang,
      defaultLanguage.Code,
      collectionMethods[COLLECT_METHOD.OneTimeWallet].Translations
    );
    setCurrentMethodDetails({
      description,
      disclaimer
    });
    setShowInfoModal(false);
    setShowOneTimeWalletModal(true);
  };

  const translations = {
    [COLLECT_METHOD.TokenCard]: {
      title: i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName),
      description: i18nextTranslate(i18nextKeys.collectionTokenCardDescription)
    },
    [COLLECT_METHOD.OwnWallet]: {
      title: i18nextTranslateDynamically(
        i18nextKeys.collectionSignableWalletTitle,
        { appName }
      ),
      description: i18nextTranslate(i18nextKeys.collectionSignableWalletDescription)
    },
    [COLLECT_METHOD.OwnNonSignableWallet]: {
      title: i18nextTranslate(i18nextKeys.collectionNonSignableWalletTitle),
      description: i18nextTranslate(i18nextKeys.collectionNonSignableWalletDescription)
    },
    [COLLECT_METHOD.OneTimeWallet]: {
      title: i18nextTranslate(i18nextKeys.collectionOneTimeWalletTitle),
      description: i18nextTranslate(i18nextKeys.collectionOneTimeWalletDescription)
    }
  };

  const getDescription = (collectionMethodType) => {
    const methodDescription = translations[collectionMethodType].description;
    const { FixedFee, VariableFee } = collectionMethods[collectionMethodType].MethodFees;
    let fixedFeeText = "";
    let variableFeeText = "";
    if (FixedFee || !VariableFee) {
      fixedFeeText = `${formatNumber(FixedFee)} ${currencyCode}`;
    }
    if (VariableFee) {
      variableFeeText = `${i18nextTranslateDynamically(
        i18nextKeys.commonFeePercentage,
        { percentage: VariableFee }
      )}${fixedFeeText ? " + " : ""}`;
    }
    return `${methodDescription}, ${i18nextTranslateDynamically(
      i18nextKeys.collectionFee,
      { fee: variableFeeText + fixedFeeText }
    )}`;
  };

  return (
    <LogOnMount eventType="Checkout started">
      {loading || loadingSettings ? (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Fade show={true}>
          <div className='flex justify-center mb-20 md:mb-24'>
            <span className='text-sm font-bold leading-tight color-8'>
              {i18nextTranslate(i18nextKeys.checkoutCollectionMethodSelect)}
            </span>
          </div>
          <div
            data-qa="checkout-collectionMethod"
            className="grid row-gap-32 col-gap-40 justify-center"
            style={{
              gridTemplateColumns: md
                ? 'repeat(auto-fit, 200px)'
                : 'repeat(auto-fit, 100%)',
              gridAutoRows: '1fr',
            }}
          >
            {collectionMethods[COLLECT_METHOD.TokenCard].IsEnabled && (
              <DefaultCard
                dataQa="tokenCard"
                name={translations[COLLECT_METHOD.TokenCard].title}
                description={getDescription(COLLECT_METHOD.TokenCard)}
                svg={getCollectionMethodIcon({
                  size: md ? '100px' : '50px',
                  collectionMethod: COLLECT_METHOD.TokenCard
                })}
                buttonGroup={() =>
                  CollectionMethodCardButtonGroup({
                    collectionMethodType: COLLECT_METHOD.TokenCard,
                    openInfoModal: showMoreDetails,
                    selectCollectionMethod: onSelectCollectionMethod,
                    dataQa: "tokenCard"
                  })
                }
              />
            )}

            {collectionMethods[COLLECT_METHOD.OwnWallet].IsEnabled &&
              signableWallets.map(({ EthereumAddress }, index) => (
                <DefaultCard
                  dataQa={`signableWallet-${index}`}
                  key={EthereumAddress}
                  name={translations[COLLECT_METHOD.OwnWallet].title}
                  subtitle={EthereumAddress}
                  description={getDescription(COLLECT_METHOD.OwnWallet)}
                  svg={getCollectionMethodIcon({ size: md ? '100px' : '50px' })}
                  buttonGroup={() =>
                    CollectionMethodCardButtonGroup({
                      collectionMethodType: COLLECT_METHOD.OwnWallet,
                      collectionMethod: { address: EthereumAddress },
                      openInfoModal: showMoreDetails,
                      selectCollectionMethod: () => selectWallet({
                        ethereumAddress: EthereumAddress,
                        type: COLLECT_METHOD.OwnWallet
                      }),
                      dataQa: `signableWallet-${index}`
                    })
                  }
                />
              ))
            }

            {collectionMethods[COLLECT_METHOD.OwnNonSignableWallet].IsEnabled &&
              wlpWallets.map(({ Address: ethereumAddress, Name }, index) => (
                <DefaultCard
                  dataQa={`wlpWallet-${index}`}
                  key={ethereumAddress}
                  name={translations[COLLECT_METHOD.OwnNonSignableWallet].title}
                  subtitle={Name}
                  description={getDescription(COLLECT_METHOD.OwnNonSignableWallet)}
                  svg={getCollectionMethodIcon({ size: md ? '100px' : '50px' })}
                  buttonGroup={() =>
                    CollectionMethodCardButtonGroup({
                      collectionMethodType: COLLECT_METHOD.OwnNonSignableWallet,
                      openInfoModal: showMoreDetails,
                      selectCollectionMethod: () => selectWallet({
                        ethereumAddress,
                        name: Name,
                        type: COLLECT_METHOD.OwnNonSignableWallet
                      }),
                      dataQa: `wlpWallet-${index}`
                    })
                  }
                />
              ))
            }

            {collectionMethods[COLLECT_METHOD.OneTimeWallet].IsEnabled && (
              <DefaultCard
                dataQa="oneTimeWallet"
                name={translations[COLLECT_METHOD.OneTimeWallet].title}
                description={getDescription(COLLECT_METHOD.OneTimeWallet)}
                svg={getCollectionMethodIcon({ size: md ? '100px' : '50px' })}
                buttonGroup={() =>
                  CollectionMethodCardButtonGroup({
                    collectionMethodType: COLLECT_METHOD.OneTimeWallet,
                    openInfoModal: showMoreDetails,
                    selectCollectionMethod: openOneTimeWalletModal,
                    dataQa: "oneTimeWallet"
                  })
                }
              />
            )}

            {collectionMethods[COLLECT_METHOD.OwnNonSignableWallet].IsEnabled &&
              collectionMethods[COLLECT_METHOD.OwnNonSignableWallet].UseWlmWallets &&
              wlmWallets.map(({ EthereumAddress, Name }, index) => (
                <DefaultCard
                  dataQa={`wlmWallet-${index}`}
                  key={EthereumAddress}
                  name={translations[COLLECT_METHOD.OwnNonSignableWallet].title}
                  subtitle={Name}
                  description={getDescription(COLLECT_METHOD.OwnNonSignableWallet)}
                  svg={getCollectionMethodIcon({ size: md ? '100px' : '50px' })}
                  buttonGroup={() =>
                    CollectionMethodCardButtonGroup({
                      collectionMethodType: COLLECT_METHOD.OwnNonSignableWallet,
                      openInfoModal: showMoreDetails,
                      selectCollectionMethod: () => selectWallet({
                        ethereumAddress: EthereumAddress,
                        name: Name,
                        type: COLLECT_METHOD.OwnNonSignableWallet
                      }),
                      dataQa: `wlmWallet-${index}`
                    })
                  }
                />
              ))
            }
          </div>
        </Fade>
      )}
      <WalletConfirmationModal
        showQrModal={showQrModal}
        close={closeWalletConfirmation}
        selectedWallet={selectedWallet}
        onSelectCollectionMethod={confirmWallet}
      />
      <InfoModal
        show={showInfoModal}
        close={() => setShowInfoModal(false)}
        content={currentMethodDetails}
        actionButton={{
          onClick: currentMethodDetails.selectCollectionMethod
        }}
        dataQa="checkout-collectionMethod-infoModal"
      />
      <OneTimeWalletModal
        show={showOneTimeWalletModal}
        close={() => setShowOneTimeWalletModal(false)}
        onSelectCollectionMethod={onSelectCollectionMethod}
        content={currentMethodDetails}
      />
    </LogOnMount>
  );
};

export default CollectionMethodSelection;
