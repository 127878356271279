import React, { useContext, useEffect, useState } from 'react';
import {
  generatePath,
  Link,
  matchPath,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import SidebarLink from './SidebarLink';
import Urls from 'Utils/urls';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import handleError from 'Helpers/handleError';
import DebugInfoModal from 'Components/DebugInfoModal/DebugInfoModal';
import { Arrow, LogoutSymbol } from 'Components/shared/symbols';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { UiContext } from 'States/ui/uiState';
import { KycContext } from 'States/kyc/kycState';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import LanguageSwitcher from 'Components/Sidebar/LanguageSwitcher';
import {
  STORAGE_KEYS,
  TENANT_FEATURE
} from 'Enums';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { ROUTES } from 'Router/Routes';
import Text from "../shared/Text";

const isActiveRoute = (pathName, route, exact = false) =>
  matchPath(pathName, { path: route, exact });

const Sidebar = ({
  features,
  logo,
  restrictedMode,
  allowedRedemption = null,
  featuredAsset = null
}) => {
  const [showDebugModal, setShowDebugModal] = useState(false);
  const [showKycReminder, setShowKycReminder] = useState(false);
  const {
    isAdmin,
    isAuthenticated,
    user
  } = useContext(AuthContext);
  const {
    smallScreenSidebarShowing,
    setSmallScreenSidebarShowing,
  } = useContext(UiContext);
  const {
    config: {
      kycTiersDescription,
      kycTierFunctionalRestrictions,
      redeemSettings
    }
  } = useContext(ConfigContext);
  const {
    isKycEnabled,
    getUserKycData,
    isKycCaseLoading
  } = useContext(KycContext);
  const { pathname } = useLocation();
  let history = useHistory();
  const { logEvent } = useAmplitude();
  const { isUserTierSufficient: redeemVisibilityReached } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.redemption
  );
  const { isUserTierSufficient: purchaseVisibilityReached } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );

  const isFeaturedAssetEnabled = !!featuredAsset &&
    (!isAuthenticated || (isAdmin || purchaseVisibilityReached));

  useEffect(() => {
    const loadLatestKycCase = async () => {
      try {
        const response = await getUserKycData();
        if (!response?.LatestCase) {
          setShowKycReminder(true);
        }
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.kycLoadUserTierError);
        handleError({ error, history, message });
      }
    }
    if (isAuthenticated && !isAdmin
      && isKycEnabled && !isKycCaseLoading) {
      loadLatestKycCase();
    }
  }, [isKycEnabled, isAuthenticated, pathname]);

  const hideSidebar = () => setSmallScreenSidebarShowing(false);

  const userIsConfirmedByAdmin = user && user.isConfirmedByAdmin;

  const trackLogout = () => logEvent('Logout', {
    screen: location.pathname.split("/")[1] || "home"
  });

  const links = [
    {
      route: ROUTES.login,
      text: i18nextTranslate(i18nextKeys.sidebarLoginRegister),
      requiresAuth: false,
      enabled: true,
      dataQa: 'sidebar-login',
      active: false,
      state: {
        [STORAGE_KEYS.authReturnPath]: pathname
      },
      onClick: () => logEvent(
        "Auth login started",
        { origin: "menu" }
      )
    },
    {
      route: restrictedMode && featuredAsset
        ? generatePath(
            ROUTES.featuredAsset,
            { id: featuredAsset.uniqueAssetId }
          )
        : ROUTES.featuredAssetBase,
      text: featuredAsset?.customPurchaseSettings?.sidebarLink,
      requiresAuth: false,
      enabled: isFeaturedAssetEnabled,
      dataQa: 'sidebar-customPurchase',
      active: isActiveRoute(
        pathname,
        [
          ROUTES.base,
          ROUTES.featuredAssetBase,
          ROUTES.featuredAsset
        ],
        true
      )
    },
    {
      route: !isAdmin && restrictedMode && featuredAsset
        ? generatePath(
            ROUTES.featuredAsset,
            { id: featuredAsset.uniqueAssetId }
          )
        : ROUTES.featuredAssetBase,
      text: featuredAsset?.customPurchaseSettings?.sidebarLink,
      requiresAuth: true,
      enabled: isFeaturedAssetEnabled,
      dataQa: 'sidebar-customPurchase',
      active: isActiveRoute(
        pathname,
        [
          ROUTES.base,
          ROUTES.featuredAssetBase,
          ROUTES.featuredAsset
        ],
        true
      )
    },
    {
      route: ROUTES.shop,
      text: i18nextTranslate(i18nextKeys.sidebarShop),
      requiresAuth: true,
      enabled:
        (isAdmin || !restrictedMode) &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.purchase) &&
        purchaseVisibilityReached,
      dataQa: 'sidebar-purchase',
      active: isActiveRoute(pathname, ROUTES.shop),
    },
    {
      route: ROUTES.shop,
      text: i18nextTranslate(i18nextKeys.sidebarShop),
      requiresAuth: false,
      enabled:
        !restrictedMode &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.purchase),
      dataQa: 'sidebar-purchase-view-only',
      active: isActiveRoute(pathname, ROUTES.shop),
    },
    {
      route: !isAdmin && restrictedMode && allowedRedemption
        ? generatePath(
            ROUTES.redemptionForm,
            { id: allowedRedemption }
          )
        : ROUTES.redemption,
      text: redeemSettings?.menuItemName || i18nextTranslate(i18nextKeys.sidebarRedemption),
      requiresAuth: true,
      enabled:
        (isAdmin || !restrictedMode || allowedRedemption) &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) &&
        userIsConfirmedByAdmin &&
        redeemVisibilityReached,
      dataQa: 'sidebar-redeem',
      active: isActiveRoute(pathname, ROUTES.redemption),
    },
    {
      route: ROUTES.admin.kyc,
      text: i18nextTranslate(i18nextKeys.sidebarKycCases),
      requiresAuth: true,
      adminOnly: true,
      enabled: isTenantFeatureAvailable(features, TENANT_FEATURE.kyc),
      dataQa: 'sidebar-admin-kyc',
      active: isActiveRoute(pathname, ROUTES.admin.kyc),
    },
    {
      route: ROUTES.admin.orders,
      text: i18nextTranslate(i18nextKeys.sidebarOrderManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-purchase',
      active: isActiveRoute(pathname, ROUTES.admin.orders),
    },
    {
      route: ROUTES.admin.redemptions,
      text: i18nextTranslate(i18nextKeys.sidebarRedemptionManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: isTenantFeatureAvailable(features, TENANT_FEATURE.redeem),
      dataQa: 'sidebar-admin-redeem',
      active: isActiveRoute(pathname, ROUTES.admin.redemptions),
    },
    {
      route: ROUTES.admin.config.tenant,
      text: i18nextTranslate(i18nextKeys.sidebarConfigManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-config',
      active: isActiveRoute(pathname, ROUTES.admin.config.tenant),
    },
    {
      route: ROUTES.admin.config.json,
      text: i18nextTranslate(i18nextKeys.sidebarJsonConfigManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-json-config',
      active: isActiveRoute(pathname, ROUTES.admin.config.json),
    },
  ];
  return (
    <div
      data-qa="sidebar"
      className={`flex-col ${smallScreenSidebarShowing ? 'flex' : 'hidden'}
        text-xl md:text-xs
        bg-1
        z-50 fixed lg:relative lg:flex lg:h-auto h-full`}
      style={{ minWidth: '282px', width: '282px' }}
    >
      <div
        className="w-full flex flex-col sticky overflow-auto"
        style={{ minHeight: 'calc(100vh - 32px)', top: 0 }}
      >
        <div className="flex justify-between items-center mt-32 ml-32 mr-24 mb-24">
          <img
            data-qa="sidebar-logo"
            style={{
              maxWidth: '150px',
              maxHeight: '60px'
            }}
            src={`${Urls.get()?.blob}/${logo}`}
            alt="Company Logo"
          />
          <LanguageSwitcher />
        </div>
        <nav className="self-end flex flex-col justify-end w-full">
          {isAuthenticated && (
            <div
              className={`flex-none flex items-center justify-between px-16 py-8 ml-16 color-7 leading-5 rounded-l-lg ${isActiveRoute(pathname, 'account')
                ? 'sidebarLink-active-highlight'
                : ''
                }`}
              data-qa="sidebar-account"
            >
              <Link
                to={userIsConfirmedByAdmin ? ROUTES.account : '#'}
                data-qa="sidebar-account-link"
                className={`w-5/6 overflow-hidden ${userIsConfirmedByAdmin ? '' : 'pointer-events-none'
                  }`}
              >
                <div className="flex flex-wrap">
                  <Text textStyle="p2" color="color-7">
                    {`${user.profile.given_name} ${user.profile.family_name}`}
                  </Text>
                </div>
                <div
                  data-qa="sidebar-account-email"
                >
                  <Text textStyle="p4" color="color-7">
                    {user.profile.email}
                  </Text>
                </div>
              </Link>
              <Link
                to={ROUTES.logout}
                data-qa="sidebar-logout"
              >
                <LogoutSymbol onClick={trackLogout} />
              </Link>
            </div>
          )}
          {showKycReminder && (
            <Link
              to={ROUTES.kyc}
              target="_blank"
              onClick={() => logEvent("KYC started", {
                origin: "reminder",
                provider: kycTiersDescription[1]?.providers[0].name !== KYC_PROVIDER.manual
              })}
              className="flex items-center gap-12 py-12 px-16 m-16 rounded-lg normal-case text-xs color-white bg-4--20"
              data-qa="sidebar-kycReminder"
            >
              <span className="w-5/6">
                {i18nextTranslate(i18nextKeys.sidebarkycReminder)}
              </span>
              <Arrow size="20" />
            </Link>
          )}
          <ul className="subpixel-antialiased color-7 uppercase font-semibold w-full pl-16">
            {links.map(
              ({
                route,
                text,
                requiresAuth,
                enabled,
                adminOnly,
                dataQa,
                active,
                state,
                onClick = () => { },
              }) => {
                // Route (or rather the link to it) is protected and requires a user to be logged in (authenticated)
                if (requiresAuth && enabled) {
                  if (
                    (isAuthenticated && !adminOnly) ||
                    (isAuthenticated && adminOnly && isAdmin)
                  ) {
                    return (
                      <SidebarLink
                        route={route}
                        text={text}
                        key={route + text}
                        dataQa={dataQa}
                        state={state}
                        onClick={() => {
                          onClick();
                          hideSidebar();
                        }}
                        active={active}
                      />
                    );
                  } else {
                    return null;
                  }
                }

                // Route is not protected and shall be shown to un-authenticated users only
                if (!isAuthenticated && enabled) {
                  return (
                    <SidebarLink
                      route={route}
                      text={text}
                      key={route + text}
                      dataQa={dataQa}
                      state={state}
                      onClick={() => {
                        onClick();
                        hideSidebar();
                      }}
                      active={active}
                    />
                  );
                }

                // none of the above apply
                return null;
              }
            )}
          </ul>
        </nav>
        <div className="h-full flex flex-col pr-16 pb-16">
          {user &&
            !user.isNewUser &&
            user.profile.email_verified &&
            !user.isConfirmedByAdmin && (
              <div
                className="color-white font-bold border-4 p-16 text-center mt-32 cursor-default"
                style={{ maxWidth: 'fit-content' }}
              >
                {i18nextTranslate(i18nextKeys.accountNotConfirmedByAdminLong)}
              </div>
            )}

          <Link
            className="mt-auto mb-16 color-7 text-center underline"
            to={ROUTES.imprint}
          >
            <p>{i18nextTranslate(i18nextKeys.sidebarImprint)}</p>
          </Link>
          {isAuthenticated && (
            <span
              data-qa="debugInfoModal-openButton"
              onClick={() => setShowDebugModal(true)}
              className="rounded-full h-32 w-32 bg-gray-200 hover:bg-gray-500 ease-in-out duration-300 cursor-pointer flex justify-center items-center text-xs self-end"
            >
              {'</>'}
            </span>
          )}
        </div>
      </div>
      {isAuthenticated && (
        <DebugInfoModal
          show={showDebugModal}
          close={() => setShowDebugModal(false)}
        />
      )}
    </div>
  );
};

export default Sidebar;
