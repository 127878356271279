import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Amplitude, LogOnMount } from 'react-amplitude-hooks';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { AuthContext } from 'States/auth/authState';
import { CartContext } from 'States/cart/cartState';
import { ConfigContext } from 'States/config/configState';
import AddToCart from './AddToCart';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import BurgerMenu from 'Components/shared/BurgerMenu';
import Urls from 'Utils/urls';
import api from 'Api';
import handleError from 'Helpers/errorNotification';
import { ROUTES } from 'Router/Routes';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { UiContext } from 'States/ui/uiState';
import CartButton from './CartButton';

const instance = axios.create();
instance.defaults.headers.common = {};

const FeaturedTokenPurchasePage = ({ featuredAsset, restrictedMode = false }) => {
  const history = useHistory();
  const { isAuthenticated, user } = useContext(AuthContext);
  const [html, setHtml] = useState(null);
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(null);
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);
  const {
    breakpoints: { md, lg, xxl },
  } = useContext(UiContext);
  const {
    loadCart,
    cartItems,
    loadingCart,
  } = useContext(CartContext);

  const requiredTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.purchase || 0,
    asset?.kycTier || 0
  );

  const { isUserTierSufficient } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );

  useEffect(() => {
    if (!featuredAsset && (!isAuthenticated || isUserTierSufficient)) {
      history.replace(
        restrictedMode
          ? ROUTES.welcome
          : ROUTES.shop
      );
    }
  }, [featuredAsset, isUserTierSufficient]);

  useEffect(() => {
    const loadCartAndFeaturedAsset = async () => {
      try {
        loadCart();
        const featuredAssetData = await api.Assets.getAsset(featuredAsset.uniqueAssetId);
        setAsset({ ...featuredAsset, ...featuredAssetData });
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.errorPurchaseAssetLoadingError);
        handleError({ error, history, message });
      }
    };

    const loadTemplate = async () => {
      const customHtmlPath =
        isAuthenticated && featuredAsset?.customPurchaseSettings?.customHtmlSourcePathLoggedIn
          ? featuredAsset.customPurchaseSettings.customHtmlSourcePathLoggedIn
          : featuredAsset.customPurchaseSettings.customHtmlSourcePath;

      const { data } = await instance.get(
        `${Urls.get()?.blob}/${customHtmlPath}`
      );
      setHtml(data);
      setLoading(false);
    };

    loadTemplate();
    if (isAuthenticated && user?.isConfirmedByAdmin) {
      loadCartAndFeaturedAsset();
    }
  }, [isAuthenticated, featuredAsset]);

  const showContent =
    html &&
    !loading &&
    (asset || !isAuthenticated || (user && !user.isConfirmedByAdmin));

  return showContent ? (
    <Amplitude eventProperties={{ scope: 'featured' }}>
      <LogOnMount eventType="Featured opened">
        <div className="revertPageContainer">
          <div
            className="fixed z-40 rounded-md bg-1 flex justify-center items-center lg:hidden"
            style={{
              height: '2.8rem',
              width: '2.8rem',
              top: '15px',
              left: '15px',
            }}
          >
            <BurgerMenu
              rounded="rounded-md"
              colorClass="bg-1"
              style={{ marginLeft: 0 }}
            />
          </div>

          {isAuthenticated && user && user.isConfirmedByAdmin && (
            <CartButton
              itemsCount={Object.keys(cartItems).length}
              isLoading={loadingCart}
              fixedPosition
            />
          )}

          {isAuthenticated ? (
            <div
              className={`fixed z-40 bg-3 rounded-md ${
                user?.isConfirmedByAdmin ? "px-16 pt-16 pb-12" : "p-16 md:px-28 md:py-16"
              }`}
              style={{
                width: xxl ? '514px' : md ? '405px' : '328px',
                bottom: lg ? '24px' : '16px',
                right: lg ? '24px' : '16px',
                ...!md && { maxWidth: 'calc(100% - 32px)' }
              }}
            >
              <AddToCart
                asset={asset || {}}
                requiredTier={requiredTier}
                buttonWidth={{ xxl: '180px', default: '140px' }}
              />
            </div>
          ) : null}
          <div
            style={{ minHeight: '100%' }}
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </LogOnMount>
    </Amplitude>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default FeaturedTokenPurchasePage;
