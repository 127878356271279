import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'Api';
import { objectArrayToObject } from 'Utils/utils';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useConfigSettings from 'Hooks/useConfigSettings';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import { ToggleSymbol } from 'Components/shared/symbols';
import TENANT_FEATURE from 'Enums/TenantFeature';
import handleError from 'Helpers/handleError';
import {
  getFeatureTitleKey,
  getFeatureDescriptionKey,
  getFeatureToggleTextConfirmationTitle,
  getFeatureToggleTextConfirmationKey
} from 'Helpers/translations';
import FeatureToggle from '../../shared/FeatureToggle';
import SubsectionHeading from '../../shared/SubsectionHeading';

const FeatureConfig = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feature, setFeature] = useState({});
  const [featuresAvailability, setFeaturesAvailability] = useState(null);
  const history = useHistory();
  const {
    breakpoints: { md },
  } = useContext(UiContext);

  const {
    data: features
  } = useFeatureAvailability.query({
    refetchOnMount: 'always',
    onError: (error) => handleError({ error, history })
  });

  const {
    isLoading: updatingFeatures,
    mutate: updateFeatures
  } = useFeatureAvailability.mutation({
    onError: (error) => handleError({ error, history }),
    onSettled: () => closeFeatureToggleModal()
  });

  const {
    data: settings
  } = useConfigSettings.query({
    refetchOnMount: 'always',
    onError: (error) => handleError({ error, history })
  });

  const {
    isLoading: updatingSettings,
    mutate: updateSettings
  } = useConfigSettings.mutation({
    onError: (error) => handleError({ error, history }),
    onSettled: () => closeFeatureToggleModal()
  });

  useEffect(() => {
    const getFeaturesAvailability = async () => {
      const { value: featuresAvailability } = await api.Config.Features.getAvailability();
      setFeaturesAvailability(
        objectArrayToObject(featuresAvailability, "Type")
      );
    };
    getFeaturesAvailability();
  }, [features])

  const toggleFeature = () => {
    setIsLoading(true);
    if (updatingFeatures) return;

    updateFeatures({
      type: feature.Type,
      data: { IsEnabled: !feature.IsEnabled }
    });
  };

  const toggleRestrictedMode = () => {
    setIsLoading(true);
    if (updatingSettings) return;

    updateSettings({
      data: {
        RestrictedMode: !feature.IsEnabled
      }
    });
  };

  const openFeatureToggleModal = (feature) => {
    setFeature(feature);
    setIsLoading(false);
    setShowConfirmationModal(true);
  }

  const closeFeatureToggleModal = () => {
    setShowConfirmationModal(false);
  };

  const isFeatureAvailable = (type) => {
    switch(type) {
      case TENANT_FEATURE.purchase:
        return featuresAvailability?.[TENANT_FEATURE.purchase].IsAvailable
      case TENANT_FEATURE.customFeaturedAsset:
        return featuresAvailability?.[TENANT_FEATURE.customFeaturedAsset].IsAvailable
      default:
        return true;
    }
  };

  return (
    <>
      <div className="flex flex-col gap-40 xxl:gap-48">
        <div className="flex flex-col gap-24 xxl:gap-32">
          <SubsectionHeading
            text={i18nextTranslate(i18nextKeys.tenantSettingsFeaturesMain)}
            className=""
            dataQa="features-main"
          />
          <div
            className="grid row-gap-24 col-gap-40 xl:col-gap-56 xxl:col-gap-96"
            style={{
              gridTemplateColumns: md ? '1fr 1fr' : '1fr'
            }}
          >
            {features?.map(({ Type, IsEnabled }) => {
              return (
                <FeatureToggle
                  key={Type}
                  title={i18nextTranslate(getFeatureTitleKey(Type))}
                  description={i18nextTranslate(getFeatureDescriptionKey(Type))}
                  tooltipText={
                    !isFeatureAvailable(Type)
                      ? i18nextTranslate(i18nextKeys.tenantSettingsFeaturesPurchaseTooltip)
                      : null
                  }
                  onChange={() => openFeatureToggleModal({ Type, IsEnabled })}
                  isEnabled={IsEnabled}
                  disabled={!isFeatureAvailable(Type)}
                  dataQa={Type}
                />
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-24 xxl:gap-32">
          <SubsectionHeading
            text={i18nextTranslate(i18nextKeys.tenantSettingsFeaturesAdditional)}
            className=""
            dataQa="features-additional"
          />
          <div
            className="grid row-gap-24 col-gap-40 xl:col-gap-56 xxl:col-gap-96"
            style={{
              gridTemplateColumns: md ? '1fr 1fr' : '1fr',
            }}
          >
            <FeatureToggle
              title={i18nextTranslate(
                i18nextKeys.tenantSettingsFeaturesRestrictedModeTitle
              )}
              description={i18nextTranslate(
                i18nextKeys.tenantSettingsFeaturesRestrictedModeDescription
              )}
              onChange={() => openFeatureToggleModal({
                Type: TENANT_FEATURE.restrictedMode,
                IsEnabled: settings.RestrictedMode
              })}
              isEnabled={settings.RestrictedMode}
              dataQa={TENANT_FEATURE.restrictedMode}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        close={closeFeatureToggleModal}
        loading={isLoading}
        onConfirm={
          feature.Type === TENANT_FEATURE.restrictedMode
            ? toggleRestrictedMode
            : toggleFeature
        }
        confirmButtonText={i18nextTranslate(
          feature?.IsEnabled
            ? i18nextKeys.buttonTurnOff
            : i18nextKeys.buttonTurnOn
        )}
        icon={isLoading
          ? <LoadingSpinner
              dataQa={`${feature.Type}-confirmation-loading`}
            />
          : <ToggleSymbol
              style={{
                transform: `scale(${!feature?.IsEnabled ? '1,1' : '-1,1'})`,
              }}
              data-qa={`${feature.Type}-confirmation-icon`}
            />
        }
        title={getFeatureToggleTextConfirmationTitle(feature.Type, feature.IsEnabled)}
        description={i18nextTranslate(
          getFeatureToggleTextConfirmationKey(
            feature?.Type,
            feature?.IsEnabled
          )
        )}
      />
    </>
  );
};

export default FeatureConfig;
