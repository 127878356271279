import React, { useEffect, useState } from 'react';
import api from 'Api';
import preval from 'preval.macro';
import Modal from 'Components/shared/Modal/Modal';
import { Button } from 'Components/shared/buttons';
import { copyToClipboard } from 'Utils/utils';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const buildVersion = {
  name: 'Build version',
  version: process.env.REACT_APP_VERSION,
};
const buildDate = {
  name: 'Build date',
  version: preval`module.exports = new Date().toLocaleString();`,
};

const DebugInfoModal = ({ show, close }) => {
  const [serverVersion, setServerVersion] = useState({});
  const [wlpBeVersion, setWlpBeVersion] = useState({});
  const [dependencies, setDependencies] = useState([]);

  useEffect(() => {
    if (show) {
      const getWlpBeVersion = async () => {
        try {
          const data = await api.DebugInfo.getWlpBeVersion();
          setWlpBeVersion(data);
        } catch (err) {
          console.log(err);
        }
      };
      getWlpBeVersion();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const getDependencies = async () => {
        try {
          const data = await api.DebugInfo.getClDependencies();
          setDependencies(data);
        } catch (err) {
          console.log(err);
        }
      };
      getDependencies();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const getServerVersion = async () => {
        try {
          const data = await api.DebugInfo.getClServerVersion();

          setServerVersion(data);
        } catch (err) {
          console.log(err);
        }
      };
      getServerVersion();
    }
  }, [show]);

  const filterVersions = (versions) => {
    return versions.filter(({ name, version }) => name && version)
  };

  const buttonWidth = {
    xxl: '190px',
    xl: '170px',
    default: '140px'
  };

  return (
    <Modal dataQa="debugInfoModal" show={show} close={close}>
      <div className="flex-col bg-3 px-32 py-32 color-8">
        <div className="flex flex-col items-start">
          {filterVersions([
            buildVersion,
            buildDate,
            wlpBeVersion,
            serverVersion,
            ...dependencies,
          ]).map(({ name, version }, index) => (
            <span key={index}>
              <span className="font-bold">{name}:</span> {version}
            </span>
          ))}

          <div className="flex justify-between gap-16 mt-24 md:mt-32 w-full">
            <Button
              dataQa="debugInfoModal-closeButton"
              onClick={close}
              text={i18nextTranslate(i18nextKeys.buttonClose)}
              width={buttonWidth}
              secondary
            />
            <Button
              onClick={() => {
                copyToClipboard(
                  JSON.stringify(
                    filterVersions([
                      buildVersion,
                      buildDate,
                      wlpBeVersion,
                      serverVersion,
                      ...dependencies,
                    ])
                  )
                );
              }}
              text={i18nextTranslate(i18nextKeys.buttonCopy)}
              dataQa="debugInfoModal-copyButton"
              width={buttonWidth}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DebugInfoModal;
