import React, { useContext } from 'react';
import Truncate from 'react-truncate';
import { UiContext } from 'States/ui/uiState';
import Text from '../Text';
import DefaultCardButtonGroup from './DefaultCardButtonGroup';

const DefaultCard = ({
  name = '',
  subtitle,
  description = '',
  iconUrl = '',
  buttons = [],
  svg,
  dataQa,
  buttonGroup = DefaultCardButtonGroup,
}) => {
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);

  const iconSize = xxl ? '206px' : md ? '188px' : '90px';

  return (
    <div
      data-qa={dataQa}
      className="grid gap-16 w-full p-16 md:px-0 md:pt-0 bg-3 rounded"
      style={
        md
          ? {
            width: xxl ? '206px' : '188px',
            gridTemplate: `"icon" "description"
                             "buttons"`,
            gridTemplateColumns: xxl ? '206px' : '188px',
            gridTemplateRows: 'auto minmax(0, 1fr) auto',
          }
          : {
            gridTemplate: `"icon description"
              "buttons buttons"`,
            gridTemplateColumns: '90px minmax(0, 1fr)',
          }
      }
    >
      {iconUrl && (
        <img
          alt={`${name}`}
          src={`${iconUrl}`}
          className="rounded md:rounded-none md:rounded-t"
          style={{
            height: iconSize,
            width: iconSize,
            objectFit: 'cover',
            gridArea: 'icon'
          }}
        />
      )}
      {svg ? (
        <div
          data-qa={`${dataQa}-imageWrapper`}
          className="bg-lightGrey flex justify-center items-center rounded md:rounded-none md:rounded-t"
          style={{
            height: iconSize,
            minWidth: iconSize,
            gridArea: 'icon'
          }}
        >
          {svg}
        </div>
      ) : null}
      <div
        className="flex flex-col md:px-16"
        style={{ gridArea: 'description' }}
      >
        <Text>
          <Truncate
            lines={4}
            data-qa={`${dataQa}-title`}
          >
            {name}
          </Truncate>
        </Text>
        <Text>
          {!!subtitle && (
            <Truncate
              lines={1}
              data-qa={`${dataQa}-subtitle`}
            >
              {subtitle}
            </Truncate>
          )}
        </Text>
        <hr className="border-color-3-10 my-16" />
        <Text
          textStyle="p2"
          dataQa={`${dataQa}-description`}
        >
          {description}
        </Text>
      </div>
      {buttons && buttonGroup({ buttons, dataQa })}
    </div>
  );
};

export default DefaultCard;
