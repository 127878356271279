import React from 'react';
import ErrorMessage from './ErrorMessage';

const TextField = ({
  data: {
    id,
    key,
    label,
    placeholder,
    required,
    valid,
    error,
    classes = 'w-full px-12',
    value,
    dataQa,
  },
  type = 'text',
  onChange,
  onFocus,
  disabled,
}) => (
  <div className={`${classes} mb-12 md:mb-4`} data-qa={dataQa}>
    <label
      className="block tracking-wide color-white text-xs font-bold mb-8"
      htmlFor={id}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {label}{required ? " *" : null}
    </label>
    <input
      value={value}
      name={key}
      label={label}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      className={`appearance-none block w-full h-40 px-16
                  bg-gray-200 text-gray-700
                  focus:outline-none focus:bg-9 bg-9 color-10 border-color-5
                  border rounded ${error && error.show ? 'border-red-500' : ''}
                  ${valid ? 'border-green-500' : ''}
                `}
      id={id}
      type={type || 'text'}
      placeholder={placeholder}
    />
    <ErrorMessage error={error} />
  </div>
);

export default TextField;
