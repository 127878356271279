import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const responseBody = (response) => {
  return response.data;
};

const withCorrelationId = async (config) => {
  const correlationId = uuidv4();

  if (!config.headers) {
    config.headers = {};
  }
  config.headers['X-Correlation-ID'] = correlationId;

  try {
    return responseBody(await axios(config));
  } catch (err) {
    err.correlationId = correlationId;
    throw err;
  }
};

const http = {
  get: (url, options) => withCorrelationId({ method: 'get', url, ...options }),
  post: (url, data, options) =>
    withCorrelationId({ method: 'post', url, data, ...options }),
  put: (url, data, options) =>
    withCorrelationId({ method: 'put', url, data, ...options }),
  patch: (url, data, options) =>
    withCorrelationId({ method: 'patch', url, data, ...options }),
  delete: (url, data, options) =>
    withCorrelationId({ method: 'delete', url, data, ...options }),
};

export default http;
