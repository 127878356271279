import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { useFormatNumber } from 'Utils/formatNumber';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Text from 'Components/shared/Text';
import FeeBlock from './FeeBlock';

const Amount = ({
  formattedAmount,
  units,
  label,
  assetName,
  dataQa,
  style = {},
}) => (
  <div
    className="flex justify-between border-solid rounded border-2 border-color-5 px-10 py-8"
    style={style}
  >
    <div className='flex gap-2'>
      <Text dataQa={`${dataQa}-label`}>
        {label}
      </Text>
      {!!assetName && (
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-asset`}
        >
          {assetName}
        </Text>
      )}
    </div>
    
    <Text
      textStyle='p2'
      dataQa={`${dataQa}-value`}
    >
      {`${formattedAmount} ${units}`}
    </Text>
  </div>
);

const PayReceiveSummary = ({ amountBreakdown, redemptionMethod}) => {
  const { breakpoints: { md } } = useContext(UiContext);
  const formatNumber = useFormatNumber();

  const {
    userGets: {
      uom: payoutUnits,
      name: payoutAssetName
    },
    userPays: {
      UnitOfMeasureCode: redeemUnits,
      CurrencyCode: redeemCurrency,
      Name: redeemAssetName
    }
  } = redemptionMethod;

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <Amount
          formattedAmount={
            formatNumber(amountBreakdown.GrossAmount.AssetUnitsString)
          }
          units={redeemUnits || redeemCurrency}
          label={i18nextTranslate(i18nextKeys.redemptionPayReceiveYouPay)}
          assetName={redeemAssetName}
          style={{ width: md ? '45%' : '100%' }}
          dataQa="pay"
        />
        {!md && (
          <FeeBlock
            calculation={amountBreakdown}
            payoutUnits={payoutUnits}
            redeemUnits={redeemUnits || redeemCurrency}
            className="w-full mt-24 mb-32"
          />
        )}
        <Amount
          formattedAmount={
            formatNumber(amountBreakdown.NetAmount.PayoutUnitsString)
          }
          units={payoutUnits}
          label={i18nextTranslate(i18nextKeys.redemptionPayReceiveYouReceive)}
          assetName={payoutAssetName}
          style={{ width: md ? '45%' : '100%' }}
          dataQa="receive"
        />
      </div>
      {md && (
        <FeeBlock
          calculation={amountBreakdown}
          payoutUnits={payoutUnits}
          redeemUnits={redeemUnits || redeemCurrency}
          style={{ width: '45%' }}
        />
      )}
    </>
  );
};

export default PayReceiveSummary;
