import React, { useContext } from 'react';
import { DefaultCard } from 'Components/shared/cards';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import { ConfigContext } from 'States/config/configState';
import { useFormatNumber } from 'Utils/formatNumber';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import useConfigSettings from 'Hooks/useConfigSettings';
import AssetCardButtonGroup from './AssetCardButtonGroup';

const AssetCard = ({
  asset,
  openAssetInfo,
  openAssetModal,
  dataQa
}) => {
  const formatNumber = useFormatNumber();
  const { isAuthenticated } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);
  const { data: settings } = useConfigSettings.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : []
  });

  const requiredTier = isAuthenticated
    ? Math.max(
        kycTierFunctionalRestrictions?.availability?.purchase || 0,
        asset.kycTier || 0
      )
    : 0

  return (
    <DefaultCard
      dataQa={dataQa}
      name={
        asset.Ticker
          ? `${asset.Name} (${asset.Ticker})`
          : asset.Name
      }
      iconUrl={`${env.BlobUrl}/${asset.icon}`}
      description={i18nextTranslateDynamically(
        i18nextKeys.purchaseAssetCardPriceTag,
        {
          price: formatNumber(asset.price),
          currency: settings?.Currency.Code,
        }
      )}
      buttonGroup={() =>
        AssetCardButtonGroup({
          openAssetInfo,
          openAssetModal,
          kycTier: requiredTier,
        })
      }
    />
  );
};

export default AssetCard;
