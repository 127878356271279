import i18nextTranslate from '../Lang/i18nextTranslate';
import { i18nextKeys } from '../Lang/i18nextKeys';

export const PAYOUT_METHOD = {
  None: 'None',
  BankTransfer: 'BankTransfer',
  BitcoinPayment: 'BitcoinPayment',
  Ethereum: 'Ethereum',
  EthereumToken: 'EthereumToken',
  PhysicalDelivery: 'PhysicalDelivery',
};

const enumToFriendlyString = (e) => {
  const {
    BankTransfer,
    BitcoinPayment,
    Ethereum,
    EthereumToken,
    PhysicalDelivery,
  } = PAYOUT_METHOD;
  switch (e) {
    case BankTransfer: {
      return i18nextTranslate(i18nextKeys.enumPayoutMethodBankTransfer);
    }
    case BitcoinPayment: {
      return i18nextTranslate(i18nextKeys.enumPayoutMethodBitcoin);
    }
    case Ethereum: {
      return i18nextTranslate(i18nextKeys.enumPayoutMethodEthereum);
    }
    case EthereumToken: {
      return i18nextTranslate(i18nextKeys.enumPayoutMethodEthereumToken);
    }
    case PhysicalDelivery: {
      return i18nextTranslate(i18nextKeys.enumPayoutMethodPhysicalDelivery);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumNone);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const {
    BankTransfer,
    BitcoinPayment,
    Ethereum,
    EthereumToken,
    PhysicalDelivery,
  } = PAYOUT_METHOD;
  switch (str) {
    case i18nextTranslate(i18nextKeys.enumPayoutMethodBankTransfer): {
      return BankTransfer;
    }
    case i18nextTranslate(i18nextKeys.enumPayoutMethodBitcoin): {
      return BitcoinPayment;
    }
    case i18nextTranslate(i18nextKeys.enumPayoutMethodEthereum): {
      return Ethereum;
    }
    case i18nextTranslate(i18nextKeys.enumPayoutMethodEthereumToken): {
      return EthereumToken;
    }
    case i18nextTranslate(i18nextKeys.enumPayoutMethodPhysicalDelivery): {
      return PhysicalDelivery;
    }
    default: {
      return PAYOUT_METHOD[str] || PAYOUT_METHOD.None;
    }
  }
};

const configTypeToEnum = {
  bank: PAYOUT_METHOD.BankTransfer,
  bitcoin: PAYOUT_METHOD.BitcoinPayment,
  eth: PAYOUT_METHOD.Ethereum,
  physical: PAYOUT_METHOD.PhysicalDelivery,
  erc20: PAYOUT_METHOD.EthereumToken,
};

const PayoutMethod = {
  values: PAYOUT_METHOD,
  enumToFriendlyString,
  friendlyStringToEnum,
  configTypeToEnum,
};

export default PayoutMethod;
