import React, {
  useContext,
  useMemo,
  useState,
  useRef,
  useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import api from 'Api';
import handleError from 'Helpers/handleError';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import Table from 'Components/shared/Table/Table';
import GlobalFilter from 'Components/shared/Table/GlobalFilter';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { AbortSymbol } from 'Components/shared/symbols';
import { TrashCanSymbol } from 'Components/shared/symbols';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import { Button } from 'Components/shared/buttons';
import { PlusSymbol } from 'Components/shared/symbols';
import SubsectionHeading from '../../shared/SubsectionHeading';
import AddAdminModal from './AddAdminModal';

const PAGE_SIZE = 20;
const AdminConfig = ({ dataQa = "admins" }) => {
  const history = useHistory();
  const [adminRemovalModalOptions, setAdminRemovalModalOptions] = useState({
    show: false,
    id: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [showAdminModal, setShowAdminModal] = useState(false);
  const fetchIdRef = useRef(0);

  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  const fetchData = useCallback(
    (pageIndex, pageSize, [_orderBy], [filter], globalFilter) => {
      const fetchId = ++fetchIdRef.current;
      (async () => {
        try {
          // Abort if this is not the latest fetch
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          const filterOdata = filter && `${filter.id} eq '${filter.value}'`;
          const globalFilterOdata =
            globalFilter &&
            `contains(EmailAddress, '${globalFilter}') ` +
            `or contains(concat(concat(FirstName, ' '), LastName), '${globalFilter}')`;

          const res = await api.Config.Users.getAdmins({
            top: pageSize,
            skip: pageSize * pageIndex,
            filter:
              filter && globalFilter
                ? `${filterOdata} and (${globalFilterOdata})`
                : filter
                  ? filterOdata
                  : globalFilter
                    ? globalFilterOdata
                    : null,
          });

          const { value } = res;
          const totalCount = res['@odata.count'];

          setPageCount(Math.ceil(totalCount / PAGE_SIZE));
          setAdmins(value);
          setIsLoading(false);
        } catch (error) {
          handleError({ error, history });
        }
      })();
    },
    [history]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: i18nextTranslate(i18nextKeys.helperOrderUser),
        accessor: (user) => `${user.FirstName} ${user.LastName}`,
        sortType: 'basic',
        id: 'User',
        color: 'color-4',
        width: '240px',
        disableFilters: true,
        disableSortBy: true,
        dataQa: "user"
      },
      {
        Header: i18nextTranslate(i18nextKeys.commonEmail),
        accessor: (user) => user.EmailAddress || '-',
        id: 'EmailAddress',
        sortType: 'basic',
        color: 'color-8',
        width: '502px',
        disableFilters: true,
        disableSortBy: true,
        dataQa: "email"
      },
      {
        Header: '',
        accessor: (row, index) => (
          <AbortSymbol
            size={xxl ? "16" : "14"}
            className="color-6 cursor-pointer"
            onClick={() => openRemoveAdminModal(row)}
            data-qa={`${dataQa}-table-row-${index}-remove-button`}
          />
        ),
        id: 'removeAction',
        className: 'flex justify-end pr-16',
        width: '115px',
        disableFilters: true,
        disableSortBy: true,
        dataQa: "remove"
      },
    ];
  }, [lang]);

  const openRemoveAdminModal = ({ Id }) => {
    setAdminRemovalModalOptions({
      show: true,
      id: Id,
    });
  };

  const onRemoveAdmin = async () => {
    const { id } = adminRemovalModalOptions;
    setAdminRemovalModalOptions({ show: false, id: undefined });
    setIsLoading(true);
    await api.Config.Users.patch(id, { IsAdmin: false });
    fetchData(0, PAGE_SIZE, [], [], null);
  };

  return (
    <div className="mt-40 flex flex-col gap-12">
      <div className="flex justify-between items-center">
        <SubsectionHeading
          text={i18nextTranslate(i18nextKeys.tenantSettingsAdminTableHeading)}
          tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsAdminTableTooltip)}
          className=""
          dataQa={dataQa}
        />
        <div className="flex gap-16">
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={i18nextTranslate(i18nextKeys.kycManagementOverviewSearchByUser)}
            width={!md ? "100%" : null}
          />
          <Button
            text={i18nextTranslate(i18nextKeys.tenantSettingsAdminTableAdd)}
            icon={PlusSymbol}
            onClick={() => setShowAdminModal(true)}
            className="flex-row-reverse"
            width={{ default: '170px' }}
            dataQa={`${dataQa}-add`}
          />
        </div>
      </div>

      <Table
        dataQa={dataQa}
        defaultPageSize={PAGE_SIZE}
        columns={columns}
        pageCount={pageCount}
        fetchData={fetchData}
        isLoading={isLoading}
        data={admins || []}
        outerGlobalFilter={globalFilter}
      />

      <AddAdminModal
        show={showAdminModal}
        close={(e) => {
          setShowAdminModal(false);
          if (e.refetchAdmins) {
            fetchData(0, PAGE_SIZE, [], [], null);
          }
        }}
      />

      <ConfirmationModal
        show={adminRemovalModalOptions.show}
        close={() =>
          setAdminRemovalModalOptions({ show: false, id: undefined })
        }
        onConfirm={onRemoveAdmin}
        confirmButtonText={i18nextTranslate(i18nextKeys.buttonConfirm)}
        icon={<TrashCanSymbol size={ xxl ? "36" : "30"} />}
        title={i18nextTranslate(
          i18nextKeys.tenantSettingsAdminTableRemoveConfirmation
        )}
        description={i18nextTranslate(i18nextKeys.tenantSettingsAdminTableRemoveInfo)}
      />
    </div>
  );
};

export default AdminConfig;
