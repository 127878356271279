import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'Api';
import { ConfigContext } from 'States/config/configState';
import { KycContext } from 'States/kyc/kycState';
import { UiContext } from 'States/ui/uiState';
import Header from 'Components/shared/Header';
import {
  SelectIcon,
  PageIcon,
  ThumbIcon,
  FunctionalityIcon,
} from 'Components/shared/symbols';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import usePaymentMethods from 'Hooks/usePaymentMethods';
import handleError from 'Helpers/handleError';
import { KYC_CASE_STATUS } from 'Enums/KycCaseStatus';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import KycTiersTable from './KycTiersTable';

const KycTiers = () => {
  const [loading, setLoading] = useState(true);
  const [featureList, setFeatureList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [assets, setAssets] = useState([])
  const history = useHistory();
  const {
    currentKycTier,
    latestKycCase,
    isKycCaseLoading
  } = useContext(KycContext);
  const {
    config: { kycTiersDescription },
  } = useContext(ConfigContext);
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const kycGuideSteps = [
    {
      Icon: SelectIcon,
      text: i18nextTranslate(i18nextKeys.kycGuideStep1),
    },
    {
      Icon: PageIcon,
      text: i18nextTranslate(
        (kycTiersDescription[currentKycTier]?.providers?.[0].name ||
          kycTiersDescription[1]?.providers?.[0].name) === KYC_PROVIDER.manual
          ? i18nextKeys.kycGuideStep2Manual
          : i18nextKeys.kycGuideStep2
      )
    },
    {
      Icon: ThumbIcon,
      text: i18nextTranslate(i18nextKeys.kycTiersGuideCheckTierStatus),
    },
    {
      Icon: FunctionalityIcon,
      text: i18nextTranslate(i18nextKeys.kycTiersGuideUseUnlockedFeatures),
    },
  ];

  const {
    isLoading: loadingPaymentMethods,
    data: paymentMethods
  } = usePaymentMethods.methodsQuery({
    refetchOnWindowFocus: false,
    onError: (error) => {
      handleError({ error, history });
    }
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { value: featureList } = await api.Kyc.getFeatureList();
        const { value: tierList } = await api.Kyc.getTierList();
        const { value: assetsData } = await api.Assets.getAssets();
        setFeatureList(featureList);
        setTierList(tierList);
        setAssets(assetsData);
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.kycLoadUserTierError);
        handleError({ error, history, message });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div data-qa="kycTiersPage">
      <Header text={i18nextTranslate(i18nextKeys.kycTiersPageHeader)} />
      {loading || isKycCaseLoading || loadingPaymentMethods ? (
        <LoadingSpinner classes="mr-auto ml-auto" />
      ) : (
        <>
          <div className="px-16 sm:px-0 color-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-48">
              {kycGuideSteps.map(({ Icon, text }, index) => (
                <div key={text} className="flex gap-16">
                  <div
                    className="bg-3 rounded-lg p-16 xxl:p-14"
                    data-qa={`kycTiersPage-step-${index + 1}-icon`}
                  >
                    <Icon size={xxl ? "48" : "32"} />
                  </div>
                  <div
                    className="color-8"
                    data-qa={`kycTiersPage-step-${index + 1}-description`}
                  >
                    <b>{`0${index + 1}.`}</b>
                    <p className="text-sm">{text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <KycTiersTable
            featureList={featureList}
            paymentMethods={paymentMethods.value}
            tierList={tierList}
            assets={assets}
            currentKycTier={currentKycTier}
            pendingKycTier={latestKycCase.RequestedTierNumber}
            isKycCaseOpen={latestKycCase.Status === KYC_CASE_STATUS.open}
          />
        </>
      )}
    </div>
  );
};

export default KycTiers;
