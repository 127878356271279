import React from 'react';
import { Link } from 'react-router-dom';

const SidebarLink = ({
  route,
  text,
  link,
  onClick,
  dataQa,
  active,
  state = {}
}) => {
  return (
    <li
      data-qa={dataQa}
      className={`px-16 py-24 md:py-12 font-extrabold rounded-l-lg ${active ? 'sidebarLink-active-highlight' : ''
        }`}
      onClick={onClick}
    >
      {link ? (
        <a href={link}>
          <span className="pb-4 hover:border-3 border-transparent border-b-2 hover:border-current transition duration-100 ease-in">
            {text}
          </span>
        </a>
      ) : (
        <Link
          to={{
            pathname: route,
            state
          }}
        >
          <span className="pb-4 hover:border-3 border-transparent border-b-2 hover:border-current transition duration-100 ease-in">
            {text}
          </span>
        </Link>
      )}
    </li>
  );
};

export default SidebarLink;
