import formatDate from 'Utils/formatDate';
import PayoutMethodEnum from 'Enums/PayoutMethod';
import RedemptionStatusEnum from 'Enums/RedemptionStatus';
import i18nextTranslate from '../Lang/i18nextTranslate';
import { i18nextKeys } from '../Lang/i18nextKeys';

export const getRedeemTableColumns = (formatNumber, xxl) => [
  {
    Header: i18nextTranslate(i18nextKeys.commonReferenceCode),
    accessor: 'ReferenceCode',
    id: 'ReferenceCode',
    disableSortBy: true,
    color: 'color-4',
    width: xxl ? '172px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemUser),
    accessor: (row) => `${row.User.FirstName} ${row.User.LastName}`,
    id: 'User',
    color: 'color-4',
    width: xxl ? '190px' : '150px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.commonDate),
    accessor: (row) => `${formatDate(row.ReceivedOn)}`,
    id: 'ReceivedOn',
    color: 'color-8',
    width: xxl ? '90px' : '82px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemMethod),
    id: 'PayoutMethodData/PayoutMethod',
    accessor: (row) =>
      PayoutMethodEnum.enumToFriendlyString(
        row?.PayoutMethodData?.PayoutMethod
      ),
    color: 'color-8',
    width: xxl ? '166px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionAssetName),
    id: 'RedeemAssetName',
    accessor: 'RedeemAssetName',
    color: 'color-8',
    width: xxl ? '166px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionGross),
    id: 'GrossAmount/AssetUnits',
    accessor: (row) => `${formatNumber(row.GrossAmount.AssetUnitsString)
      } ${row.RedeemAssetUnitOfMeasureCode}`,
    color: 'color-8',
    width: xxl ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionPayout),
    id: 'GrossAmount/PayoutUnits',
    accessor: (row) => `${formatNumber(row.GrossAmount.PayoutUnitsString)
      } ${row.Currency}`,
    color: 'color-8',
    width: xxl ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemStatus),
    accessor: (row) => RedemptionStatusEnum.enumToFriendlyString(row.Status),
    color: 'color-8',
    width: xxl ? '101px' : '85px',
    disableFilters: true,
  },
];
