import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'Components/shared/Modal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import AddAdminModalTable from './AddAdminModalTable';
import handleError from 'Helpers/handleError';
import api from 'Api';
import IndeterminateCheckbox from './IndeterminateCheckbox';

const AddAdminModal = ({
  show,
  close,
  defaultPageSize = 50,
  dataQa = "admins-add-modal"
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  const setAdmin = async (selectedRows, beforeClose = () => {}) => {
    setIsLoading(true);
    const patchRows = selectedRows.map((row) =>
    api.Config.Users.patch(row.Id, { IsAdmin: true })
    );
    await Promise.all(patchRows);
    beforeClose();
    close({ refetchAdmins: true });
    setIsLoading(false);
  };

  const selectedRowFilter = React.useMemo(
    () => (rows, ids, filterValue) => {
      if (filterValue) {
        return rows.filter((row) => {
          return row.isSelected;
        });
      }
      return rows;
    },
    []
  );

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        id: 'selection',
        filter: 'selectedRowFilter',
        className: 'color-8 px-8',
        disableSortBy: true,
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            boxClasses={`${row.isSelected ? 'bg-4--20' : 'bg-9'} w-16 h-16`}
            checkMarkClasses="w-2 h-8"
            checkMarkColor="white"
            dataQa={`kycTiersTable-tier-`}
            {...row.getToggleRowSelectedProps()}
            data-qa={`${dataQa}-table-row-${row.index}-select-checkbox`}
          />
        ),
        width: '32px',
        dataQa: "select"
      },
      {
        Header: i18nextTranslate(i18nextKeys.helperOrderUser),
        accessor: (user) => `${user.FirstName} ${user.LastName}`,
        sortType: 'basic',
        id: 'User',
        className: 'color-8',
        disableFilters: true,
        disableSortBy: true,
        dataQa: "user"
      },
      {
        Header: i18nextTranslate(i18nextKeys.commonEmail),
        accessor: (user) => user.EmailAddress || '-',
        id: 'EmailAddress',
        sortType: 'basic',
        className: 'color-8',
        disableFilters: true,
        disableSortBy: true,
        dataQa: "email"
      },
    ];
  }, []);

  const fetchData = useCallback(
    (pageIndex, pageSize, [_orderBy], [filter], globalFilter) => {
      const fetchId = ++fetchIdRef.current;
      (async () => {
        try {
          // Abort if this is not the latest fetch
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          setIsLoading(true);
          const globalFilterOdata =
            globalFilter &&
            `contains(EmailAddress, '${globalFilter}') ` +
            `or contains(concat(concat(FirstName, ' '), LastName), '${globalFilter}')`;

          const res = await api.Config.Users.getUsers({
            ...!globalFilter && {
              top: pageSize,
              skip: pageSize * pageIndex,
            },
            filter: globalFilter && globalFilterOdata,
          });

          const { value } = res;
          const totalCount = res['@odata.count'];

          setPageCount(Math.ceil(totalCount / defaultPageSize));
          setUsers(value);
          setIsLoading(false);
        } catch (error) {
          const message = i18nextTranslate(
            i18nextKeys.errorAccountNoRequiredData
          );
          handleError({ error, history, message });
        }
      })();
    },
    [history]
  );

  return (
    <Modal
      dataQa={dataQa}
      show={show}
      close={close}
    >
      <div className="flex flex-col bg-3 rounded modalContent p-16 sm:py-32 sm:px-32">
        <h2
          className="color-8 text-xl font-bold mb-16"
          data-qa={`${dataQa}-title`}
        >
          {i18nextTranslate(i18nextKeys.tenantSettingsAdminTableAddDescription)}
        </h2>
        {show ? (
          <AddAdminModalTable
            dataQa={dataQa}
            defaultPageSize={defaultPageSize}
            columns={columns}
            pageCount={pageCount}
            fetchData={fetchData}
            isLoading={isLoading}
            data={users}
            showGlobalFilter={true}
            uppercaseHeader={false}
            selectedRowFilter={selectedRowFilter}
            close={close}
            setAdmin={setAdmin}
          />
        ) : (
          <div className="flex justify-center bg-3 px-32 py-32">
            <LoadingSpinner
              dataQa={`${dataQa}-loading`}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddAdminModal;
