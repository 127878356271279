import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Form as FinalForm,
  Field as FinalField
} from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useHistory, useParams } from "react-router-dom";
import api from "Api";
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import handleError from "Helpers/handleError";
import { DIRECTION } from 'Helpers/icons';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { COLLECT_METHOD } from "Enums/CollectMethod";
import { TENANT_FEATURE } from "Enums";
import { UiContext } from "States/ui/uiState";
import { HorizontalRule } from "Components/shared/Tile";
import Text from "Components/shared/Text";
import { Arrow } from "Components/shared/symbols";
import Toggle from "Components/shared/Toggle";
import Tooltip from "Components/shared/Tooltip";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import SubsectionHeading from "../../shared/SubsectionHeading";
import Description from "../../shared/translations/Description";
import TranslationsForm from "../../shared/translations/TranslationsForm";
import Footer from "../Footer";
import {
  CountryFeesForm,
  FeesDescription,
  MethodFeeForm
} from "./Fees";

const CollectionMethod = () => {
  const [collectionMethod, setCollectionMethod] = useState(null);
  const [isSingleEnabledMethod, setIsSingleEnabledMethod] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    type: collectionMethodType
  } = useParams();
  const history = useHistory();

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const {
    data: isPurchaseEnabled
  } = useFeatureAvailability.query({
    refetchOnMount: false,
    select: useCallback(features => features.some(feature =>
      feature.IsEnabled &&
      (feature.Type === TENANT_FEATURE.purchase || feature.Type === TENANT_FEATURE.customFeaturedAsset)
    ), []),
    onError: (error) => handleError({ error, history })
  });

  useEffect(() => {
    const getCollectionMethod = async (type) => {
      try {
        if (isPurchaseEnabled) {
          const { value: collectionMethods } = await api.Config.CollectionMethods.getAll();
          const collectionMethod = collectionMethods.find(({ Type }) => Type === collectionMethodType);
          if (collectionMethod.IsEnabled) {
            const enabledMethods = collectionMethods.filter(
              ({ IsEnabled }) => IsEnabled
            );
            setIsSingleEnabledMethod(enabledMethods.length < 2);
          }
          setCollectionMethod(collectionMethod);
        } else {
          const collectionMethod = await api.Config.CollectionMethods.get(type);
          setCollectionMethod(collectionMethod);
        }
        setLoading(false);
      } catch (error) {
        handleError({ error, history });
      }
    };
    if (collectionMethodType) {
      getCollectionMethod(collectionMethodType)
    } else {
      history.replace(ROUTES.admin.config.purchase);
    }
  }, [isPurchaseEnabled]);

  const openPurchaseConfig = () =>
    history.replace(ROUTES.admin.config.purchase);

  const mergeTranslations = (translations) => {
    const mergedTranslations = {};
    Object.keys(translations).forEach(property => {
      return translations[property].forEach(translation => {
        return Object.assign(
          mergedTranslations[translation.LanguageCode] ??= {},
          translation
        );
      })
    });
    return Object.values(mergedTranslations);
  };

  const save = async (values, form) => {
    setLoading(true);
    const {
      translations,
      CountryFees,
      ...methodParams
    } = values;
    const Translations = mergeTranslations(translations);
    try {
      await api.Config.CollectionMethods.patch(
        collectionMethod.Type,
        {
          ...methodParams,
          Translations,
          CountryFees: CountryFees || []
        }
      );
      form.restart(values);
    } catch (error) {
      handleError({ error, history });
    }
    setLoading(false);
  };

  const saveAndEnable = async (values, form) => {
    await save({
      ...values,
      IsEnabled: true
    }, form);
    history.push(ROUTES.admin.config.purchase);
  };

  const disable = async () => {
    setLoading(true);
    await api.Config.CollectionMethods.patch(
      collectionMethod.Type,
      { IsEnabled: false }
    );
    history.push(ROUTES.admin.config.purchase);
  }

  const methodNameTranslation = collectionMethod
    ? i18nextTranslate(
      i18nextKeys[`configCollection${collectionMethod?.Type}Title`]
    )
    : "";
  const fieldWidth = xxl ? "500px" : "404px";
  const textAreaHeight = xxl ? "119px" : "84px";
  const contentWidth = xxl ? "700px" : "600px";

  const insertAtMutator = ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, array => {
      const copy = [...(array || [])];
      copy.splice(index, 0, value);
      return copy;
    })
  };

  return (
    <FinalForm
      onSubmit={save}
      initialValues={{
        MethodFees: collectionMethod?.MethodFees,
        FallBackCountryFees: collectionMethod?.FallBackCountryFees,
        UseWlmWallets: collectionMethod?.UseWlmWallets
      }}
      mutators={{
        ...arrayMutators,
        insertAt: insertAtMutator
      }}
      render={({
        handleSubmit,
        invalid,
        pristine,
        form,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <div className="relative">
            {!collectionMethod ? (
              <div className="flex justify-center px-32 py-24">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="px-32 py-24">
                  <div className="flex gap-16 items-center mb-32">
                    <Arrow
                      size={xxl ? "24" : "20"}
                      direction={DIRECTION.left}
                      className="color-6 cursor-pointer"
                      onClick={openPurchaseConfig}
                    />
                    <Text
                      textStyle="h2"
                      dataQa="collection-method-title"
                      uppercase
                    >
                      {i18nextTranslate(i18nextKeys.configCollectionMethod)} - {methodNameTranslation}
                    </Text>
                  </div>
                  {collectionMethod.Type === COLLECT_METHOD.OwnNonSignableWallet && (
                    <div className="flex gap-16">
                      <FinalField
                        name="UseWlmWallets"
                        type="checkbox"
                        dataQa="useWlmWallets"
                        render={({ input }) => {
                          return (
                            <Toggle
                              toggled={input.checked}
                              onToggle={input.onChange}
                            />
                          )
                        }}
                      />
                      <div className="flex items-center gap-8">
                        <Text textStyle="p2">
                          {i18nextTranslate(
                            i18nextKeys.configCollectionOwnNonSignableWalletWlmWalletsLabel)
                          }
                        </Text>
                        <Tooltip
                          text={i18nextTranslate(
                            i18nextKeys.configCollectionOwnNonSignableWalletWlmWalletsTooltip
                          )}
                          dataQa="useWlmWallets"
                          useIcon
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="flex flex-col gap-16"
                    style={{ width: contentWidth }}
                  >
                    <SubsectionHeading
                      text={i18nextTranslateDynamically(
                        i18nextKeys.configCollectionDescriptionTitle,
                        { name: methodNameTranslation }
                      )}
                      tooltipText={i18nextTranslate(i18nextKeys[
                        `configCollection${
                          collectionMethod.Type
                        }TranslationDescriptionTooltip`
                      ])}
                      className=""
                      dataQa="description"
                    />
                    <TranslationsForm
                      fieldArrayName="translations.description"
                      defaultValue={{ Description: null }}
                      translations={collectionMethod.Translations}
                      filterKeys={[ "Description" ]}
                      fieldWidth={fieldWidth}
                      fields={(props) => (
                        <Description
                          fieldName="Description"
                          fieldWidth={fieldWidth}
                          textAreaHeight={textAreaHeight}
                          maxLength={650}
                          placeholderKey={`configCollection${collectionMethod.Type}TranslationDescription`}
                          required
                          {...props}
                        />
                      )}
                    />
                  </div>
                  {collectionMethod.Type === COLLECT_METHOD.OneTimeWallet && (
                    <div
                      className="flex flex-col gap-16 mt-40 xxl:mt-48"
                      style={{ width: contentWidth }}
                    >
                      <SubsectionHeading
                        text={i18nextTranslate(i18nextKeys.configCollectionDisclaimerTitle)}
                        tooltipText={i18nextTranslate(i18nextKeys[
                          `configCollection${
                            collectionMethod.Type
                          }TranslationDisclaimerTooltip`
                        ])}
                        className=""
                        dataQa="disclaimer"
                      />
                      <TranslationsForm
                        fieldArrayName="translations.disclaimer"
                        defaultValue={{ Disclaimer: null }}
                        translations={collectionMethod.Translations}
                        filterKeys={[ "Disclaimer" ]}
                        fieldWidth={fieldWidth}
                        fields={(props) => (
                          <Description
                            fieldName="Disclaimer"
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                            maxLength={500}
                            placeholderKey={`configCollection${collectionMethod.Type}TranslationDisclaimer`}
                            required
                            {...props}
                          />
                        )}
                      />
                    </div>
                  )}
                  <HorizontalRule className="my-32 xxl:my-40" />
                  <div
                    className="flex flex-col gap-40 xxl:gap-48"
                    style={{ width: contentWidth }}
                  >
                    <FeesDescription />
                    <MethodFeeForm
                      title={i18nextTranslateDynamically(
                        i18nextKeys.configCollectionFeesMethodTitle,
                        { name: methodNameTranslation }
                      )}
                      description={i18nextTranslate(
                        i18nextKeys.configCollectionFeesMethodDescription
                      )}
                      fieldWidth={fieldWidth}
                    />
                    <CountryFeesForm
                      title={i18nextTranslateDynamically(
                        i18nextKeys.configCollectionFeesCountryTitle,
                        { name: methodNameTranslation }
                      )}
                      countryFees={collectionMethod.CountryFees}
                      fieldWidth={fieldWidth}
                    />
                  </div>
                </div>
                {collectionMethod.IsEnabled ? (
                  <Footer
                    title={methodNameTranslation}
                    description={i18nextTranslate(
                      i18nextKeys.configFooterMethodEnabledDescription
                    )}
                    tooltipText={i18nextTranslate(
                      i18nextKeys[`configCollection${collectionMethod.Type}Description`]
                    )}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configUpdateSettings)}
                    primaryButtonWidth={{
                      xxl: "210px",
                      default: "200px"
                    }}
                    onPrimaryButtonClick={handleSubmit}
                    primaryButtonDisabled={loading || invalid || pristine}
                    primaryButtonLoading={loading}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configDisableMethod)}
                    secondaryButtonTooltipText={
                      isSingleEnabledMethod
                        ? i18nextTranslate(i18nextKeys.configCollectionTooltip)
                        : null
                    }
                    onSecondaryButtonClick={disable}
                    secondaryButtonDisabled={loading || isSingleEnabledMethod}
                    dataQa="method"
                  />
                ) : (
                  <Footer
                    title={methodNameTranslation}
                    description={i18nextTranslate(
                      i18nextKeys.configCollectionFooterDescription
                    )}
                    tooltipText={i18nextTranslate(
                      i18nextKeys[`configCollection${collectionMethod.Type}Description`]
                    )}
                    primaryButtonText={i18nextTranslate(i18nextKeys.configEnableMethod)}
                    primaryButtonWidth={{
                      xxl: "383px",
                      default: "281px"
                    }}
                    onPrimaryButtonClick={() => saveAndEnable(values, form)}
                    primaryButtonDisabled={loading || collectionMethod?.IsEnabled || invalid}
                    primaryButtonLoading={loading}
                    secondaryButtonText={i18nextTranslate(i18nextKeys.configSaveSettings)}
                    onSecondaryButtonClick={handleSubmit}
                    secondaryButtonDisabled={loading || invalid || pristine}
                    dataQa="method"
                  />
                )}
              </>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default CollectionMethod;
