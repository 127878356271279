import { useHistory } from 'react-router-dom';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { STORAGE_KEYS, TENANT_FEATURE } from 'Enums';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import { useContext } from 'react';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import { ROUTES } from 'Router/Routes';

export const getPreferedRedirectPath = (features = null) => {
  const returnUrl = sessionStorage.getItem(STORAGE_KEYS.authReturnPath);
  if (returnUrl) {
    return returnUrl;
  }
  if (
    isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset)
  ) {
    return ROUTES.featuredAssetBase;
  } else if (isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)) {
    return ROUTES.shop;
  } else {
    return '/';
  }
};

const usePostLoginRedirect = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const { data: features } = useFeatureAvailability.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : [],
    enabled: !!env,
    staleTime: 300000,
  });
  const history = useHistory();

  const postLoginRedirect = () => {
    history.replace(getPreferedRedirectPath(features));
  };

  return postLoginRedirect;
};

export default usePostLoginRedirect;
