import React, { useReducer, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import langReducer from './langReducer';
import { SET_LANG } from './langTypes';
import i18next from 'Lang/i18nextConfig';
import api from 'Api';
import handleError from 'Helpers/handleError';
import { i18nextKeys } from 'Lang/i18nextKeys';

export const LangContext = createContext();

const isLanguageAvailable = (language, availableLanguages) => {
  return availableLanguages.some(({ Code }) => Code === language.toLowerCase());
};

const LangState = (props) => {
  const initialState = {
    lang: '',
  };

  const [state, dispatch] = useReducer(langReducer, initialState);
  const history = useHistory();

  const getLang = async (
    user,
    availableLanguages = []
  ) => {
    if (state.lang) {
      return state.lang;
    }

    if (user && !user.isNewUser) {
      try {
        const { Language } = await api.User.get(user.profile.sub, '');
        if (
          Language &&
          isLanguageAvailable(Language, availableLanguages)
        ) {
          return setLang(Language);
        }
      } catch (error) {
        const message = i18nextKeys.accountGetLanguageError;
        handleError({ error, history, message });
      }
    }

    const localStorageLang = localStorage.getItem('i18nextLng');
    if (
      localStorageLang &&
      isLanguageAvailable(localStorageLang, availableLanguages)
    ) {
      return setLang(localStorageLang);
    }

    const userBrowserLanguage = (
      navigator.language || navigator.userLanguage
    ).slice(0, 2); // only use language prefix: e.g. "en" from "en-US"

    const translationAvailable = isLanguageAvailable(
      userBrowserLanguage,
      availableLanguages
    );

    if (translationAvailable) {
      return setLang(userBrowserLanguage);
    }

    const defaultLanguage = availableLanguages.find(({ IsDefault }) => IsDefault);
    return setLang(defaultLanguage.Code);
  };

  const setLang = (lang, user) => {
    if (!lang) return;
    // Update client side immediately
    i18next.changeLanguage(lang.toLowerCase());
    dispatch({ type: SET_LANG, payload: lang });
    // Update asynchronous in backend if logged in
    if (user) {
      try {
        api.User.updateDelta(user.profile.sub, {
          Language: lang.toLowerCase(),
        });
      } catch (error) {
        const message = i18nextKeys.accountSetLanguageError;
        handleError({ error, history, message });
      }
    }
    return lang.toLowerCase();
  };

  return (
    <LangContext.Provider
      value={{
        lang: state.lang,
        getLang,
        setLang,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
};

export default LangState;
