import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';
import { combineValidators } from 'revalidate';
import AnimateHeight from "react-animate-height";
import api from 'Api';
import { UiContext } from 'States/ui/uiState';
import Modal from 'Components/shared/Modal';
import { Button } from 'Components/shared/buttons';
import { FormField } from 'Components/shared/formElements';
import { walletAddressValidator } from 'Components/shared/formElements/validators';
import Text from 'Components/shared/Text';
import LoadingSpinner from "Components/shared/LoadingSpinner/LoadingSpinner";
import handleError from 'Helpers/handleError';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const walletAddressModalValidator = combineValidators({
  newWalletAddress: walletAddressValidator(),
});

let formRef;

const WalletAddressModal = ({ show, close, order, setOrder }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  useEffect(() => {
    if (show && formRef) {
      formRef.restart();
    }
  }, [show]);

  const updateWalletAddress = async ({ newWalletAddress }) => {
    try {
      setIsLoading(true);
      await api.PurchaseOrder.updateDelta(order.Id, {
        TargetEthAddress: newWalletAddress,
      });
      setOrder({ ...order, TargetEthAddress: newWalletAddress });
      if (formRef) {
        setTimeout(formRef.restart);
      }
      close();
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorPurchaseManagementDetailsWalletAddressNotUpdated
      );
      handleError({ error, history, message });
    } finally {
      setIsLoading(false);
    }
  };

  const buttonWidth = {
    xxl: '190px',
    xl: '170px',
    default: '140px'
  };

  return (
    <Modal
      show={show}
      close={close}
      dataQa="order-walletAddress-modal"
    >
      <div className="flex flex-col px-16 py-20 md:p-24">
        <AnimateHeight
          duration={750}
          height={isLoading ? "auto" : 0}
        >
          <LoadingSpinner classes="m-auto" />
        </AnimateHeight>
        <AnimateHeight
          className="w-full"
          duration={750}
          height={!isLoading ? "auto" : 0}
        >
          <Text
            textStyle="h2"
            dataQa="order-walletAddress-modal-title"
            className="mb-16 xxl:mb-24"
            uppercase
          >
            {i18nextTranslate(i18nextKeys.orderWalletAddressEdit)}
          </Text>
          <Text
            dataQa="order-walletAddress-modal-description"
            className="inline"
          >
            {i18nextTranslate(i18nextKeys.orderWalletAddressCurrent)}{' '}
          </Text>
          <Text
            textStyle="p2"
            className="inline"
            dataQa="order-walletAddress-modal-currentWallet"
          >
            {order.TargetEthAddress || '-'}
          </Text>
          <FinalForm
            validate={walletAddressModalValidator}
            onSubmit={updateWalletAddress}
            render={({ handleSubmit, form, invalid, values }) => {
              formRef = form;
              return (
                <>
                  <FinalField
                    className="mt-24 mb-8 md:mb-16"
                    name="newWalletAddress"
                    label={i18nextTranslate(i18nextKeys.orderWalletAddressNew)}
                    render={(props) => (
                      <FormField
                        dataQa="order-walletAddress-modal-newAddressInput"
                        style={{
                          width: xxl ? "500px" : md ? "404px" : "100%"
                        }}
                        {...props}
                      />
                    )}
                    required
                  />
                  <div className="flex justify-between gap-16">
                    <Button
                      dataQa="admin-walletAddressModal-closeButton"
                      text={i18nextTranslate(i18nextKeys.buttonClose)}
                      onClick={close}
                      width={buttonWidth}
                      secondary
                    />
                    <Button
                      dataQa="admin-walletAddressModal-replaceButton"
                      text={i18nextTranslate(i18nextKeys.buttonReplace)}
                      onClick={handleSubmit}
                      width={buttonWidth}
                      disabled={invalid || order.TargetEthAddress === values.newWalletAddress}
                    />
                  </div>
                </>
              );
            }}
          />
        </AnimateHeight>
      </div>
    </Modal>
  );
};

export default WalletAddressModal;
