import React, { useMemo, useState, useCallback, useContext } from 'react';
import { useFormatNumber } from 'Utils/formatNumber';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'States/auth/authState';
import { LangContext } from 'States/lang/langState';
import { getRedeemTableColumns } from 'Helpers/redemption';
import handleError from 'Helpers/handleError';
import Table from 'Components/shared/Table/Table';
import { getOdataOrderByString } from 'Utils/utils';
import api from 'Api';
import Fade from 'Components/shared/Fade';
import Header from 'Components/shared/Header/Header';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';

const RedeemHistory = () => {
  const formatNumber = useFormatNumber();
  const PAGE_SIZE = 25;
  const {
    user: {
      profile: { sub },
    },
  } = useContext(AuthContext);
  const { lang } = useContext(LangContext);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [redemptionRequests, setRedemptionRequests] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const fetchData = useCallback((pageIndex, pageSize, [orderBy], _filters) => {
    const fetchId = ++fetchIdRef.current;
    setIsLoading(true);

    (async () => {
      try {
        // Abort if this is not the latest fetch
        if (fetchId !== fetchIdRef.current) {
          return;
        }

        const res = await api.Redemptions.list({
          orderBy: orderBy && getOdataOrderByString(orderBy),
          top: pageSize,
          skip: pageSize * pageIndex,
          filter: `UserId eq ${sub}`,
        });

        const { value } = res;
        const totalCount = res['@odata.count'];

        setPageCount(Math.ceil(totalCount / PAGE_SIZE));
        setRedemptionRequests(value);
        setIsLoading(false);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorRedeemHistoryNoRequiredData
        );
        handleError({ error, history, message });
      }
    })();
  });

  const columns = useMemo(
    () => getRedeemTableColumns(formatNumber),
    [lang]
  );

  const toAccountView = () => {
    history.push(ROUTES.account);
  };

  const toRedemptionRequestDetails = ({ original: { Id } }) => {
    history.push(`${ROUTES.redemptions}/${Id}`);
  };

  return (
    <div data-qa="redeem-history" className="select-none">
      <Fade show={true}>
        <Header
          text={i18nextTranslate(i18nextKeys.redeemHistoryHeader)}
          onArrowBackClick={toAccountView}
          arrowBack
        />
        <div className="w-full flex flex-col items-center">
          <Table
            dataQa="redeem-history"
            defaultSortBy="ReceivedOn"
            defaultPageSize={PAGE_SIZE}
            columns={columns}
            pageCount={pageCount}
            fetchData={fetchData}
            isLoading={isLoading}
            data={redemptionRequests}
            getRowProps={(row) => ({
              onClick: () => toRedemptionRequestDetails(row),
            })}
          />
        </div>
      </Fade>
    </div>
  );
};
export default RedeemHistory;
