import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import { EnvContext } from 'States/env/envState';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import UserInfoModal from './UserInfoModal';
import Text from 'Components/shared/Text';
import { Tile, HorizontalRule } from 'Components/shared/Tile';
import { Button } from 'Components/shared/buttons';
import UserInformation from 'Components/UserDetails/UserInformation';
import {
  userDataChanged,
  homeAddressChanged,
  nameChanged,
} from 'Helpers/formFields';
import handleError from 'Helpers/handleError';
import api from 'Api';

const UserInfoCard = ({ user, setUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const history = useHistory();
  const { env } = useContext(EnvContext);
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const { logEvent } = useAmplitude();

  const openProfileInfoEditModal = () => {
    setShowModal(true);
  };

  const openCredentialsPage = async () => {
    try {
      logEvent("Manage credentials");
      const credentialsUrl = `${env.Endpoints.AuthApi}/manage/index?client_id=${env.AuthConfig.ClientId}`;
      window.open(credentialsUrl, '_blank', 'noopener', 'noreferrer');
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorProfileCardNoCredentials
      );
      handleError({ error, history, message });
    }
  };

  const submitUserInfoForm = async (values, form) => {
    try {
      setIsLoadingModal(true);
      const isUserDataChanged = userDataChanged(form.getFieldState);
      const isHomeAddressChanged = homeAddressChanged(form.getFieldState);
      const isNameChanged = nameChanged(form.getFieldState);

      if (isHomeAddressChanged || isNameChanged) {
        const address = {
          ...values.HomeAddress,
          Purpose: 'Account',
          UserId: user.Id,
          FirstName: values.FirstName,
          LastName: values.LastName,
          PhoneNumber: values.PhoneNumber
        };
        const updatedAddress = values.HomeAddressId
          ? await api.Addresses.update(address)
          : await api.Addresses.create(address);
        values.HomeAddressId = updatedAddress.Id;
        values.HomeAddress = updatedAddress
      }

      if (isUserDataChanged) {
        await updateUserData(values);
      }
      logEvent("Account info edited");
      onFormSubmissionDone(values);
    } catch (error) {
      setIsLoadingModal(false);
      const message = i18nextTranslate(
        i18nextKeys.errorKycDetailsCardsSubmission
      );
      handleError({ error, history, message });
    }
  };

  const onFormSubmissionDone = (values) => {
    // submission success, map values back to user
    setUser(values);
    setShowModal(false);
    setTimeout(() => setIsLoadingModal(false), 1000);
  };

  const updateUserData = (user) => {
    const {
      HomeAddress,
      HomeAddressId,
      BankAccounts,
      Wallets,
      "@odata.context": context,
      ...userPayload
    } = user;
    return api.User.updateDelta(user.Id, userPayload);
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '142px'
  };

  const ButtonGroup = (
    <div
      className="w-full flex-wrap md:w-auto flex justify-start gap-12"
      style={{ height: "min-content" }}
    >
      <Button
        dataQa="account-credentialsButton"
        text={i18nextTranslate(i18nextKeys.buttonManageCredentials)}
        onClick={openCredentialsPage}
        width={buttonWidth}
        outlined
      />
      <Button
        dataQa="account-userInfoButton"
        text={i18nextTranslate(i18nextKeys.cardsUserEditPersonalInfo)}
        onClick={openProfileInfoEditModal}
        width={buttonWidth}
        outlined
      />
    </div>
  );

  return (
    <>
      <Tile
        title={!md ? `${user.FirstName} ${user.LastName}` : null}
        alwaysVisibleContent={
          !md
            ? <Text dataQa="account-user-email">
                {user.EmailAddress}
              </Text>
            : null
        }
        dataQa="account-user"
        expandable
        defaultExpanded
      >
        <div className="flex flex-col md:flex-row md:justify-between">
          {md && (
            <div className="flex flex-col md:gap-2">
              <Text
                dataQa="account-user-name"
                textStyle="h2"
                uppercase
              >
                {user.FirstName} {user.LastName}
              </Text>
              <Text dataQa="account-user-email">
                {user.EmailAddress}
              </Text>
            </div>
          )}
          {ButtonGroup}
        </div>
        <HorizontalRule className="my-20 md:mt-15 md:mb-24" />
        <UserInformation
          user={user}
          singleColumn={!md}
        />
      </Tile>
      <UserInfoModal
        show={showModal}
        close={() => setShowModal(false)}
        user={user}
        isLoading={isLoadingModal}
        onUserInfoFormSubmit={submitUserInfoForm}
      />
    </>
  );
};

export default UserInfoCard;
