import React from 'react';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { Tile, TileCardContent } from 'Components/shared/Tile';

const AddressInformation = ({ title, address, dataQa }) => {
  const addressData = [{
    items: [
      {
        name: i18nextTranslate(i18nextKeys.commonFirstName),
        value: address.FirstName,
        dataQa: `${dataQa}-firstName`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonLastName),
        value: address.LastName,
        dataQa: `${dataQa}-lastName`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressStreet),
        value: address.AddressLine,
        dataQa: `${dataQa}-street`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressZip),
        value: address.ZipCode,
        dataQa: `${dataQa}-zip`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressCity),
        value: address.City,
        dataQa: `${dataQa}-city`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressRegion),
        value: address.State,
        dataQa: `${dataQa}-region`
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressCountry),
        value: address.Country,
        dataQa: `${dataQa}-country`
      }
    ]
  }];

  return (
    <Tile
      title={title}
      expandable
    >
      <TileCardContent data={addressData} />
    </Tile>
  );
};

export default AddressInformation;
