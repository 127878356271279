import React, { useCallback, useContext, useMemo, useState } from "react";
import { Amplitude, useAmplitude } from "react-amplitude-hooks";
import handleError from "Helpers/handleError";
import { useFormatNumber } from "Utils/formatNumber";
import { getOrderTableColumns } from "Helpers/order";
import { generatePath, useHistory } from "react-router-dom";
import Header from "Components/shared/Header";
import Table from "Components/shared/Table/Table";
import api from "Api";
import Fade from "Components/shared/Fade";
import fileSaver from "Utils/fileSaver";
import { getOdataOrderByString } from "Utils/utils";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { ROUTES } from "Router/Routes";
import GlobalFilter from "Components/shared/Table/GlobalFilter";
import { Button } from "Components/shared/buttons";
import { LangContext } from 'States/lang/langState';
import { UiContext } from "States/ui/uiState";

const PurchaseManagementOverview = () => {
  const formatNumber = useFormatNumber();
  const PAGE_SIZE = 25;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const fetchIdRef = React.useRef(0);

  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);
  const { logEvent } = useAmplitude();

  const fetchData = useCallback((pageIndex, pageSize, [orderBy], _filters) => {
    const fetchId = ++fetchIdRef.current;
    setIsLoading(true);

    (async () => {
      try {
        // Abort if this is not the latest fetch
        if (fetchId !== fetchIdRef.current) {
          return;
        }
        const globalFilterOdata = `(contains(ReferenceCode, '${globalFilter}') ` +
          `or contains(concat(concat(User/FirstName, ' '), User/LastName), '${globalFilter}'))`;

        const res = await api.PurchaseOrder.list({
          orderBy: orderBy && getOdataOrderByString(orderBy),
          top: pageSize,
          skip: pageSize * pageIndex,
          filter: globalFilter && globalFilterOdata
        });
        const { value } = res;
        const totalCount = res["@odata.count"];

        setPageCount(Math.ceil(totalCount / PAGE_SIZE));
        setPurchaseOrders(value);
        setIsLoading(false);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorPurchaseManagementOverviewLoadingError
        );
        handleError({ error, history, message });
      }
    })();
  });

  const columns = useMemo(
    () => getOrderTableColumns(formatNumber, xxl),
    [lang]
  );

  const toPurchaseOrderDetails = ({ original: { Id } }) => {
    history.push(generatePath(ROUTES.admin.orderDetails, { id: Id }));
  };

  const exportPurchases = async () => {
    try {
      const data = await api.PurchaseOrder.export();
      await fileSaver(data, "purchases-export.xlsx");
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorPurchaseManagementOverviewExportRecordsFail
      );
      handleError({ error, history, message });
    }
  };

  const search = (filter) => {
    if (filter) {
      logEvent("Order search", {
        parameter: filter
      });
    }
    setGlobalFilter(filter);
  };

  const buttonWidth = {
    xxl: "190px",
    md: "170px",
    default: "100%"
  };

  return (
    <div
      data-qa="admin-purchase"
      className="select-none"
    >
      <Fade show={true}>
        <Header
          text={i18nextTranslate(i18nextKeys.headerOrderManagement)}
        >
          <Amplitude>
            {({ instrument }) => (
              <div className="flex flex-col md:flex-row gap-10 md:gap-16">
                <Button
                  dataQa="admin-purchase-exportButton"
                  onClick={instrument("Export orders", exportPurchases)}
                  text={i18nextTranslate(i18nextKeys.buttonExport)}
                  width={buttonWidth}
                />
                <GlobalFilter
                  globalFilter={globalFilter}
                  setGlobalFilter={search}
                  placeholder={i18nextTranslate(i18nextKeys.commonSearchCodeUser)}
                  width={!md ? "100%" : null}
                />
              </div>
            )}
          </Amplitude>
        </Header>
        <div className="w-full flex flex-col items-center">
          <Table
            dataQa="admin-purchase"
            defaultSortBy="ReceivedOn"
            defaultPageSize={PAGE_SIZE}
            columns={columns}
            pageCount={pageCount}
            fetchData={fetchData}
            isLoading={isLoading}
            data={purchaseOrders}
            outerGlobalFilter={globalFilter}
            getRowProps={(row) => ({
              onClick: () => toPurchaseOrderDetails(row)
            })}
          />
        </div>
      </Fade>
    </div>
  );
};

export default PurchaseManagementOverview;
