import React, { useContext } from 'react';
import DefaultCardKycButtonGroup from 'Components/shared/cards/DefaultCardKycButtonGroup';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ConfigContext } from 'States/config/configState';

const RedeemMethodSelectionButtonGroup = ({
  redemptionMethod,
  openModal,
  goToForm,
}) => {
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);

  const requiredTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.redemption || 0,
    redemptionMethod.kycTier || 0
  );

  return (
    <DefaultCardKycButtonGroup
      buttonConfig={{
        topButton: {
          show: true,
          label: {
            insufficientTier: i18nextKeys.buttonMoreInfo,
            sufficientTier: i18nextKeys.buttonMoreInfo,
          },
          onClick: () => openModal(redemptionMethod),
        },
        bottomButton: {
          show: true,
          label: {
            insufficientTier: i18nextKeys.kycGetTierToSelect,
            sufficientTier: i18nextKeys.buttonSelectThisMethod,
          },
          onClick: () => goToForm(redemptionMethod),
          origin: "redemption method tile"
        }
      }}
      requiredTier={requiredTier}
    />
  );
};

export default RedeemMethodSelectionButtonGroup;
