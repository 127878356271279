import React, { useContext } from "react";
import { Field as FinalField } from 'react-final-form';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { isMaxLengthExceeded } from "Utils/validators";
import { ConfigContext } from 'States/config/configState';
import { UiContext } from "States/ui/uiState";
import { FormField } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { Tile } from "Components/shared/Tile";

const AdditionalDetails = ({ initialValue }) => {
  const {
    config: {
      redeemSettings : {
        additionalInfoRedemptionRequest
      }
    }
  } = useContext(ConfigContext);

  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  const fieldHeight = xxl ? "119px" : "84px";

  return additionalInfoRedemptionRequest.enabled ? (
    <Tile
      title={i18nextTranslate(i18nextKeys.redemptionAdditionalDetailsTitle)}
      xlPadding="xl:p-32"
      xxlPadding="xxl:p-32"
      expandable
    >
      <div
        className="flex flex-col gap-24"
        style={{ width: md ? '45%' : '100%' }}
      >
        <Text textStyle="p4">
          {additionalInfoRedemptionRequest.description}
        </Text>
        <FinalField
          name="AdditionalDetails"
          validate={isMaxLengthExceeded(350)}
          initialValue={initialValue}
        >
          {(props) => (
            <FormField
              label={additionalInfoRedemptionRequest.name}
              height={fieldHeight}
              showNumberOfChars
              multiline
              {...props}
            />
          )}
        </FinalField>
      </div>
    </Tile>
  ) : null;
};

export default AdditionalDetails;
