import React, { useEffect, useContext, useRef, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { ROUTES } from 'Router/Routes';
import { AuthContext } from '../../States/auth/authState';
import Header from '../shared/Header/Header';
import { Button } from 'Components/shared/buttons';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import Urls from '../../Utils/urls';
import axios from 'axios';
import handleError from 'Helpers/handleError';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import usePostLoginRedirect from 'Hooks/usePostLoginRedirect';
import WLM_AUTH_STATE from 'Enums/WlmAuthState';
import { useAmplitude } from 'react-amplitude-hooks';
const instance = axios.create();
instance.defaults.headers.common = {};

const TermsConditions = ({ readOnly = false }) => {
  const [loadingTC, setLoadingTC] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [loadingRefCodeSettings, setLoadingRefCodeSettings] = useState(true);
  const [refCodeSetting, setRefCodeSetting] = useState({});
  const [refCode, setRefCode] = useState('');
  let history = useHistory();
  const unblockHistory = useRef(null);
  const { user, setAuthenticated } = useContext(AuthContext);
  const postLoginRedirect = usePostLoginRedirect();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const loadTermsAndConditions = async () => {
      try {
        const { data } = await instance.get(`${Urls.get().blob}/tos`);
        setTermsAndConditions(data);
        setLoadingTC(false);
      } catch (error) {
        handleError({ error, history });
      }
    };
    loadTermsAndConditions();
  }, []);

  useEffect(() => {
    const getRefCodeSetting = async () => {
      try {
        const {
          data: { referralCode },
        } = await axios.get(`${Urls.get().clApi}/registrationtemplates/active`);
        setRefCodeSetting(referralCode);
        setLoadingRefCodeSettings(false);
      } catch (error) {
        handleError({ error, history });
      }
    };
    getRefCodeSetting();
  }, []);

  useEffect(() => {
    const blockHistory = () => {
      unblockHistory.current = history.block(({ pathname }) => {
        if (matchPath(pathname, ROUTES.logout)) {
          unblockHistory.current();
        } else {
          return false;
        }
      });
    };

    blockHistory();
  }, []); // eslint-disable-line

  const acceptTermsAndConditions = async () => {
    setLoadingTC(true);
    logEvent('Registration initiated', {
      "referral needed": !!refCodeSetting?.isMandatory,
      "referral provided": refCode.length ? "entered" : "blank"
    });
    const payload = {
      isDataPolicyAccepted: true,
    };
    if (refCode) {
      payload.referralCode = refCode;
    }
    try {
      const { status } = await axios.post(
        `${Urls.get().clApi}/auth/signup`,
        payload
      );
      if (status === 200) {
        // we have to refetch signup information after accepting TC to check if a user has admin confirmation
        const {
          data: { isNewUser, state },
        } = await axios.post(`${Urls.get().clApi}/auth/signin-oidc`, {});
        user.isNewUser = isNewUser; // TODO: check if we can remove this flag
        user.isConfirmedByAdmin =
          state?.stateId == WLM_AUTH_STATE.CONFIRMED_BY_ADMIN;
        user.isAdminSet = true; // TODO: revisit signup flow and get rid of this flag
        setAuthenticated(true, user);

        unblockHistory.current();
        if (!user.profile.email_verified) {
          // TODO: maybe replace history instead of push
          history.push(ROUTES.confirmEmail);
        } else {
          postLoginRedirect();
        }
      }
    } catch (error) {
      setLoadingTC(false);
      const message = i18nextTranslate(i18nextKeys.errorTcAccept);
      handleError({ error, history, message });
    }
  };

  const declineTermsAndConditions = () => {
    unblockHistory.current();
    logEvent('Logout', {
      screen: ROUTES.terms.split("/")[1]
    });
    history.push(ROUTES.logout);
  };

  const updateRefCode = ({ target }) => {
    setRefCode(target.value);
  };

  const loading = loadingTC || loadingRefCodeSettings;

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <div>
      <Header text={i18nextTranslate(i18nextKeys.tcHeader)} />
      <h2 className="color-8 text-xl font-bold mb-24 text-center">
        {i18nextTranslateDynamically(i18nextKeys.tcInstruction, {
          andAccept: !readOnly
            ? i18nextTranslate(i18nextKeys.tcInstructionAndAccept)
            : '',
        })}
      </h2>

      {loading ? (
        <LoadingSpinner classes="m-auto" />
      ) : (
        <div
          style={{ maxWidth: '990px' }}
          className="color-8 m-auto"
        >
          <p
            style={{ maxHeight: '68vh', overflowY: 'auto' }}
            className="whitespace-pre-line"
          >
            {termsAndConditions}
          </p>

          {!readOnly && (
            <>
              {refCodeSetting?.isActive && (
                <p
                  className="px-24 py-16 mt-24 bg-4"
                  data-qa="tc-refCodeLabel"
                >
                  {i18nextTranslate(i18nextKeys.tcInstructionReferralCode)}
                  {refCodeSetting?.isMandatory && '*'}:{' '}
                  <input
                    className="color-10 inputField"
                    type="text"
                    onChange={updateRefCode}
                    value={refCode}
                  />
                </p>
              )}
              <div className="flex justify-between mt-24">
                <Button
                  dataQa="tc-declineButton"
                  text={i18nextTranslate(i18nextKeys.buttonDecline)}
                  onClick={declineTermsAndConditions}
                  width={buttonWidth}
                  secondary
                />

                <Button
                  dataQa="tc-acceptButton"
                  text={i18nextTranslate(i18nextKeys.buttonAccept)}
                  onClick={acceptTermsAndConditions}
                  width={buttonWidth}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TermsConditions;
