import React, { useContext, useState } from "react";
import AnimateHeight from "react-animate-height";
import { UiContext } from "States/ui/uiState";
import { DIRECTION } from 'Helpers/icons';
import { SingleChevron, TrashCanSymbol } from "Components/shared/symbols";
import Text from "Components/shared/Text";

const Accordion = ({
  children,
  text,
  fieldWidth,
  dataQa = "accordion",
  removeAccordion = () => {},
}) => {
  const [expanded, setExpanded] = useState(true);
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        className={`flex justify-between items-center ${
          expanded ? 'bg-3--5' : 'bg-3--2'
        } rounded border border-solid border-color-5 px-10 py-8`}
        style={{ width: fieldWidth }}
        data-qa={dataQa}
      >
        <div className="flex gap-10 items-center">
          <SingleChevron
            size={xxl ? '16' : '14'}
            direction={
              expanded
                ? DIRECTION.up
                : DIRECTION.down}
            className="cursor-pointer"
            onClick={toggleExpanded}
            data-qa={`${dataQa}-toggle`}
          />
          <Text dataQa={`${dataQa}-text`}>{text}</Text>
        </div>
        <TrashCanSymbol
          size={xxl ? '16' : '14'}
          className="color-6 cursor-pointer"
          onClick={() => removeAccordion(text)}
          data-qa={`${dataQa}-remove`}
        />
      </div>
      <AnimateHeight
        duration={750}
        height={expanded ? 'auto' : 0}
      >
        <div className="mt-16 xxl:mt-20 mb-16">
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default Accordion;
