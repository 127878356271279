import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import Text from 'Components/shared/Text';
import Stamp from 'Components/shared/Stamp';
import { Tile } from 'Components/shared/Tile';
import handleError from 'Helpers/handleError';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { Button } from 'Components/shared/buttons';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import WalletInformation from './WalletInformation';
import WalletFormModal from 'Components/CryptoWallet/WalletFormModal';

const WalletsCard = ({
  wallets: walletsProp,
  fetchUserWallets = () => {}
}) => {
  const [loading, setLoading] = useState(true);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [currentWallet, setCurrentWallet] = useState({});
  const history = useHistory();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (walletsProp) {
      setWallets(walletsProp);
      setLoading(false);
    }
  }, [walletsProp]);

  const onEditClick = (wallet) => {
    setCurrentWallet(wallet);
    setShowWalletModal(true);
  };

  const onDeleteIconClick = (wallet) => {
    setCurrentWallet(wallet);
    setShowConfirmationModal(true);
  };

  const onWalletFormSubmit = async () => {
    try {
      logEvent(
        currentWallet.Id
          ? "Wallet edited"
          : "Wallet added"
      );
      setLoading(true);
      fetchUserWallets();
      setShowWalletModal(false);
      setCurrentWallet({});
      setLoading(false);
    } catch (error) {
      handleError({ error, history });
    }
  };

  const deleteWallet = async (walletId) => {
    try {
      setLoading(true);
      setShowConfirmationModal(false);
      await api.WalletAddresses.delete(walletId);
      setWallets((wallets) =>
        wallets.filter((wallet) => wallet.Id !== walletId)
      );
      setCurrentWallet({});
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorWalletCardDeleteWalletCouldNotDelete
      );
      handleError({ error, history, message });
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  const buttonWidth = {
    xxl: '190px',
    default: '170px'
  };

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.accountWalletsHeading)}
      dataQa="account-wallets"
      expandable={!!wallets.length}
    >
      <div className="items-start w-full">
        <div className="flex flex-col gap-12">
          {wallets.map((wallet, index) => (
            <div key={index} className="flex items-center w-full">
              <Stamp
                onEditClick={() => onEditClick(wallet)}
                onDeleteClick={() => onDeleteIconClick(wallet)}
                badge={`${wallet.Type || ''}`}
                upperText={wallet.Name}
                lowerText={wallet.Address}
                key={index}
                pointer={true}
                style={{
                  flexGrow: 1,
                }}
                editable
              >
                <WalletInformation wallet={wallet} />
              </Stamp>
            </div>
          ))}
        </div>


        {wallets.length ? (
          <Button
            text={i18nextTranslate(i18nextKeys.buttonAddWallet)}
            onClick={() => onEditClick({})}
            className="mt-24 md:mt-32"
            width={buttonWidth}
          />
        ) : (
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <Text className="text-sm mb-24 md:mb-0">
              {i18nextTranslate(i18nextKeys.cardsWalletTypeNoWalletsStored)}
            </Text>
            <Button
              text={i18nextTranslate(i18nextKeys.buttonAddWallet)}
              onClick={() => onEditClick({})}
              width={buttonWidth}
            />
          </div>
        )}
      </div>

      {showWalletModal && (
        <WalletFormModal
          show={showWalletModal}
          close={() => setShowWalletModal(false)}
          isLoading={loading}
          onWalletFormSubmit={onWalletFormSubmit}
          wallet={currentWallet}
        />
      )}

      <ConfirmationModal
        show={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
        onConfirm={() => deleteWallet(currentWallet.Id)}
        confirmButtonText={i18nextTranslate(i18nextKeys.buttonDelete)}
        title={i18nextTranslate(i18nextKeys.accountWalletsDeletionHeading)}
        description={
          <Trans i18nKey={i18nextKeys.accountWalletsDeletionText}>
            <Text
              textStyle="p2"
              color="color-4"
              className="inline"
            >
              {{ wallet: currentWallet.Name }}
            </Text>
          </Trans>
        }
        dataQa="wallet-delete-confirmation-modal"
      />
    </Tile>
  );
};

export default WalletsCard;
