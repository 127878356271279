import { toast } from 'react-toastify';

const infoNotification = (message = 'Success!') => {
  toast.success(message, {
    closeOnClick: true,
    className: 'bg-4',
  });
};

export default infoNotification;
