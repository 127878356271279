import {
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';
import {
  isArray,
  isBoolean,
  isHexString,
  isNonNegativeInteger,
  isPositiveNumber,
  isRequiredIfWithMessage,
  isRequiredNumberOrNumericString,
  isRequiredString,
  isRequiredStringIf,
  isString,
  isValueUnique,
  hasLengthGreaterThanIf
} from 'Utils/validators';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';


const featuredAssetValidator = (
  isFeaturedAssetEnabled,
  restrictedMode
) => createValidator(
  () => (assets) => {
    if (!isFeaturedAssetEnabled) {
      return;
    }
    let featuredCount = 0;
    for (const asset of assets) {
      if (typeof asset.isFeaturedAsset === "boolean"
        && asset.isFeaturedAsset) {
        featuredCount += 1;
        if (!restrictedMode && featuredCount > 1) {
          return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetSingleAsset);
        }
      }
    }
    if (!featuredCount) {
      return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetRequired);
    } 
    return;
  },
  () => {}
);

const customPurchaseSettingsValidator = (isFeaturedAssetEnabled) => {
  return (field, values) =>
    combineValidators({
      sidebarLink: isRequiredStringIf(
        'sidebarLink',
        isFeaturedAssetEnabled
      ),
      customHtmlSourcePath: isRequiredStringIf(
        'customHtmlSourcePath',
        isFeaturedAssetEnabled
      ),
      customHtmlSourcePathLoggedIn: isString(
        'customHtmlSourcePathLoggedIn'
      )
    })(values);
}

export const assetValidator = (isFeaturedAssetEnabled) => {
  return (asset) => {

    return combineValidators({
      kycTier: isNonNegativeInteger('kycTier'),
      icon: isRequiredString('icon'),
      issuerAddress: isHexString('issuerAddress'),
      uniqueAssetId: isHexString('uniqueAssetId'),
      maxAmount: isPositiveNumber('maxAmount'),
      price: isRequiredNumberOrNumericString('price'),
      isFeaturedAsset: isBoolean('isFeaturedAsset'),
      customPurchaseSettings: composeValidators(
        isRequiredIfWithMessage(
          (values) => { return values.isFeaturedAsset },
          'customPurchaseSettings'
        ),
        asset.isFeaturedAsset || asset.customPurchaseSettings
          ? customPurchaseSettingsValidator(
              () => isFeaturedAssetEnabled && asset.isFeaturedAsset
            )
          : () => {}
      )('customPurchaseSettings')
    })(asset);
  }
}

const assetArrayValidator = (isFeaturedAssetEnabled) => {
  return (field, assets) =>
    assets.map(assetValidator(isFeaturedAssetEnabled));
}

const assetsValidator = (
  field,
  isFeaturedAssetEnabled,
  isPurchaseEnabled,
  restrictedMode
) =>
  composeValidators(
    isArray(field),
    hasLengthGreaterThanIf(
      0, isPurchaseEnabled, i18nextKeys.validatorAssetsRequired
    )('assets'),
    featuredAssetValidator(isFeaturedAssetEnabled, restrictedMode),
    isValueUnique(
      i18nextTranslate(i18nextKeys.validatorAssetsUniqueAssetIds),
      'uniqueAssetId'
    ),
    assetArrayValidator(isFeaturedAssetEnabled)
  )({ field });

export default assetsValidator;
