import {
  SET_AUTHENTICATED,
  AUTH_ERROR,
  SET_LOADING,
  SET_ADMIN,
} from './authTypes';
import i18nextTranslate from '../../Lang/i18nextTranslate';
import { i18nextKeys } from '../../Lang/i18nextKeys';

const authReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case SET_AUTHENTICATED: {
      const isAuthenticated = action.payload.isAuthenticated;
      const user = action.payload.user;
      return {
        ...state,
        isAuthenticated,
        user,
        loading: false,
        isAdminSet: !isAuthenticated || !!user?.isAdminSet || user.isNewUser,
      };
    }

    case SET_ADMIN: {
      return {
        ...state,
        isAdmin: action.payload,
        isAdminSet: true
      };
    }

    case AUTH_ERROR: {
      return {
        ...state,
        error: i18nextTranslate(i18nextKeys.errorStatesAuthAuthError),
        loading: false,
        user: null,
        isAuthenticated: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
  }
};

export default authReducer;
