import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useField } from 'react-final-form';
import { composeValidators, isRequired } from 'revalidate';
import Big from 'big.js';
import api from 'Api';
import handleError from "Helpers/handleError";
import { DIRECTION } from 'Helpers/icons';
import { SCALE } from 'Enums/RedemptionMethodTypes';
import useOnScreen from 'Hooks/useOnScreen';
import { UiContext } from 'States/ui/uiState';
import { Tile } from 'Components/shared/Tile';
import Text from 'Components/shared/Text';
import { Error } from 'Components/shared/formElements';
import { NumberInput } from 'Components/shared/formElements';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { Arrow } from 'Components/shared/symbols';
import Warning from 'Components/shared/Warning';
import { useFormatNumber } from 'Utils/formatNumber';
import { isBetween } from 'Utils/validators';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import FeeBlock from './FeeBlock';

export const REDEMPTION_AMOUNT_FIELD = {
  pay: 'amountToPay',
  receive: 'amountToReceive'
};

const amountValidator = (min = "0", max = "0", uom) => {
  const limitsMessage = `${i18nextTranslate(i18nextKeys.commonErrorInvalidQuantity)
    }: ${i18nextTranslateDynamically(
      i18nextKeys.redemptionPayReceiveLimits,
      { min, max, uom }
    )
    }`;
  return composeValidators(
    isRequired,
    isBetween(min, max, limitsMessage),
  )("field");
};

const PayReceive = ({
  activeField,
  redemptionMethod,
  beneficiaryCountry,
  amounts,
  getAmounts,
  setAmounts,
  loading = false,
  disabled = false
}) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [roundingData, setRoundingData] = useState({});
  const [limits, setLimits] = useState(null);
  const [showCountryChangeWarning, setShowCountryChangeWarning] = useState(false);

  const history = useHistory();
  const tileRef = useRef(null);
  const tileVisible = useOnScreen(tileRef);
  const formatNumber = useFormatNumber();

  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const {
    userGets: {
      uom: payoutUnits,
      name: payoutAssetName
    },
    userPays: {
      UnitOfMeasureCode: redeemUnits,
      CurrencyCode: redeemCurrency,
      Name: redeemAssetName
    }
  } = redemptionMethod;

  useEffect(() => {
    const updateAmountFields = () => {
      if (amounts.length > 1) {
        Big.NE = -limits.payoutAmountScale - 1;
        if (amounts[0].updatedFieldName === REDEMPTION_AMOUNT_FIELD.pay) {
          setRoundingData({
            roundingDifference:
              Big(payAmountField.input.value)
                .minus(amounts[0].GrossAmount.AssetUnitsString)
                .toString(),
            roundedValue: amounts[0].GrossAmount.AssetUnitsString,
            uom: redeemUnits || redeemCurrency,
            fieldName: i18nextTranslate(i18nextKeys.redemptionPayReceiveYouPay)
          });
        } else {
          setRoundingData({
            roundingDifference:
              Big(receiveAmountField.input.value)
                .minus(amounts[0].NetAmount.PayoutUnitsString)
                .toString(),
            roundedValue: amounts[0].NetAmount.PayoutUnitsString,
            uom: payoutUnits,
            fieldName: i18nextTranslate(i18nextKeys.redemptionPayReceiveYouReceive)
          });
        }
      }
      payAmountField.input.onChange(amounts[0].GrossAmount.AssetUnitsString);
      receiveAmountField.input.onChange(amounts[0].NetAmount.PayoutUnitsString);
    };
    if (amounts.length && limits) {
      updateAmountFields();
    } else if (limits?.MinPayAmount) {
      getAmounts({
        [REDEMPTION_AMOUNT_FIELD.pay]: limits.MinPayAmount,
        updatedFieldName: payAmountField.input.name
      });
    }
  }, [amounts, limits]);

  useEffect(() => {
    const calculateLimits = async () => {
      try {
        const calculatedLimits = await api.Redemptions.getLimits({
          methodId: redemptionMethod.id,
          country: beneficiaryCountry
        });
        const updatedLimits = {
          ...calculatedLimits,
          payoutAmountScale: SCALE[redemptionMethod.type]
        };
        if (!limits) {
          setLimits(updatedLimits);
          return;
        }
        if (limits.MinPayAmount === updatedLimits.MinPayAmount
          && limits.MaxPayAmount === updatedLimits.MaxPayAmount
        ) {
          return;
        }
        setLimits(updatedLimits);
        if (payAmountField.input.value) {
          setShowCountryChangeWarning(true);
          getAmounts({
            amountToPay: payAmountField.input.value,
          });
          tileRef.current.scrollIntoView({
            behavior: "smooth"
          });
        }
      } catch (error) {
        handleError({ error, history })
      }
    }
    calculateLimits();
  }, [beneficiaryCountry]);

  useEffect(() => {
    if (!activeField) {
      return;
    }
    const otherFieldInFocus = activeField !== undefined &&
      ![
        REDEMPTION_AMOUNT_FIELD.pay,
        REDEMPTION_AMOUNT_FIELD.receive
      ].includes(activeField);
    if (otherFieldInFocus) {
      setShowCountryChangeWarning(false);
    }
  }, [activeField]);

  useEffect(() => {
    if (showCountryChangeWarning && !tileVisible) {
      setShowCountryChangeWarning(false);
    }
  }, [tileVisible]);

  const debounceAmountCalculation = (inputs) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const amountToPay = inputs[REDEMPTION_AMOUNT_FIELD.pay];
    const amountToReceive = inputs[REDEMPTION_AMOUNT_FIELD.receive];
    if (amountToPay === null || amountToReceive === null) {
      return;
    }
    const isBelowMin = amountToPay
      ? Big(amountToPay).lt(limits.MinPayAmount)
      : Big(amountToReceive).lt(limits.MinPayoutAmount);
    const isAboveMax = amountToPay
      ? Big(amountToPay).gt(limits.MaxPayAmount)
      : Big(amountToReceive).gt(limits.MaxPayoutAmount);

    if (isBelowMin || isAboveMax) {
      return;
    }
    setShowCountryChangeWarning(false);
    setDebounceTimeout(
      setTimeout(() => getAmounts(inputs), 1500)
    );
  };

  const roundUp = () => {
    setAmounts(amounts.slice(1));
  };

  const payAmountField = useField(REDEMPTION_AMOUNT_FIELD.pay, {
    initialValue: limits
      ? amounts[0]?.GrossAmount.AssetUnitsString
      : undefined,
    validate: amountValidator(
      limits?.MinPayAmount,
      limits?.MaxPayAmount,
      redeemUnits || redeemCurrency
    )
  });

  const receiveAmountField = useField(REDEMPTION_AMOUNT_FIELD.receive, {
    initialValue: limits
      ? amounts[0]?.NetAmount.PayoutUnitsString
      : undefined,
    validate: amountValidator(
      limits?.MinPayoutAmount,
      limits?.MaxPayoutAmount,
      payoutUnits
    )
  });

  const payAmountHint = `${i18nextTranslateDynamically(
    i18nextKeys.redemptionPayReceiveLimits,
    {
      min: formatNumber(limits?.MinPayAmount),
      max: formatNumber(limits?.MaxPayAmount),
      uom: redeemUnits || redeemCurrency
    }
  )
    }${Number(limits?.IncrementPayAmount)
      ? " " + i18nextTranslateDynamically(
        i18nextKeys.redemptionPayReceiveStep,
        {
          step: limits.IncrementPayAmount,
          uom: redeemUnits || redeemCurrency
        }
      )
      : ""
    }`;

  const receiveAmountHint = `${i18nextTranslateDynamically(
    i18nextKeys.redemptionPayReceiveLimits,
    {
      min: formatNumber(limits?.MinPayoutAmount),
      max: formatNumber(limits?.MaxPayoutAmount),
      uom: payoutUnits
    }
  )
    }${Number(limits?.IncrementPayoutAmount)
      ? " " + i18nextTranslateDynamically(
        i18nextKeys.redemptionPayReceiveStep,
        {
          step: formatNumber(limits.IncrementPayoutAmount),
          uom: payoutUnits
        }
      )
      : ""
    }`;

  return (
    <Tile
      dataQa="amounts"
      title={i18nextTranslate(i18nextKeys.redemptionPayReceiveHeading)}
      xlPadding="xl:p-32"
      xxlPadding="xl:p-32"
      reference={tileRef}
    >
      <div className="flex flex-col gap-16 md:gap-24">
        {amounts.length > 1 &&
          !!roundingData.roundingDifference &&
          !showCountryChangeWarning && (
            <Warning
              visibleText={i18nextTranslateDynamically(
                i18nextKeys.redemptionPayReceiveRoundingWarning,
                roundingData
              )}
              expandableText={i18nextTranslate(
                i18nextKeys.redemptionPayReceiveRoundingExplanation
              )}
              buttonText={i18nextTranslate(i18nextKeys.redemptionPayReceiveRoundUp)}
              onButtonClick={roundUp}
              dataQa="amounts-warning"
            />
          )
        }
        {showCountryChangeWarning && (
          <Warning
            visibleText={i18nextTranslate(i18nextKeys.redemptionPayReceiveCountryChangeWarning)}
            dataQa="fees-warning"
          />
        )}
        <span className="flex flex-col md:flex-row justify-between gap-16 md:gap-8 w-full">
          <div
            className="flex flex-col gap-4"
            style={{ width: md ? '45%' : '100%' }}
            data-qa="amounts-pay"
          >
            <Text
              textStyle="h3"
              className="truncate"
            >
              <label data-qa="amounts-pay-label">
                {
                  `${i18nextTranslate(i18nextKeys.redemptionPayReceiveYouPay)
                  }${redeemAssetName ? ` ${redeemAssetName},` : ""
                  } ${redeemUnits || redeemCurrency}`
                }
              </label>
            </Text>
            <NumberInput
              name={payAmountField.input.name}
              value={payAmountField.input.value}
              step={limits?.IncrementPayAmount}
              min={limits?.MinPayAmount}
              max={limits?.MaxPayAmount}
              onChange={(value) => {
                payAmountField.input.onChange(value);
                debounceAmountCalculation({
                  [REDEMPTION_AMOUNT_FIELD.pay]: value,
                  updatedFieldName: payAmountField.input.name
                });
              }}
              dataQa="amounts-pay"
              invalid={payAmountField.meta.invalid}
              touched={!!payAmountField.input.value}
              disabled={disabled || loading}
              innerLabelText={redeemUnits || redeemCurrency}
              showInnerLabel={!!amounts.length}
              decimal
            />
            {payAmountField.input.value && payAmountField.meta.error ? (
              <Error
                error={payAmountField.meta.error}
                dataQa="amounts-pay-input-error"
                className=""
              />
            ) : (
              <Text
                textStyle="h3"
                color="color-4"
                dataQa="amounts-pay-limits"
              >
                {payAmountHint}
              </Text>
            )}
          </div>
          {!md && !!amounts.length && (
            <FeeBlock
              calculation={amounts[0]}
              redeemUnits={redeemUnits || redeemCurrency}
              payoutUnits={payoutUnits}
              className="w-full"
            />
          )}
          {loading ? (
            <div className="h-20 w-20 self-center">
              <LoadingSpinner
                size="h-20 w-20"
                dataQa="amounts-loading"
              />
            </div>
          ) : (
            <Arrow
              size="20"
              className="color-8 self-center"
              direction={md ? DIRECTION.right : DIRECTION.down}
              data-qa="amounts-arrow"
            />
          )}
          <div
            className="flex flex-col gap-4"
            style={{ width: md ? '45%' : '100%' }}
            data-qa="amounts-receive"
          >
            <Text
              textStyle="h3"
              className="truncate"
            >
              <label data-qa="amounts-receive-label">
                {
                  `${i18nextTranslate(i18nextKeys.redemptionPayReceiveYouReceive)
                  }${payoutAssetName ? ` ${payoutAssetName},` : ""
                  } ${payoutUnits}`
                }
              </label>
            </Text>
            <NumberInput
              name={receiveAmountField.input.name}
              value={receiveAmountField.input.value}
              step={limits?.IncrementPayoutAmount}
              min={limits?.MinPayoutAmount}
              max={limits?.MaxPayoutAmount}
              scale={limits?.payoutAmountScale}
              onChange={(value) => {
                receiveAmountField.input.onChange(value);
                debounceAmountCalculation({
                  [REDEMPTION_AMOUNT_FIELD.receive]: value,
                  updatedFieldName: receiveAmountField.input.name
                });
              }}
              dataQa="amounts-receive"
              invalid={receiveAmountField.meta.invalid}
              touched={!!receiveAmountField.input.value}
              disabled={disabled || loading}
              innerLabelText={payoutUnits}
              showInnerLabel={!!amounts.length}
              decimal
            />
            {receiveAmountField.input.value && receiveAmountField.meta.error ? (
              <Error
                error={receiveAmountField.meta.error}
                dataQa="amounts-receive-input-error"
                className=""
              />
            ) : (
              <Text
                textStyle="h3"
                color="color-4"
                dataQa="amounts-receive-limits"
              >
                {receiveAmountHint}
              </Text>
            )}
          </div>
        </span>
        {md && !!amounts.length && (
          <FeeBlock
            calculation={amounts[0]}
            redeemUnits={redeemUnits || redeemCurrency}
            payoutUnits={payoutUnits}
            style={{ width: '45%' }}
          />
        )}
      </div>
    </Tile>
  );
};

export default PayReceive;
