import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import handleError from 'Helpers/handleError';
import { EnvContext } from 'States/env/envState';
import Fade from 'Components/shared/Fade';
import api from 'Api';
import CollectMethodEnum from 'Enums/CollectMethod';
import OrderStatusEnum from 'Enums/OrderStatus';
import PaymentMethodEnum from 'Enums/PaymentMethod';
import Cart from 'Features/Cart';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';

const CheckoutOrderSummary = () => {
  const { env } = useContext(EnvContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (
      !location?.state ||
      !location?.state?.invoiceAddress ||
      !location?.state?.collectionMethodType
    ) {
      history.replace(ROUTES.checkoutCollectionMethod);
    }
  }, []);

  const createOrder = () => {
    const collectionMethodType = location?.state?.collectionMethodType;
    const order = {
      CollectMethod: collectionMethodType,
      Status: OrderStatusEnum.values.Draft,
      PaymentMethod: PaymentMethodEnum.values.None,
      InvoiceAddress: location.state.invoiceAddress,
      TargetEthAddress: location?.state?.collectionMethodAddress,
      ...(collectionMethodType === CollectMethodEnum.values.TokenCard && {
        ShippingAddress: location.state.shippingAddress || location.state.invoiceAddress,
      }),
    };
    submitOrder(order);
  };

  const submitOrder = async (orderPayload) => {
    try {
      setLoading(true);
      const order = await api.PurchaseOrder.create(orderPayload);
      const timeouts = await api.Config.getTimeouts(env.TenantId);
      order.timeouts = timeouts.purchase;
      setLoading(false);
      logEvent("Order confirmed");
      history.push({
        pathname: ROUTES.checkoutPaymentMethod,
        state: { order }
      });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorOrderCreate);
      handleError({ error, history, message });
      setLoading(false);
    }
  };

  return (
    <Fade show>
      <div
        data-qa="checkout-orderOverview"
        className="color-8 default-table"
      >
        <Cart
          showHeader={false}
          loading={loading}
          proceedButtonAction={createOrder}
          inCheckoutProcess
        />
      </div>
    </Fade>
  );
};

export default CheckoutOrderSummary;
