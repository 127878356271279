import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { ConfigContext } from 'States/config/configState';
import Fade from 'Components/shared/Fade';
import Header from 'Components/shared/Header/Header';
import { Button, TextButton } from 'Components/shared/buttons';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { DIRECTION } from 'Helpers/icons';
import { Arrow } from 'Components/shared/symbols';

const ErrorPage = ({ location }) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const {
    config: { imprint },
  } = useContext(ConfigContext);

  const toggleErrorDetails = () => {
    setExpanded(!expanded);
  }

  const navigateBack = () => {
    history.goBack();
  };

  const navigateToHome = () => {
    history.replace('/');
  };

  const getPageContent = (errorCode, supportEmail) => {
    switch (errorCode) {
      case 401:
      case 403:
        return {
          heading: i18nextTranslate(i18nextKeys.errorPageAccessDeniedHeading),
          text: i18nextTranslateDynamically(i18nextKeys.errorPageAccessDeniedText,
            { supportEmail })
        }
      case 404:
        return {
          heading: i18nextTranslate(i18nextKeys.errorPageNotFoundHeading),
          text: i18nextTranslateDynamically(i18nextKeys.errorPageNotFoundText,
            { supportEmail })
        }
      case 500:
        return {
          heading: i18nextTranslate(i18nextKeys.errorPageServerProblemHeading),
          text: i18nextTranslateDynamically(i18nextKeys.errorPageServerProblemText,
            { supportEmail })
        }
      default:
        return {
          heading: i18nextTranslate(i18nextKeys.errorPageServiceUnavailableHeading),
          text: i18nextTranslate(i18nextKeys.errorPageServiceUnavailableText),
          showTryAgainButton: true
        }
    }
  };

  const errorCode = location.state?.errorCode || 503;
  const pageContent = getPageContent(errorCode, imprint?.email);
  const errorDetails = location.state?.errorDetails;

  return (
    <div className="color-8 h-full px-16 sm:px-0">
      <Fade show={true} className="w-full h-full flex flex-col items-center">
        <Header
          text={i18nextTranslateDynamically(i18nextKeys.errorPageHeader, { errorCode })}
          classes="w-full"
        />
        <div className="h-full flex flex-col justify-center items-center text-center mx-24 sm:mx-0 md:w-4/5 lg:w-2/3 xl:w-1/2">
          <div
            className="w-full font-errors text-center text-gradient font-bold leading-snug bg-clip-text text-transparent"
            style={{ fontSize: '160px' }}
            data-qa="errorPage-errorCode"
          >
            {errorCode}
          </div>
          <h2
            className="text-2xl font-semibold my-20"
            data-qa="errorPage-heading"
          >
            {pageContent.heading}
          </h2>
          <p
            className="font-light"
            data-qa="errorPage-text"
          >
            {pageContent.text}
          </p>

          {!!errorDetails?.length && (
            <>
              <button
                onClick={toggleErrorDetails}
                className="color-6 font-semibold underline my-16"
                data-qa="errorPage-showMore-button"
              >
                {i18nextTranslate(expanded
                  ? i18nextKeys.commonShowLess
                  : i18nextKeys.commonShowMore
                )}
              </button>
              <AnimateHeight
                duration={750}
                height={expanded ? 'auto' : 0}
              >
                {errorDetails.map((errorDetail, index) => (
                  <div
                    key={index}
                    className="font-light text-sm"
                    data-qa={`errorPage-showMore-text-${index}`}
                  >
                    {errorDetail}
                  </div>
                ))}
              </AnimateHeight>
            </>
          )}

          {pageContent.showTryAgainButton && (
            <Button
              text={i18nextTranslate(i18nextKeys.errorPageTryAgain)}
              onClick={navigateBack}
              dataQa="errorPage-tryAgainButton"
              width={{
                xxl: "190px",
                default: "170px"
              }}
              className="mt-64"
              tertiary
            />
          )}
          <TextButton
            text={i18nextTranslate(i18nextKeys.buttonHome)}
            onClick={navigateToHome}
            className={`flex-row-reverse ${
              pageContent.showTryAgainButton ? 'mt-24' : 'mt-64'
            }`}
            icon={Arrow}
            iconDirection={DIRECTION.left}
          />
        </div>
      </Fade>
    </div>
  );
};

export default ErrorPage;
