import { combineValidators } from 'revalidate';
import { isRequiredStringIf, isString } from 'Utils/validators';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import TENANT_FEATURE from 'Enums/TenantFeature';
import {
  landingPageConfigValidator,
  redeemSettingsConfigValidator,
  assetsValidator,
  imprintConfigValidator,
  kycTiersDescriptionValidator,
  kycTierFunctionalRestrictionsValidator
} from './jsonConfigValidators';

export const validate = (config, features, settings) => {
  const refCodeTemplateRequired = isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
    || isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset);
  const isKycEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.kyc);
  const isFeaturedAssetEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset);
  const isPurchaseEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.purchase);
  return combineValidators({
    websiteTitle: isString('websiteTitle'),
    refCodeTemplate: isRequiredStringIf('refCodeTemplate', () => refCodeTemplateRequired),
    logo: isString('logo'),
    landingPage: landingPageConfigValidator,
    imprint: imprintConfigValidator,
    kycTiersDescription: kycTiersDescriptionValidator('kycTiersDescription', isKycEnabled),
    kycTierFunctionalRestrictions: kycTierFunctionalRestrictionsValidator,
    redeemSettings: redeemSettingsConfigValidator,
    assets: assetsValidator(
      'assets',
      isFeaturedAssetEnabled,
      isPurchaseEnabled,
      settings.RestrictedMode
    )
  })(config);
};

export const getValidationMessages = (validationResult) => {
  const validationMessages = [];
  getArbitrarilyNestedStringsFromObject(validationMessages, validationResult);
  return validationMessages;
};

const getArbitrarilyNestedStringsFromObject = (output, obj, parentKey = '') => {
  for (let key in obj) {
    if (typeof obj[key] == 'object') {
      getArbitrarilyNestedStringsFromObject(
        output,
        obj[key],
        parentKey + key + '.'
      );
    } else if (obj[key] !== undefined) {
      if (Array.isArray(obj)) {
        output.push(parentKey + key + ' ' + obj[key]);
      } else {
        output.push(parentKey + key + " " + obj[key]);
      }
    }
  }
};
