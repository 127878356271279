import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Amplitude, LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { isValidUrl } from 'Utils/utils';
import { ROUTES } from 'Router/Routes';
import AssetCard from './AssetCard/AssetCard';
import AssetModal from './AssetCard/AssetModal';
import Header from 'Components/shared/Header';
import InfoModal from 'Components/shared/Modal/InfoModal';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import { CartContext } from 'States/cart/cartState';
import { getAssetsConfig } from 'Helpers/assets';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import handleError from 'Helpers/handleError';
import { UiContext } from 'States/ui/uiState';
import { ConfigContext } from 'States/config/configState';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import api from 'Api';
import CartButton from './CartButton';

const Purchase = () => {
  const history = useHistory();
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const { cartItems, loadCart, loadingCart } = useContext(CartContext);
  const [assets, setAssets] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(null);
  const [modalAsset, setModalAsset] = useState({});
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);
  const { isUserTierSufficient } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );
  const [timerInterval, setTimerInterval] = useState(-1);
  const [secondsOpen, setSecondsOpen] = useState(0);
  const { logEvent } = useAmplitude((inheritedProps) => ({
    ...inheritedProps,
  }));

  const onFocus = () => {
    if (timerInterval !== -1) {
      logEvent('Purchase details checked', { useful: secondsOpen >= 3 });
      clearInterval(timerInterval);
      setTimerInterval(-1);
      setSecondsOpen(0);
    }
  };

  // TODO: secondsOpen does not properly get read if eventListener isn't refreshed at every change.
  // Maybe there is a better way to send the correct seconds without reloading eventListener
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [timerInterval, secondsOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!isUserTierSufficient) history.replace(ROUTES.welcome);
      loadCart();
    }
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      try {
        const assetsConfig = await getAssetsConfig(isAuthenticated, env.TenantId);
        const { value: assetsData } = await api.Assets.getAssets(!isAuthenticated && env.TenantId);
        const assets = assetsConfig.map(assetConfig => {
          const assetData = assetsData.find(asset => asset.Id === assetConfig.uniqueAssetId);
          return { ...assetConfig, ...assetData };
        });
        setAssets(assets || []);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorPurchaseAssetLoadingError
        );
        handleError({ error, history, message });
      }
    };
    loadAssets();
  }, [history]);

  const openAssetModal = (asset) => () => {
    setModalAsset(asset);
    setShowAssetModal(true);
  };

  const openAssetInfo = (asset) => () => {
    if (isValidUrl(asset.Description)) {
      const timer = setInterval(
        () => setSecondsOpen((secondsOpen) => secondsOpen + 1),
        1000
      );
      setTimerInterval(timer);
      window.open(asset.Description, '_blank', 'noopener', 'noreferrer');
    } else {
      setModalAsset(asset);
      setShowInfoModal(true);
      logEvent('Purchase details checked');
    }
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  return (
    <Amplitude
      eventProperties={{
        scope: 'purchase',
      }}
    >
      <LogOnMount eventType="Shop opened">
        <div data-qa="purchase">
          <Header
            text={i18nextTranslate(i18nextKeys.headerShop)}
            wrapChildren={false}
          >
            {isAuthenticated && user && user.isConfirmedByAdmin && (
              <CartButton
                itemsCount={Object.keys(cartItems).length}
                isLoading={loadingCart}
              />
            )}
          </Header>
          <div
            className="grid row-gap-32 col-gap-40 justify-center xl:px-24"
            style={{
              gridTemplateColumns: md
                ? `repeat(auto-fit, ${xxl ? '206px' : '188px'})`
                : 'repeat(auto-fit, 100%)',
              gridAutoRows: '1fr',
            }}
          >
            {assets.map((asset, index) => (
              <AssetCard
                key={asset.uniqueAssetId}
                dataQa={`purchase-assetCard-${index}`}
                asset={asset}
                openAssetModal={openAssetModal(asset)}
                openAssetInfo={openAssetInfo(asset)}
              />
            ))}
            <InfoModal
              show={showInfoModal}
              close={closeInfoModal}
              content={{
                title: modalAsset.Name,
                description: modalAsset.Description,
                icon: `${env.BlobUrl}/${modalAsset.icon}`
              }}
              dataQa="checkout-collectionMethod-infoModal"
            />
            {showAssetModal && (
              <AssetModal
                show={showAssetModal}
                close={() => setShowAssetModal(false)}
                asset={modalAsset}
              />
            )}
          </div>
        </div>
      </LogOnMount>
    </Amplitude>
  );
};

export default Purchase;
