import infoNotification from 'Helpers/infoNotification';
import i18nextTranslate from '../Lang/i18nextTranslate';
import { i18nextKeys } from '../Lang/i18nextKeys';

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const copyToClipboardAndNotify = (value, field) => {
  copyToClipboard(value);
  infoNotification(
    `${field} ${i18nextTranslate(i18nextKeys.copiedToClipboardInfo)}`
  );
};

export const allValsEqualTo = (object, value) => {
  return Object.values(object).every((val) => val === value);
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

export const getOdataOrderByString = ({ id, desc }) => {
  const sort = desc ? 'desc' : 'asc';
  if (id === 'User') {
    return `User/FirstName ${sort}, User/LastName ${sort}`;
  }
  return `${id} ${sort}`;
};

export const objectArrayToObject = (array, keyField, filterKeys = []) => {
  const obj = {};
  array.forEach((entry) => {
    const key = entry[keyField];
    obj[key] = filterKeys.length
      ? filterObject({
        keys: filterKeys,
        sourceObj: entry
      })
      : entry;
  });
  return obj;
};

export const filterObject = ({
  keys,
  sourceObj = {},
  targetObj = {}
}) => {
  const result = keys.reduce((filtered, key) => ({
    ...filtered,
    [key]: sourceObj[key] || null
  }), targetObj);
  return result;
};

export const capitalizeString = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const round = (number, decimals) => decimals
  ? Number(Math.round(number + 'e' + decimals) + 'e-' + decimals)
  : number;

export const roundDown = (number, decimals) => decimals
  ? Number(Math.floor(number + 'e' + decimals) + 'e-' + decimals)
  : number;

export const bigToRoundedDownString = (big, decimals) => {
  const numberStr = decimals && big.toString !== "0" &&
    !Number.isInteger(big.toNumber())
      ? big.toFixed(decimals, 0)
      : big.toString();
  const rounded = numberStr.replace(/(\.\d*[1-9])0+$/, '$1'); // Trim trailing decimal zeros
  return rounded;
};
