import React, { useEffect, useState } from "react";
import {
  Form as FinalForm,
  Field as FinalField
} from 'react-final-form';
import { useHistory } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import api from "Api";
import handleError from "Helpers/handleError";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { Button } from "Components/shared/buttons";
import FeatureToggle from "../ConfigManagement/shared/FeatureToggle";
import Modal from 'Components/shared/Modal/Modal';
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Text from 'Components/shared/Text';
import Warning from "Components/shared/Warning";

const KycSettingsModal = ({
  show,
  close
}) => {
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [warningText, setWarningText] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (show) {
      getSettings();
    }
  }, [show]);

  const getSettings = async () => {
    try {
      const {
        AutomaticallyApproveCases,
        AutomaticallyDeclineCases
      } = await api.Kyc.getSettings();
      setSettings({
        AutomaticallyApproveCases,
        AutomaticallyDeclineCases
      });
      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      close();
      handleError(error, history);
    }
  };

  const toggleWarning = ({
    autoApprove,
    autoDecline
  }) => {
    const warningText = i18nextTranslateDynamically(
      autoApprove || autoDecline
        ? i18nextKeys.kycManagementAutoEnable
        : i18nextKeys.kycManagementAutoDisable,
      {
        providerStatus: i18nextTranslate(
          typeof autoApprove === "boolean"
            ? i18nextKeys.shuftiProVerificationAccepted
            : i18nextKeys.shuftiProVerificationDeclined
        ),
        status: i18nextTranslate(
          typeof autoApprove === "boolean"
            ? i18nextKeys.kycCaseStatusApproved
            : i18nextKeys.kycCaseStatusDeclined
        )
      }
    );
    setWarningText(warningText);
  };

  const saveSettings = async (values) => {
    setUpdating(true);
    try {
      await api.Kyc.updateSettings(values);
      await getSettings();
      setWarningText(null);
    } catch (error) {
      handleError(error, history);
    }
    setUpdating(false);
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '142px'
  };

  return (
    <Modal
      dataQa="kyc-settings-modal"
      show={show}
      close={close}
      shouldShowCloseButton={false}
    >
      <div className="px-16 py-20 md:p-24">
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <FinalForm
            onSubmit={saveSettings}
            initialValues={settings}
          >
            {({ handleSubmit, dirty, pristine, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-24">
                  <Text
                    dataQa="settings"
                    textStyle="h2"
                    uppercase
                  >
                    {i18nextTranslate(i18nextKeys.kycManagementStatusSettings)}
                  </Text>
                  <FinalField
                    name="AutomaticallyApproveCases"
                    type="checkbox"
                  >
                    {({ input: { checked, onChange }, meta }) => (
                      <FeatureToggle
                        title={i18nextTranslate(i18nextKeys.kycManagementAutoApproveTitle)}
                        description={i18nextTranslate(i18nextKeys.kycManagementAutoApproveDescription)}
                        isEnabled={checked}
                        onChange={(autoApprove) => {
                          toggleWarning(
                            meta.dirty
                              ? { autoDecline: values.AutomaticallyDeclineCases }
                              : { autoApprove }
                          );
                          onChange(autoApprove);
                        }}
                        disabled={submitting}
                        dataQa="kyc-autoApprove"
                        toggleGap="12"
                        textGap="10"
                      />
                    )}
                  </FinalField>
                  <FinalField
                    name="AutomaticallyDeclineCases"
                    type="checkbox"
                  >
                    {({ input: { checked, onChange }, meta }) => (
                      <FeatureToggle
                        title={i18nextTranslate(i18nextKeys.kycManagementAutoDeclineTitle)}
                        description={i18nextTranslate(i18nextKeys.kycManagementAutoDeclineDescription)}
                        isEnabled={checked}
                        onChange={(autoDecline) => {
                          toggleWarning(
                            meta.dirty
                              ? { autoApprove: values.AutomaticallyApproveCases }
                              : { autoDecline }
                          );
                          onChange(autoDecline);
                        }}
                        disabled={submitting}
                        dataQa="kyc-autoDecline"
                        toggleGap="12"
                        textGap="10"
                      />
                    )}
                  </FinalField>
                </div>
                <AnimateHeight
                  duration={750}
                  height={warningText && dirty ? "auto" : 0}
                >
                  <Warning
                    visibleText={warningText}
                    className="mt-24"
                    dataQa="kyc-settings-warning"
                  />
                </AnimateHeight>
                <div className="mt-24 md:mt-32 flex gap-12 justify-between">
                  <Button
                    dataQa="kyc-settings-cancel"
                    text={i18nextTranslate(i18nextKeys.buttonCancel)}
                    width={buttonWidth}
                    onClick={close}
                    disabled={submitting}
                    secondary
                  />
                  <Button
                    dataQa="kyc-settings-save"
                    type="submit"
                    text={i18nextTranslate(i18nextKeys.buttonSave)}
                    width={buttonWidth}
                    onClick={handleSubmit}
                    disabled={submitting || pristine}
                  />
                </div>
              </form>
            )}
          </FinalForm>
        )}
      </div>
    </Modal>
  );
};

export default KycSettingsModal;
