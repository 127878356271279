import React from 'react';
import { useHistory } from 'react-router-dom';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';
import { Button } from 'Components/shared/buttons';
import { CheckboxWithText } from 'Components/shared/formElements';
import { Arrow } from 'Components/shared/symbols';

const RedemptionFormFooter = ({
  checkboxConfig,
  submitButtonText,
  submitForm = () => {},
  submitDisabled = false,
  requiredKycTier = 0,
  backButtonAction = () => {},
  showTermsAndConditions = false,
  hideBackButton = false,
  checked = false
}) => {
  const history = useHistory();

  const redirectToKyc = () =>
    history.push({
      pathname: ROUTES.kyc,
      state: {
        returnUrl: window.location.href
      }
    })

  const buttonWidth = {
    xxl: '190px',
    default: '156px'
  };

  return (
    <div className="flex flex-col gap-16 md:gap-24">
      <div className="flex flex-col gap-16">
        {!!checkboxConfig && (
          <>
            {checkboxConfig.map((text, index) => (
              <CheckboxWithText
                key={index}
                name={`conditions.${index}`}
                text={text}
                dataQa={`condition-${index}`}
                disabled={requiredKycTier}
                checked={checked}
                required
                showErrors
              />
            ))}
          </>
        )}
        {!!showTermsAndConditions && (
          <CheckboxWithText
            name="terms"
            text={i18nextTranslate(i18nextKeys.redeemSummaryTc)}
            dataQa="condition-terms"
            disabled={requiredKycTier}
            checked={checked}
            showErrors
            required
          />
        )}
      </div>
      <div className={`flex gap-8 ${
        hideBackButton ? "justify-end" : "justify-between"
      }`}>
        {!hideBackButton && (
          <Button
            dataQa="back"
            text={i18nextTranslate(i18nextKeys.buttonBack)}
            width={buttonWidth}
            onClick={backButtonAction}
            tertiary
          />
        )}
        <Button
          dataQa="submit"
          className="justify-self-end"
          text={
            requiredKycTier
              ? i18nextTranslateDynamically(
                  i18nextKeys.kycGetTierToContinue,
                  { tier: requiredKycTier }
                )
              : submitButtonText
          }
          icon={requiredKycTier ? Arrow : null}
          width={buttonWidth}
          onClick={
            requiredKycTier
              ? redirectToKyc
              : submitForm
          }
          disabled={!requiredKycTier && submitDisabled}
        />
      </div>
    </div>
  );
};

export default RedemptionFormFooter;
